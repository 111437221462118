import React, { useEffect } from "react";

const Veriff = ({ firstName, lastName, id, setVerified, setOpenVeriff }) => {
  useEffect(() => {

    //setOpenVeriff(false);

    const script = document.createElement("script");
    script.src = "https://cdn.veriff.me/sdk/js/1.5/veriff.min.js";
    script.async = true;
    script.onload = () => initializeVeriff();
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [firstName, lastName]);

  const initializeVeriff = () => {

    if (window.Veriff) {
      const veriff = window.Veriff({
        host: "https://stationapi.veriff.com",
        apiKey: "2818469c-12c5-426c-a230-61efd7ad3799",
        parentId: "veriff-root",

        onSession: function (err, response) {
          if (!err) {
            window.veriffSDK.createVeriffFrame({
              url: response.verification.url,
              onEvent: function (event) {
                console.log("Event", event);
                if (event === "STARTED") {
                  console.log("User started verification");
                }
                if (event === "CANCELED") {
                  console.log("User canceled verification");
                }
                if (event === "FINISHED") {
                  console.log("User finished verification");
                  setVerified(true);
                }
              },
            });
          }
        },
      });

      veriff.setParams({
        person: {
          givenName: firstName,
          lastName: lastName,
        },
        vendorData: id,
      });

      veriff.mount();
      setTimeout(() => {    
        setOpenVeriff(false);  
      }, 3000);

    }
  };

  return (
    <div id="veriff-root" style={{ width: "350px", height: "100px" }}></div>
  );
};

export default Veriff;
