/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import React, { useEffect, useMemo, useState } from 'react';
import { Web3Button, Web3Modal, useWeb3Modal } from '@web3modal/react';
import { mainnet, useDisconnect, useAccount, useContractRead, useContractReads, useContractWrite, useNetwork, usePublicClient, useSwitchNetwork } from 'wagmi';
import { createPublicClient, formatEther, http, parseEther } from 'viem';
import { polygon } from 'wagmi/chains';
import '../App.css';
import logo from '../assets/logo.png';
import home from '../assets/home.svg';
import doller from '../assets/doller.png';
import bars from '../assets/bars.png';
import help from '../assets/help.png';
import notification from '../assets/notification.png';
import spanish from '../assets/spanish.png';
import off from '../assets/power-off.png';
import user from '../assets/user.png';
import arrow from '../assets/arrow.png';
import gift from '../assets/gift.svg';
import audit from '../assets/auditace.png';
import Enmascarar from '../assets/Enmascarar.png';
import usdt from '../assets/usdt.svg';
import { base } from 'wagmi/chains';
import Header from '../components/headers';
import grid from '../assets/grid.png';
import HeaderMobile from '../components/headersMobile';
import HeaderNavBar from '../components/headersNavBar';
import axios from 'axios';

var Scroll = require('react-scroll');

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

const tweet = () => {
	window.open("#");
}

const tg = () => {
	window.open("#");
}

let ABIToken = [
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "Paused",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "Unpaused",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			}
		],
		"name": "allowance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "burn",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "burnFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimals",
		"outputs": [
			{
				"internalType": "uint8",
				"name": "",
				"type": "uint8"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "subtractedValue",
				"type": "uint256"
			}
		],
		"name": "decreaseAllowance",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "addedValue",
				"type": "uint256"
			}
		],
		"name": "increaseAllowance",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "pause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "transfer",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "unpause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	}
];
let addressToken = "0xDcbF2647B9ccb7F85a3Da06446B670f2FD8ca643"

let ABI = [
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_token",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [],
		"name": "Cancel",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [],
		"name": "Claim",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "softCap",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "hardCap",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint32",
				"name": "startAt",
				"type": "uint32"
			},
			{
				"indexed": false,
				"internalType": "uint32",
				"name": "endAt",
				"type": "uint32"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "minAmount",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "maxAmount",
				"type": "uint256"
			}
		],
		"name": "Launch",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "caller",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "PledgeCanceled",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "caller",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "PledgeConfirmed",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "caller",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "PledgeIntention",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "caller",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Refund",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "EURtoPOL",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "POLToEUR",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "POLToGHUT",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "approvedList",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "cancel",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_wallet",
				"type": "address"
			}
		],
		"name": "cancelPledgeTo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "canceled",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "claim",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "claimed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_wallet",
				"type": "address"
			}
		],
		"name": "confirmPledge",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimalCorrecter",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimalCorrecter2",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "deposit",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "receiver",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "distribution",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "eligibleGHUTTokens",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "endAt",
		"outputs": [
			{
				"internalType": "uint32",
				"name": "",
				"type": "uint32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "exchangeRate",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getApprovedList",
		"outputs": [
			{
				"internalType": "address[]",
				"name": "",
				"type": "address[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getBalance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getChainlinkDataFeedLatestAnswer_USD_EUR",
		"outputs": [
			{
				"internalType": "int256",
				"name": "",
				"type": "int256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getChainlinkDataFeedLatestAnswer_USD_POL",
		"outputs": [
			{
				"internalType": "int256",
				"name": "",
				"type": "int256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getHardCapInPol",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getMaxAmountInPol",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getMinAmountInPol",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getPledgeAddressList",
		"outputs": [
			{
				"internalType": "address[]",
				"name": "",
				"type": "address[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getSoftCapInPol",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "hardCap",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "holded",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "holdedAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "holdedAmountInEuros",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_softCap",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_hardCap",
				"type": "uint256"
			},
			{
				"internalType": "uint32",
				"name": "_startAt",
				"type": "uint32"
			},
			{
				"internalType": "uint32",
				"name": "_endAt",
				"type": "uint32"
			},
			{
				"internalType": "uint256",
				"name": "_minAmount",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_maxAmount",
				"type": "uint256"
			}
		],
		"name": "launch",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "maxAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "minAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "pledge",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "pledgeList",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "pledged",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "pledgedAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "refund",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "wallet",
				"type": "address"
			}
		],
		"name": "refundTo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_df",
				"type": "address"
			}
		],
		"name": "setDataFeed_USD_EUR",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_df",
				"type": "address"
			}
		],
		"name": "setDataFeed_USD_POL",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_decimalCorrecter",
				"type": "uint256"
			}
		],
		"name": "setDecimalCorrecter",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_decimalCorrecter2",
				"type": "uint256"
			}
		],
		"name": "setDecimalCorrecter2",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint32",
				"name": "_endAt",
				"type": "uint32"
			}
		],
		"name": "setEndAt",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_exchangeRate",
				"type": "uint256"
			}
		],
		"name": "setExchangeRate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_hardCap",
				"type": "uint256"
			}
		],
		"name": "setHardCap",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_maxAmount",
				"type": "uint256"
			}
		],
		"name": "setMaxAmount",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_minAmount",
				"type": "uint256"
			}
		],
		"name": "setMinAmount",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_pledged",
				"type": "uint256"
			}
		],
		"name": "setPledged",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_softCap",
				"type": "uint256"
			}
		],
		"name": "setSoftCap",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint32",
				"name": "_startAt",
				"type": "uint32"
			}
		],
		"name": "setStartAt",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_tokenContract",
				"type": "address"
			}
		],
		"name": "setTokenContract",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "softCap",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "startAt",
		"outputs": [
			{
				"internalType": "uint32",
				"name": "",
				"type": "uint32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "token",
		"outputs": [
			{
				"internalType": "contract IERC20",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "tokenWithdrawal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdrawAnyLeftbalance",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"stateMutability": "payable",
		"type": "receive"
	}
];
let address = "0xfFe1d6AB2733027aaB7fEb7f49218fb5Da888f20"

const Admin = () => {

	const { disconnect } = useDisconnect();
	const { open } = useWeb3Modal()
	const { chain } = useNetwork()
	const { switchNetwork } = useSwitchNetwork()
	const clientID = "2f8f716d-b303-481b-80ef-febaf341524b";
	const httpURL = "https://polygon-mainnet.infura.io/v3/9aad89c8e515457ab8b7805f5da593ea"
	const [_connected, setConnected] = useState(false);
	const [statusError2, setstatusError2] = useState(false);
	const [statusLoading2, setstatusLoading2] = useState(false);
	const [isMinted2, setIsMinted2] = useState(false);
	const [statusError3, setstatusError3] = useState(false);
	const [statusLoading3, setstatusLoading3] = useState(false);
	const [isMinted3, setIsMinted3] = useState(false);
	const [_Receiver, set_Receiver] = useState('');
	const [_Receiver2, set_Receiver2] = useState('');
	const [_Amount, set_Amount] = useState(0);
	const [_Wallet, set_Wallet] = useState('');
	const [_ghutAmount, setGhutAmount] = useState(0);
	const [_ghutAmount_input, setGhutAmount_input] = useState(0);
	const [_ghutAmount2, setGhutAmount2] = useState(0);
	const [walletAddress_deduction, setWalletAddress_deduction] = useState('');
	const [_GhutToPol, setGhutToPol] = useState(0);
	const [_EndAt, setEndAt] = useState(0);
	const [_StartAt, setStartAt] = useState(0);
	const [_MaxAmount, setMaxAmount] = useState(0);
	const [_MinAmount, setMinAmount] = useState(0);
	const [errorMsg, setErrorMsg] = useState('');
	const [errorMsg_1, setErrorMsg_1] = useState('');
	const [_owner, setOwner] = useState('');
	const [_holdedAmount, setHoldedAmount] = useState('');
	const [_eligibleGHUTTokens, setEligibleGHUTTokens] = useState('');
	const [selectedAddress, setSelectedAddress] = useState(''); // State to keep track of the selected address
	const [selectedAddress2, setSelectedAddress2] = useState(''); // State to keep track of the selected address
	const [isOpen, setIsOpen] = useState(false);
	const [isOpen2, setIsOpen2] = useState(false);

	const [_listAddress, setListAddress] = useState([]);
	const [_approvedListAddress, setApprovedListAddress] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const { address: walletAddress } = useAccount({
		async onConnect() {
			handleConnect()
		}
	})

	const transport = http(httpURL);

	const publicClient = createPublicClient({
		chain: polygon,
		transport
	})

	var contract = {
		address: address,
		abi: ABI
	}

	var contract2 = {
		address: addressToken,
		abi: ABIToken
	}

	async function handleConnect() {
		if (chain.id !== 137) {
			switchNetwork(137);
		}

		//await fetchNFTs();
		setConnected(true);
	}

	const { refetch: getOwner } = useContractRead({
		...contract,
		functionName: "owner",
		args: [],
	});

	const { refetch: getEndAt } = useContractRead({
		...contract,
		functionName: "endAt",
		args: [],
	});

	useEffect(() => {

		if (_connected) {
		}

		async function fetchList() {
			var data = await getPledgeAddressListVar();
			let listAddress = data.data;
			let uniqueAddresses = [...new Set(listAddress)]; // Remove duplicates
			setListAddress(uniqueAddresses);
			console.log("listAddress : ", uniqueAddresses);
		}

		async function fetchApprovedList() {
			var data = await getApprovedListVar();
			let approvedListAddress = data.data;
			let uniqueAddresses2 = [...new Set(approvedListAddress)]; // Remove duplicates
			setApprovedListAddress(uniqueAddresses2);
			console.log("approved listAddress : ", uniqueAddresses2);
		}

		async function fetchGetOwner() {
			var data = await getOwner();
			let owner = data.data;
			console.log("owner data.data : " + data.data);

			setOwner(owner);
			console.log("owner : " + owner);
		}

		async function fetchHoldedAmount() {
			var data = await getHoldedAmount();
			let holdedAmount = data.data;
			console.log("holdedAmount : " + data.data);

			setHoldedAmount(holdedAmount);
			console.log("holdedAmount : " + holdedAmount);
		}

		async function fetchEndAt() {
			var data = await getEndAt();
			let EndAt = Number(data.data);
			console.log("EndAt_data.data : " + data.data);
			console.log("EndAt : " + EndAt);
		}

		fetchList();
		fetchGetOwner();
		fetchEndAt();
		fetchApprovedList();
		fetchHoldedAmount();

	}, [_connected, walletAddress]);

	// Fetch eligible GHUT tokens for the selected address
	async function fetchEligibleGHUTTokens(address) {
		// Simulate fetching eligible tokens from the backend
		var data = await getEligibleGHUTTokens(address); // Pass the address to your backend function
		let eligibleGHUTTokens = Number(data.data);
		setEligibleGHUTTokens(eligibleGHUTTokens);
		setGhutAmount(eligibleGHUTTokens); // Set the fetched tokens to the amount input
		console.log("eligibleGHUTTokens : " + eligibleGHUTTokens);
	}

	// Function called when an address is clicked
	const handleAddressClick = (address) => {
		if (selectedAddress === address) {
			setSelectedAddress(''); // Deselect the address
			set_Wallet(''); // Reset the wallet address input
		} else {
			set_Wallet(address); // Set the clicked address in the wallet address input
			setSelectedAddress(address); // Select the address
		}
	};

	const handleAddressClick2 = (address2) => {
		if (selectedAddress2 === address2) {
			setSelectedAddress2(''); // Deselect the address
			set_Receiver(''); // Reset the receiver input
			setGhutAmount(''); // Reset the distribution amount input

		} else {
			set_Receiver(address2); // Set the clicked address in the receiver input
			fetchEligibleGHUTTokens(address2); // Fetch the eligible tokens for this address
			setSelectedAddress2(address2); // Select the address
		}
	};

	const { refetch: getPledgeAddressListVar } = useContractRead({
		...contract,
		functionName: 'getPledgeAddressList', args: [],
	})

	const { refetch: getApprovedListVar } = useContractRead({
		...contract,
		functionName: 'getApprovedList', args: [],
	})

	const { refetch: getEligibleGHUTTokens } = useContractRead({
		...contract,
		functionName: 'eligibleGHUTTokens', args: [walletAddress],
	})

	const { refetch: getHoldedAmount } = useContractRead({
		...contract,
		functionName: 'holdedAmount', args: [walletAddress],
	})

	function shortenAddress(walletAddress) {
		try {
			return _connected
				? walletAddress.slice(0, 3) + "..." + walletAddress.slice(-4)
				: "Connect";
		} catch (error) {
			console.log(error);
		}
	}

	async function disconnectWallet() {
		setConnected(false);
		disconnect();
		window.location.reload(true);
	}

	const { writeAsync } = useContractWrite({
		onError(error) {
			if (error.message.includes("balance")) {
			}
		},
	});


	async function confirmPledge() {
		try {
			setstatusError2(false);
			setstatusLoading2(true);
			setErrorMsg('');  // Clear previous error message

			// Validate wallet address
			if (!_Wallet || !_Wallet.startsWith('0x') || _Wallet.length !== 42) {
				setErrorMsg('Please enter a valid Ethereum address.');
				return;
			}

			if (walletAddress === _owner) {
				console.log("I'm the Owner");
			}

			setstatusError2(false);
			setstatusLoading2(true);

			/*const res = await writeAsync({
				...contract,
				functionName: 'confirmPledge',
				args: [_Wallet],
				gasPrice: 30000000000, // Set the gas price (20 Gwei) in wei
				//maxPriorityFeePerGas: '30000000000', // Example priority fee in wei (1 Gwei)
				//maxFeePerGas: '30000000000', // Example maximum fee in wei (20 Gwei)
			});*/


			const res = await writeAsync({
				...contract,
				functionName: 'confirmPledge',
				args: [_Wallet],
				gas: 2100000,
				gasPrice: 1600000000000
				//	gasPrice: '4000000000', // Use estimated gas limit
				//	maxPriorityFeePerGas: '40000000000', // Example priority fee (1 Gwei)
				//	maxFeePerGas: '40000000000', // Example maximum fee (30 Gwei)
			});

			// Check transaction result
			const result = await publicClient.waitForTransactionReceipt(res);
			if (result.status === 'success') {
				setstatusError2(false);
				setIsMinted2(true);
				setstatusLoading2(false);

				// Wait for transaction to process
				await new Promise((resolve) => setTimeout(resolve, 2000));
			} else {
				setIsMinted2(false);
				setstatusError2(true);
				setstatusLoading2(false);
			}

		} catch (e) {
			console.log(e);  // Log the entire error for debugging

			// Capture and display the specific error message from the contract
			if (e.reason) {
				setErrorMsg(e.reason);  // Show the contract revert reason
			} else {
				setErrorMsg('An unexpected error occurred.');
			}

			setstatusError2(true);
			setstatusLoading2(false);
		}
	}

	const contractHistory = async (event) => {
		event.preventDefault(); // Prevent default form submission behavior
		setResponseMessage(''); // Clear any previous response message
		setIsLoading(true); // Set loading to true

		try {

			if (file) {
				const fileName = file.name;

				// Convert the file to base64 encoding
				const fileReader = new FileReader();
				fileReader.onload = async () => {
					const base64String = fileReader.result.split(',')[1];

					try {
						const payload = {
							contract: '',
							token: '',
							softCap: _softCap,
							hardCap: _hardCap,
							minAmount: _MinAmount,
							maxAmount: _MaxAmount,
							start: _StartAt,
							end: _EndAt,
							pledged: '',
							tokenName: _ghutAmount,
							tokenDecimals: '',
							dateRegistration: '',
						};

						if (_connected) {
							payload.wallet = walletAddress;
						}

						const response = await axios.post(' https://localhost:53805/api/ContractHistory', payload);
						console.log('Registration completed successfully!');
						console.log(response.data);
						setResponseMessage(response.data || 'Success');
					} catch (error) {
						console.log('Error sending file.', error);
						setResponseMessage('Error sending file.');
					} finally {
						setIsLoading(false); // Set loading to false
					}
				};

				fileReader.onerror = () => {
					console.log('Error reading file.');
					setResponseMessage('Error reading file.');
					setIsLoading(false); // Set loading to false
				};

				fileReader.readAsDataURL(file);
			} else {
				setResponseMessage('Please select a file.');
				setIsLoading(false); // Set loading to false
			}
		} catch (err) {
			console.log(err);
			setResponseMessage('Error occurred.');
			setIsLoading(false); // Set loading to false
		}
	};

	async function distribution() {
		try {
			setstatusError3(false);
			setstatusLoading3(true);
			setErrorMsg_1('');  // Clear previous error message

			// Validate wallet address
			/*	if (!_Wallet || !_Wallet.startsWith('0x') || _Wallet.length !== 42) {
					setErrorMsg_1('Please enter a valid Ethereum address.');
					return;
				}*/

			setstatusError3(false);
			setstatusLoading3(true);

			console.log("_Receiver__ : " + _Receiver);
			console.log("_ghutAmount__ : " + _ghutAmount_input);

			var res = await writeAsync({
				...contract,
				functionName: 'distribution',
				args: [_Receiver, _ghutAmount_input * 10 ** 6],
				gas: 2100000,
				gasPrice: 1600000000000
			})

			// Check transaction result
			const result = await publicClient.waitForTransactionReceipt(res);
			if (result.status === 1) {
				setstatusError3(false);
				setIsMinted3(true);
				setstatusLoading3(false);

				deduction();

				// Refresh the page after 2 seconds
				setTimeout(() => {
					window.location.reload(true);
				}, 2000);

			} else {
				setIsMinted3(false);
				setstatusError3(true);
				setstatusLoading3(false);
			}

		} catch (e) {
			console.error("Transaction failed:", e);
			if (e.message.includes("Transaction with hash")) {
				deduction();
				console.log("adkiuyhsalofdjalkhfjdalskhf");
			}
			if (e.message.includes("err: insufficient funds for gas")) {
				setstatusError3("Insufficient funds");
				setstatusError3(true); // Show the error div for insufficient funds
				set_validate(0);
			} else if (e.message.includes("User rejected the request")) {
				setstatusError3("User Rejected");
				//setshowErrorDiv(false);
			} else {
				setstatusError3("Sorry, something went wrong");
				//setshowErrorDiv(false);
			}

			//setstatusError(true);
			//setstatusLoading(false);
		}
	}

	const deduction = async (event) => {
		setIsLoading(true); // Set loading to true

		try {

			try {
				const payload = {
					walletAddress: _Receiver,
					ghutAmount: Number(_ghutAmount),
				};

				const response = await axios.post('https://api.guardhawk.io/api/Deduction', payload);
				console.log('Registration completed successfully!');
				console.log(response.data);
				//setResponseMessage(response.data || 'Success');
			} catch (error) {
				console.log('Error sending file.', error);
				//setResponseMessage('Error sending file.');
			} finally {
				setIsLoading(false); // Set loading to false
			}

		} catch (err) {
			console.log(err);
			//setResponseMessage('Error occurred.');
			setIsLoading(false); // Set loading to false
		}
	};

	const closediv = () => {
		setstatusError2(null);
		setstatusLoading2(null);
		setIsMinted2(null);
	};



	return (
		<div className='allWrapMain'>
			{walletAddress === _owner ?
			<>
				<div className='allWrap'>
					<div class="light">
						{_connected ? (
							<div className="connectT" onClick={() => disconnectWallet()}>
								{walletAddress === "" ? "Conectar Billetera Web3" : shortenAddress(walletAddress)}
							</div>
						) : (
							<div className="connectT" onClick={() => { open(); }}>Conectar Billetera Web3</div>
						)}

						<div className='onlyMob'>
							<HeaderMobile />
						</div>

						<div className='page1Main'>
							<Header />

							<div className='rightSection'>

								<div className='mainT'>PANEL DE ADMINISTRACIÓN</div>
								<div className='flexBox3T'>BIENVENIDO AL PANEL DE ADMINISTRACIÓN</div>

								<p></p>
								<div className='adminFlexDiv'>
									<div id="distributionForm">
										<div className="form-group">
											<label htmlFor="receiverAddress">DISTRIBUTE TOKENS <span className='ce'>(Run this only after ending the campaign)</span></label>
											<input
												id="receiverAddress"
												placeholder="0x1234..."
												name="_Receiver"
												value={_Receiver}
												onChange={event => set_Receiver2(event.target.value)}
												type="text"
												required
											/>
										</div>
										<div className="form-group">
											<input
												id="distributionAmount"
												placeholder="Enter amount"
												name="_ghutAmount_input"
												value={_ghutAmount_input}
												onChange={event => setGhutAmount_input(event.target.value)}
												type="number"
												step="0.01" // Ensures the input allows decimal values
												required
											/>
											{!_ghutAmount == 0 ?
												<>
													{_ghutAmount / 10 ** 36}
												</>
												: null}
										</div>
										<div className="form-group">
											<button onClick={distribution}>Transact</button>
										</div>
									</div>

									
									<details style={{ cursor: "pointer" }} open={isOpen2}>
										<summary onClick={() => setIsOpen2(!isOpen2)}>Approved List</summary>
										<div className="addressList">
											{_approvedListAddress.map((address2, index2) => (
												<div
													key={index2}
													onClick={() => {
														handleAddressClick2(address2);
														setIsOpen2(false);
													}}
													style={{ color: selectedAddress2 === address2 ? "#a555fa" : "rgb(138, 138, 138)" }}
												>
													{address2}

												</div>
											))}
										</div>
									</details>
									
								</div>

								<div className='adminFlexDiv'>

									<div id="confirmPledgeForm">
										<div className="form-group">
											<label htmlFor="walletAddress">CONFIRM PLEDGE</label>
											<input
												id="walletAddress"
												placeholder="0x1234..."
												name="_Wallet"
												value={_Wallet}
												onChange={event => set_Wallet(event.target.value)}
												type="text"
												required
											/>
										</div>
										<div className="form-group">
											<button onClick={confirmPledge}>Transact</button>
										</div>
									</div>

									<details style={{ cursor: "pointer" }} open={isOpen}>
										<summary onClick={() => setIsOpen(!isOpen)}>Pledge List</summary>
										<div className="addressList">
											{_listAddress.map((address, index) => (
												<div
													key={index}
													onClick={() => {
														handleAddressClick(address);
														setIsOpen(false);
													}}
													style={{ color: selectedAddress === address ? "#a555fa" : "rgb(138, 138, 138)" }}
												>
													{address}

												</div>
											))}
										</div>
									</details>
								</div>
							 


								{/* Address List */}
								{/*_listAddress.length > 0 && (
											<div className='addressList'>
												{_listAddress.map((address, index) => (
													<div
														key={index}
														onClick={() => handleAddressClick(address)}
														style={{
															cursor: 'pointer',
															color: selectedAddress === address ? '#a555fa' : 'rgb(138, 138, 138)' // Conditional styling
														}}
													>
														{address}
													</div>
												))}
											</div>
										)*/}

							  
							</div>

						</div>

					</div>
					<img className='grid' src={grid} />

				</div>
				<div className='onlyMob'>
					<HeaderNavBar />
				</div>
			</>
			:<div className='onlyOwner'><span className='ownerTxt' onClick={() => window.location.href = '/'}>Kindly note that only the owner can access this page. Thank you for your cooperation!</span></div>
}
		</div>
	)

}
export default Admin;
