/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import { polygon } from 'viem/chains';
import React, { useEffect, useMemo, useState, useContext } from 'react';
import { Web3Button, Web3Modal, useWeb3Modal } from '@web3modal/react';
import { useDisconnect, useAccount, useContractRead, useContractReads, useContractWrite, useNetwork, usePublicClient, useSwitchNetwork } from 'wagmi';
import { createPublicClient, formatEther, http, parseEther } from 'viem';
import '../App.css';
import axios from 'axios';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import 'react-datepicker/dist/react-datepicker.css';
import eyeOpen from '../assets/show.png';
import eyeClose from '../assets/hide.png';

var Scroll = require('react-scroll');

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

const tweet = () => {
	window.open("#");
}

const tg = () => {
	window.open("#");
}

const RegisterLogin = () => {

	const [_percentage, setPercentage] = useState('0%');
	const { open } = useWeb3Modal();
	const { chain } = useNetwork();
	const { switchNetwork } = useSwitchNetwork();
	const clientID = "2f8f716d-b303-481b-80ef-febaf341524b";
	const [_amount, set_amount] = useState(1000);
	const httpURL = "https://polygon-mainnet.infura.io/v3/9aad89c8e515457ab8b7805f5da593ea"
	const [_quantity, set_quantity] = useState(0);
	const [_error, set_error] = useState(0);
	const [showErrorDiv, setshowErrorDiv] = useState(false);
	const [statusError, setstatusError] = useState(false);
	const [statusLoading, setstatusLoading] = useState(false);
	const [_owner, set_owner] = useState("");
	const [_connected, setConnected] = useState(false);
	const { disconnect } = useDisconnect();
	const [_success1, setSuccessMsg1] = useState("");
	const [_loading1, setLoadingMsg1] = useState("");
	const [_ErrorMsg1, setErrorMsg1] = useState("");
	const [success, setsuccess] = useState(false);
	const [_viewUser, set_getUser] = useState("");
	const [_totalUsers, set_totalUsers] = useState("");
	const [_getAddressById, set_getAddressById] = useState("");
	const [txnHash, setTxnHash] = useState('');
	const [_totalTokensSold, setTotalTokensSold] = useState(0);
	const [_totalTokensForSale, setTotalTokensForSale] = useState(0);
	const [_targetFunds, setTargetFunds] = useState(0);
	const [_totalFundsCollected, setTotalFundsCollected] = useState(0);
	const [_rate, setRate] = useState(0);
	const [_pledged, setPledged] = useState(0);
	const [_hardCap, setHardCap] = useState(0);
	const [_EURToPOL, setEURToPOL] = useState(0);
	const [_HardCapInPOL, setHardCapInPOL] = useState(0);
	const [_softCap, setSoftCap] = useState(0);
	const [_login, set_login] = useState(0);
	const [_register, set_register] = useState(0);
	const [_softCapTargetPercentage, set_softCapTargetPercentage] = useState('0%');
	const [_pledgedPercentage, set_pledgedPercentage] = useState('0%');
	const options = countryList().getData();
	const [dob, setDob] = useState(null);
	const [day, setDay] = useState('');
	const [month, setMonth] = useState('');
	const [year, setYear] = useState('');
	const [_BirthDate, setBirthDate] = useState(0);
	const [_Country, setCountry] = useState('');
	const [_Password, setPassword] = useState('');
	const [_Password2, setPassword2] = useState('');
	const [_Email, setEmail] = useState('');
	const [_OTP, set_OTP] = useState('');
	const [_FirstName, setFirstName] = useState('');
	const [_LastName, setLastName] = useState('');
	const [_PostalCode, setPostalCode] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [_ghutAmount, setGhutAmount] = useState(0);
	const [_GhutToPol, setGhutToPol] = useState(0);
	const [_EndAt, setEndAt] = useState(0);
	const [_StartAt, setStartAt] = useState(0);
	const [_MaxAmount, setMaxAmount] = useState(0);
	const [_MinAmount, setMinAmount] = useState(0);
	const [_pol1000ToGhut, setPol1000ToGhut] = useState(0);
	const [_userToken, setUserToken] = useState(0);
	const [_ShowMsgDiv, setShowMsgDiv] = useState(0);
	const [isForgetPassSuccess, setIsForgetPassSuccess] = useState(null);
	const [isLoginSuccess, setIsLoginSuccess] = useState(null);
	const [isRegisterSuccess, setIsRegisterSuccess] = useState(null);
	const [isResetPassSuccess, setIsResetPassSuccess] = useState(null);
	const [_forgotpassdiv, setforgotpassdiv] = useState(0);
	const [_resetPass, set_resetPass] = useState(0);
	const [responseMessage, setResponseMessage] = useState('');
	const [_ImgData, setImgData] = useState('');
	const [passwordError, setPasswordError] = useState('');

	const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword); // Toggle visibility
	};

	const { address: walletAddress } = useAccount({
		async onConnect() {
			handleConnect()
		}
	})

	const transport = http(httpURL);

	const publicClient = createPublicClient({
		chain: polygon,
		transport
	})

	// Helper function to generate options
	const generateOptions = (start, end) => {
		const options = [];
		for (let i = start; i <= end; i++) {
			options.push({ value: i, label: i });
		}
		return options;
	};

	const days = generateOptions(1, 31);
	const months = generateOptions(1, 12);
	const years = generateOptions(1924, new Date().getFullYear());

	// Combine day, month, and year into a single date variable
	const getDateValue = () => {
		if (day && month && year) {
			const date = new Date(year, month - 1, day); // Months are zero-based in JavaScript
			return date.toISOString().split('T')[0]; // Returns date in YYYY-MM-DD format
		}
		return '';
	};

	const changeHandler = country => {
		setCountry(country);
	};

	const handleDateChange = (date) => {
		setDob(date);
	};

	async function handleConnect() {
		if (chain.id !== 137) {
			switchNetwork(137);
		}

		//await fetchNFTs();
		setConnected(true);
	}

	function formatNumber(number) {
		// Convert number to string and split into integer and decimal parts
		let [integerPart, decimalPart] = number.toString().split('.');

		// Add commas every three digits from the right for the integer part
		integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

		// Combine the integer part with the decimal part if it exists
		return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
	}

	useEffect(() => {

		var _userToken2 = sessionStorage.getItem("userToken");
		setUserToken(_userToken2);
		console.log("_userToken2 : " + _userToken2);

		if (_connected) {
			if (!_userToken2) {
				loginWindow()
			}
		}

		console.log("_connected : " + _connected);
		console.log("_login : " + _login);

	}, [_connected, walletAddress]);

	const { writeAsync } = useContractWrite({
		onError(error) {
			if (error.message.includes("balance")) {
				setstatusError(true);
				setstatusLoading(false);
			}
		},
	});

	async function loginWindow() {
		set_login(1);
		set_register(0);
	}

	async function regsiterWindow() {
		set_register(1);
		set_login(0);
		setforgotpassdiv(0); // Reset the state to hide the divs
	}

	async function closeWindow() {
		setshowErrorDiv(false);
	}

	const customStyles = {
		control: (provided, state) => ({
			...provided,
			borderRadius: '5px',
			borderColor: '#ccc',
			padding: '5px',
			margin: '10px 0',
			textAlign: 'left',
			boxShadow: state.isFocused ? `0 0 0 2px #8c40dd` : 'none', // Outline color on focus
			border: state.isFocused ? '1px solid #8c40dd' : '1px solid #ccc', // Border color change on focus
		}),
		placeholder: (provided) => ({
			...provided,
			textAlign: 'left',
		}),
		singleValue: (provided) => ({
			...provided,
			textAlign: 'left',
		}),
		menu: (provided) => ({
			...provided,
			textAlign: 'left',
		}),
		option: (provided) => ({
			...provided,
			textAlign: 'left',
		}),
	};

	const validatePassword = (password) => {
		const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/;
		return passwordRegex.test(password);
	};

	const registerUser = async (event) => {
		event.preventDefault(); // Prevent default form submission behavior
		setResponseMessage(''); // Clear any previous response message
		setIsLoading(true); // Set loading to true

		// Validate password before proceeding
		if (!validatePassword(_Password)) {
			setPasswordError(
				'La contraseña debe tener 1 letra minúscula, 1 letra mayúscula, 1 dígito, 1 carácter especial y la longitud debe estar entre 8 y 11 caracteres'
			);
			setIsLoading(false);
			return; // Stop form submission if password is invalid
		}

		try {
			const dateValue = getDateValue();

			const payload = {
				email: _Email,
				firstName: _FirstName,
				lastName: _LastName,
				postalCode: _PostalCode,
				password: _Password,
				userRoleId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
				country: _Country.value,
				dateOfBirth: dateValue,
			};

			if (_connected) {
				payload.walletAddress = walletAddress;
			}

			const response = await axios.post('https://api.guardhawk.io/api/User', payload);
			console.log('Registration completed successfully!');
			console.log(response.data.message);

			// Only navigate to the login page if registration was successful
			if (response.data.isSuccess) {
				setUserToken(response.data.token); // Save the token if available
				setIsRegisterSuccess(true);
				setResponseMessage('Registration successful! Redirecting to login...');
				loginWindow(); // Show login form only if registration was successful
			} else {
				console.log('Registration was not successful:', response.data.message);
				setIsRegisterSuccess(false);
				setResponseMessage(response.data.message || 'Registration failed.');
			}
		} catch (error) {
			console.log('Error during registration:', error);
			// If there's a server error, show the error message if available
			setResponseMessage(error.response?.data?.message || 'Error occurred during registration.');
			setIsRegisterSuccess(false);
		} finally {
			setIsLoading(false); // Set loading to false
		}
	};

	const loginUser = async (event) => {
		event.preventDefault(); // Prevent default form submission behavior

		console.log("LOGIN");
		setResponseMessage(''); // Clear any previous response message
		setIsLoading(true); // Set loading to true

		try {
			const payload = {
				email: _Email,
				password: _Password,
			};

			if (_connected) {
				payload.walletAddress = walletAddress;
			}

			console.log("Login USER");

			const response = await axios.post('https://api.guardhawk.io/api/Account/login', payload);
			console.log('Login completed successfully!');
			console.log(response.data);

			if (response.data.isSuccess) {
				const token = response.data.data.token; // Extract the token from the response
				setUserToken(token);
				sessionStorage.setItem('userToken', token);
				sessionStorage.setItem('displayName', response.data.data.displayName);
				sessionStorage.setItem('email', response.data.data.email);
				sessionStorage.setItem('isValidIdentity', response.data.data.isVerified);
				console.log("IsverifiedLogin : " + response.data.data.isVerified);
				console.log("isValidIdentity_session : " + sessionStorage.getItem('isValidIdentity'));

				var fullImg = 'data:image/png;base64,' + response.data.data.imgUrl;
				sessionStorage.setItem('imgUrl', fullImg);
				setImgData(fullImg);

				console.log("userIDREAD" + response.data.data.userId);
				sessionStorage.setItem('userId', response.data.data.userId);

				console.log("userID get : " + sessionStorage.getItem("userId"));

				setIsLoginSuccess(true); // Set success
				setResponseMessage('Login successful!');
				set_login(0); // Close the login window on success
			} else {
				setIsLoginSuccess(false); // Set error
				setResponseMessage(response.data.message || 'Login failed.');
			}
		} catch (error) {
			console.log('Error during login.', error);
			// Extract and display the exact error message if available
			const errorMsg = error.response?.data?.message || 'Error occurred during login.';
			setIsLoginSuccess(false); // Set error
			setResponseMessage(errorMsg);
		} finally {
			setIsLoading(false); // Set loading to false
		}
	};

	const forgotPassword = async (event) => {
		event.preventDefault(); // Prevent default form submission behavior

		console.log("forgotPassword");
		setResponseMessage(''); // Clear any previous response message
		setIsLoading(true); // Set loading to true
		setPassword('');

		try {
			const payload = {
				email: _Email,
				password: '',
			};

			const response = await axios.post('https://api.guardhawk.io/api/Account/fogetPassword', payload);
			console.log('Password resets successfully!');
			console.log(response.data);

			if (response.data.isSuccess) {
				const token = response.data.data.token; // Extract the token from the response
				setUserToken(token);
				sessionStorage.setItem('userToken', token);
				sessionStorage.setItem('email', response.data.data.email);
				sessionStorage.setItem('userId', response.data.data.userId);
				console.log("userID get : " + sessionStorage.getItem("userId"));

				setIsForgetPassSuccess('Successfully OTP Sent!');
				setforgotpassdiv(0); // Close the login window on success
				set_resetPass(1);
			} else {
				setIsForgetPassSuccess(false); // Set error
				setResponseMessage(response.data.message || 'OTP sent failed.');
			}
		} catch (error) {
			console.log('Error during login.', error);
			// Extract and display the exact error message if available
			const errorMsg = error.response?.data?.message || 'Error occurred during reset.';
			setIsForgetPassSuccess(false); // Set error
			setResponseMessage(errorMsg);
		} finally {
			setIsLoading(false); // Set loading to false
		}
	};

	const resetPassword = async (event) => {
		event.preventDefault(); // Prevent default form submission behavior

		console.log("resetPassword");
		setResponseMessage(''); // Clear any previous response message
		setIsLoading(true); // Set loading to true

		console.log("_Email :" + _Email);
		console.log("_OTP :" + _OTP);
		console.log("_Password :" + _Password);

		try {
			const payload = {
				email: _Email,
				otp: _OTP,
				newPassword: _Password,
			};

			const response = await axios.post('https://api.guardhawk.io/api/Account/resetPassword', payload);
			console.log(response.data);

			if (response.data.isSuccess) {
				const token = response.data.data.token; // Extract the token from the response
				setUserToken(token);
				console.log('Password resets successfully!');
				sessionStorage.setItem('userToken', token);
				sessionStorage.setItem('email', response.data.data.email);
				sessionStorage.setItem('userId', response.data.data.userId);
				console.log("userID get : " + sessionStorage.getItem("userId"));

				setIsResetPassSuccess(true); // Set success
				setResponseMessage('Password resets successful!');
				//setforgotpassdiv(0); // Close the login window on success
				set_resetPass(0);
				set_login(1);
			} else {
				setIsResetPassSuccess(false); // Set error
				setResponseMessage(response.data.message || 'Password resets failed.');
			}
		} catch (error) {
			console.log('Error during login.', error);
			// Extract and display the exact error message if available
			const errorMsg = error.response?.data?.message || 'Error occurred during reset.';
			setIsForgetPassSuccess(false); // Set error
			setResponseMessage(errorMsg);
		} finally {
			setIsLoading(false); // Set loading to false
		}
	};

	// Close the popup div
	const closediv = () => {
		setIsLoginSuccess(null); // Reset the state to hide the divs
		setIsRegisterSuccess(null);
		setIsForgetPassSuccess(null);
		setIsResetPassSuccess(null);
	};

	const forgotpassdiv = () => {
		setforgotpassdiv(1); // Reset the state to hide the divs
		set_login(0);
		set_register(0);
	};

	const closeForgotPassDiv = () => {
		setforgotpassdiv(0); // Reset the state to hide the divs
		set_login(1);
		set_resetPass(0);
	};

	async function disconnectWallet() {
		setConnected(false);
		disconnect();
		window.location.reload(true);
	}

	return (
		<div className='allWrapMain'>

			{_register > 0 ?
				<div class="register-overlay">
					<div class="register-popup">
						<div className="close2" onClick={() => disconnectWallet()}>✖</div>

						<h2>Register</h2>
						<form onSubmit={registerUser} method="post" encType="multipart/form-data">
							<div className='regsiterFlex'>
								<div className='leftSectionWidth'>
									<input type="text" placeholder="Nombre" required name="_FirstName" value={_FirstName}
										onChange={event => setFirstName(event.target.value)} />
									<input type="text" placeholder="Apellido" required name="_LastName" value={_LastName}
										onChange={event => setLastName(event.target.value)} />
									<input type="email" placeholder="Ingresa tu correo electrónico" required name="_Email" value={_Email}
										onChange={event => setEmail(event.target.value)} />
									{/*<input type="password" placeholder="Confirm your password" required name="_Password" value={_Password}
										onChange={event => setPassword(event.target.value)} />*/}

									{/*<input type="password" placeholder="Ingresa tu contraseña" required name="_Password" value={_Password}
										onChange={event => setPassword(event.target.value)} />*/}

									<div className='passDiv'>
										<input
											className="fake-password"
											placeholder="Ingresa tu contraseña"
											required name="_Password" value={_Password}
											onChange={event => setPassword(event.target.value)}
											type={showPassword ? "text" : "password"}
										/>
										<div className='eyeDiv' onClick={togglePasswordVisibility}>
											{showPassword ? <img className='eye' src={eyeOpen} /> : <img className='eye' src={eyeClose} />} {/* Toggle eye icon */}
										</div>
									</div>

									{passwordError ?
										<p style={{ color: 'red' }}>{passwordError}</p> :
										<div className='notePass'>La contraseña debe tener 1 letra minúscula, 1 letra mayúscula, 1 dígito, 1 <span className='specialTxt' title='!@#$%^&*?'> carácter especial</span> y la longitud debe estar entre 8 y 11 caracteres.</div>
									}

								</div>


								<div className='rightSectionWidth'>

									<label id="birthTxt">Fecha de nacimiento</label>
									<div className="dob-section">
										<Select
											options={days}
											value={day ? { value: day, label: day } : null}
											onChange={(selected) => setDay(selected.value)}
											placeholder="Día"
											styles={customStyles}
											required
											id="selectSection"

										/>
										<Select
											options={months}
											value={month ? { value: month, label: month } : null}
											onChange={(selected) => setMonth(selected.value)}
											placeholder="Mes"
											styles={customStyles}
											required
											id="selectSection"
										/>
										<Select
											options={years}
											value={year ? { value: year, label: year } : null}
											onChange={(selected) => setYear(selected.value)}
											placeholder="Año"
											styles={customStyles}
											required
											id="selectSection"

										/>


										<Select
											options={options}
											name="_Country" value={_Country}
											onChange={changeHandler}
											placeholder="Selecciona tu país"
											styles={customStyles} // Apply custom styles		
											id="selectSection"

										/>
										<input type="text" placeholder="Código postal" required name="_PostalCode" value={_PostalCode}
											onChange={event => setPostalCode(event.target.value)} />

									</div>

								</div>
							</div>
							<button type="submit" class="register-btn">Registrarse</button>
							<div class="cancel-btn" onClick={() => disconnectWallet()}>Cancelar</div>
						</form>

						<div className='accountCreate'>¿Ya tienes una cuenta? <span className='rh' onClick={loginWindow}>Inicia sesión aquí</span></div>

					</div>
				</div> : null}

			{_connected && (_login > 0) ? (

				<div class="login-overlay">
					<div class="login-popup">
						<div className="close2" onClick={() => disconnectWallet()}>✖</div>

						<h2>Login Iniciar sesión</h2>
						<form onSubmit={loginUser} method="post" encType="multipart/form-data">
							<input type="text" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$" placeholder="Ingresa tu correo electrónico" required name="_Email" value={_Email}
								onChange={event => setEmail(event.target.value)} />
							<input type="password" placeholder="Ingresa tu contraseña" required name="_Password" value={_Password}
								onChange={event => setPassword(event.target.value)} />
							{/*<div>
							<input type="checkbox" id="remember-me" />
							<label id="remember-me2" for="remember-me">Remember me</label>
						</div>*/}
							<button type="submit" class="login-btn">Iniciar sesión</button>
							<div class="forgot-password" onClick={forgotpassdiv}>¿Olvidaste tu contraseña?</div>
						</form>
						<div className='accountCreate'>¿No tienes una cuenta? <span className='rh' onClick={regsiterWindow}>Regístrate aquí</span></div>
					</div>
				</div>
			) : null}

			{_forgotpassdiv > 0 ?
				<div class="login-overlay">
					<div class="login-popup">
						<div className="close2" onClick={closeForgotPassDiv}>✖</div>

						<h2>Resetear contraseña</h2>
						<form onSubmit={forgotPassword} method="post" encType="multipart/form-data">
							<input type="text" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$" placeholder="Escribe tu email" required name="_Email" value={_Email}
								onChange={event => setEmail(event.target.value)} />

							{/*<div>
							<input type="checkbox" id="remember-me" />
							<label id="remember-me2" for="remember-me">Remember me</label>
						</div>*/}
							<div className='notePass'>Recibirás un código de recuperación. Ten presente que puede llegar a spam</div>
							<button type="submit" class="login-btn">Envía requisito</button>
						</form>
						<div className='accountCreate'>¿No tienes una cuenta? <span className='rh' onClick={regsiterWindow}>Regístrate aquí</span></div>
					</div>
				</div> : null}

			{/*<div className="popup-containerMain3">
						<div className="popup-container3">
							<div className="popup success">
								<div className="close" onClick={closediv}>✖</div>
								<p className="responseSuccess">{responseMessage}</p>
								<div className="review"><span className='log' onClick={afterRegister}>Login Now</span></div>
							</div>
						</div>
					</div>*/}
			<div>
				{isLoginSuccess === true && (
					<div className="popup-containerMain3">
						<div className="popup-container3">
							<div className="popup success">
								<div className="close" onClick={closediv}>✖</div>
								<p className="responseSuccess">{responseMessage}</p>
								<div className="review">Gracias por unirte a nosotros.</div>
							</div>
						</div>
					</div>
				)}

				{isLoginSuccess === false && (
					<div className="popup-containerMain3">
						<div className="popup-container3">
							<div className="popup error">
								<div className="close" onClick={closediv}>✖</div>
								<p className='responseSuccess'>{responseMessage}</p>
								<div className='review2'>Por favor inténtalo de nuevo.</div>
							</div>
						</div>
					</div>
				)}

				{isResetPassSuccess === true && (
					<div className="popup-containerMain3">
						<div className="popup-container3">
							<div className="popup success">
								<div className="close" onClick={closediv}>✖</div>
								<p className="responseSuccess">{responseMessage}</p>
								<div className="review">Su contraseña ha sido restablecida exitosamente</div>
							</div>
						</div>
					</div>
				)}

				{isResetPassSuccess === false && (
					<div className="popup-containerMain3">
						<div className="popup-container3">
							<div className="popup error">
								<div className="close" onClick={closediv}>✖</div>
								<p className='responseSuccess'>{responseMessage}</p>
								<div className='review2'>Por favor inténtalo de nuevo.</div>
							</div>
						</div>
					</div>
				)}

				{_resetPass > 0 ? (
					<div class="login-overlay">
						<div class="login-popup">
							<div className="close2" onClick={closeForgotPassDiv}>✖</div>

							<h2>Password Reset</h2>

							<form onSubmit={resetPassword} method="post" encType="multipart/form-data">
								<input type="text" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$" placeholder="Escribe tu email" required name="_Email" value={_Email}
									onChange={event => setEmail(event.target.value)} />

								<input type="text" placeholder="OTP" required name="_OTP" value={_OTP}
									onChange={event => set_OTP(event.target.value)} />

								{/*<input type="password" placeholder="Ingresa tu contraseña" required name="_Password" value={_Password}
									onChange={event => setPassword(event.target.value)} />*/}

								<div className='passDiv'>
									<input
										placeholder="Ingresa tu contraseña"
										required name="_Password" value={_Password}
										onChange={event => setPassword(event.target.value)}
										type={showPassword ? "text" : "password"}
									/>
									<div className='eyeDiv' onClick={togglePasswordVisibility}>
										{showPassword ? <img className='eye' src={eyeOpen} /> : <img className='eye' src={eyeClose} />} {/* Toggle eye icon */}
									</div>
								</div>

								{passwordError ?
									<p style={{ color: 'red' }}>{passwordError}</p> :
									<div className='notePass'>La contraseña debe tener 1 letra minúscula, 1 letra mayúscula, 1 dígito, 1 <span className='specialTxt' title='!@#$%^&*?'> carácter especial</span> y la longitud debe estar entre 8 y 11 caracteres.</div>
								}

								{/*	<div className='notePass'>La contraseña debe tener 1 letra minúscula, 1 letra mayúscula, 1 dígito, 1 carácter especial y la longitud debe estar entre 8 y 11 caracteres.</div>
								passwordError && <p style={{ color: 'red' }}>{passwordError}</p>*/}

								{/*<div>s
							<input type="checkbox" id="remember-me" />
							<label id="remember-me2" for="remember-me">Remember me</label>
						</div>*/}
								<button type="submit" class="login-btn">Reset</button>
							</form>
						</div>
					</div>
				) : null}

				{isForgetPassSuccess === false && (
					<div className="popup-containerMain3">
						<div className="popup-container3">
							<div className="popup error">
								<div className="close" onClick={closediv}>✖</div>
								<p className='responseSuccess'>{responseMessage}</p>
								<div className='review2'>Por favor inténtalo de nuevo.</div>
							</div>
						</div>
					</div>
				)}

				{isRegisterSuccess === false && (
					<div className="popup-containerMain3">
						<div className="popup-container3">
							<div className="popup error">
								<div className="close" onClick={closediv}>✖</div>
								<p className='responseSuccess'>{responseMessage}</p>
								<div className='review2'>Por favor inténtalo de nuevo.</div>
							</div>
						</div>
					</div>
				)}

				{isLoading && (
					<div className="popup-containerMain3">
						<div className="popup-container3">
							<div className="popup error">
								<p class="responseSuccess">Cargando...</p>
								<div className='responseSuccess'>Espere mientras procesamos su solicitud</div>
							</div>
						</div>
					</div>
				)}

			</div>


			{/*{_ErrorMsg1 ? (
				<div className="popup-containerMain3">
					<div className="popup-container3">
						<div className="popup success">
							<div className="close" onClick={closediv}>✖</div>
							<p className="responseSuccess">Sorry Something went wrong</p>
							<div className='review2'>An error occurred while processing your request. Por favor inténtalo de nuevo.</div>
						</div>
					</div>
				</div>)
				: null}

			{_loading1 ? (
				<div className="popup-containerMain3">
					<div className="popup-container3">
						<div className="popup success">
							<div className="close" onClick={closediv}>✖</div>
							<p className="responseSuccess">Loading..</p>
							<div className="review">Gracias por unirte a nosotros.</div>
						</div>
					</div>
				</div>)
				: null}

				
			{_success1 ? (
				<div className="popup-containerMain3">
					<div className="popup-container3">
						<div className="popup success">
							<div className="close" onClick={closediv}>✖</div>
							<p className="responseSuccess"></p>
							<div className="review">Gracias por unirte a nosotros.</div>
						</div>
					</div>
				</div>)
				: null}
			*/}

		</div>
	)

}
export default RegisterLogin;
