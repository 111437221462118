/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import React, { useEffect, useMemo, useState } from 'react';
import { Web3Button, Web3Modal, useWeb3Modal } from '@web3modal/react';
import { mainnet, useDisconnect, useAccount, useContractRead, useContractReads, useContractWrite, useNetwork, usePublicClient, useSwitchNetwork } from 'wagmi';
import { createPublicClient, formatEther, http, parseEther } from 'viem';
import { polygon } from 'wagmi/chains';
import '../App.css';
import logo from '../assets/logo.png';
import home from '../assets/home.svg';
import doller from '../assets/doller.png';
import bars from '../assets/bars.png';
import notification from '../assets/notification.png';
import spanish from '../assets/spanish.png';
import off from '../assets/power-off.png';
import user from '../assets/user.png';
import arrow from '../assets/arrow.png';
import gift from '../assets/gift.svg';
import audit from '../assets/auditace.png';
import doc from '../assets/file.png';
import edit from '../assets/edit.png';
import upload from '../assets/profile_image.png';
import { base } from 'wagmi/chains';
import Header from '../components/headers';
import grid from '../assets/grid.png';
import HeaderMobile from '../components/headersMobile';
import HeaderNavBar from '../components/headersNavBar';
import on from '../assets/power-on.png';
import axios from 'axios';
import RegisterLogin from '../components/regsiterAndLogin';
import eyeOpen from '../assets/show.png';
import eyeClose from '../assets/hide.png';
import help from '../assets/help.png';
import english from '../assets/en.png';
import { useNavigate, useLocation } from 'react-router-dom';

var Scroll = require('react-scroll');

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

const tweet = () => {
	window.open("#");
}

const addPOL = () => {
	window.open("https://support.metamask.io/getting-started/how-to-use-the-metamask-mobile-browser");
};

let ABIToken = [
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "Paused",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "Unpaused",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			}
		],
		"name": "allowance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "burn",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "burnFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimals",
		"outputs": [
			{
				"internalType": "uint8",
				"name": "",
				"type": "uint8"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "subtractedValue",
				"type": "uint256"
			}
		],
		"name": "decreaseAllowance",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "addedValue",
				"type": "uint256"
			}
		],
		"name": "increaseAllowance",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "pause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "transfer",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "unpause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	}
];
let addressToken = "0xDcbF2647B9ccb7F85a3Da06446B670f2FD8ca643"

let ABI = [
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_token",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [],
		"name": "Cancel",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [],
		"name": "Claim",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "softCap",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "hardCap",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint32",
				"name": "startAt",
				"type": "uint32"
			},
			{
				"indexed": false,
				"internalType": "uint32",
				"name": "endAt",
				"type": "uint32"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "minAmount",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "maxAmount",
				"type": "uint256"
			}
		],
		"name": "Launch",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "caller",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "PledgeCanceled",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "caller",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "PledgeConfirmed",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "caller",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "PledgeIntention",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "caller",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Refund",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "EURtoPOL",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "POLToEUR",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "POLToGHUT",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "approvedList",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "cancel",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_wallet",
				"type": "address"
			}
		],
		"name": "cancelPledgeTo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "canceled",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "claim",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "claimed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_wallet",
				"type": "address"
			}
		],
		"name": "confirmPledge",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimalCorrecter",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimalCorrecter2",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "deposit",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "receiver",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "distribution",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "eligibleGHUTTokens",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "endAt",
		"outputs": [
			{
				"internalType": "uint32",
				"name": "",
				"type": "uint32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "exchangeRate",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getApprovedList",
		"outputs": [
			{
				"internalType": "address[]",
				"name": "",
				"type": "address[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getBalance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getChainlinkDataFeedLatestAnswer_USD_EUR",
		"outputs": [
			{
				"internalType": "int256",
				"name": "",
				"type": "int256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getChainlinkDataFeedLatestAnswer_USD_POL",
		"outputs": [
			{
				"internalType": "int256",
				"name": "",
				"type": "int256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getHardCapInPol",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getMaxAmountInPol",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getMinAmountInPol",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getPledgeAddressList",
		"outputs": [
			{
				"internalType": "address[]",
				"name": "",
				"type": "address[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getSoftCapInPol",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "hardCap",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "holded",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "holdedAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "holdedAmountInEuros",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_softCap",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_hardCap",
				"type": "uint256"
			},
			{
				"internalType": "uint32",
				"name": "_startAt",
				"type": "uint32"
			},
			{
				"internalType": "uint32",
				"name": "_endAt",
				"type": "uint32"
			},
			{
				"internalType": "uint256",
				"name": "_minAmount",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_maxAmount",
				"type": "uint256"
			}
		],
		"name": "launch",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "maxAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "minAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "pledge",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "pledgeList",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "pledged",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "pledgedAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "refund",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "wallet",
				"type": "address"
			}
		],
		"name": "refundTo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_df",
				"type": "address"
			}
		],
		"name": "setDataFeed_USD_EUR",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_df",
				"type": "address"
			}
		],
		"name": "setDataFeed_USD_POL",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_decimalCorrecter",
				"type": "uint256"
			}
		],
		"name": "setDecimalCorrecter",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_decimalCorrecter2",
				"type": "uint256"
			}
		],
		"name": "setDecimalCorrecter2",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint32",
				"name": "_endAt",
				"type": "uint32"
			}
		],
		"name": "setEndAt",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_exchangeRate",
				"type": "uint256"
			}
		],
		"name": "setExchangeRate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_hardCap",
				"type": "uint256"
			}
		],
		"name": "setHardCap",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_maxAmount",
				"type": "uint256"
			}
		],
		"name": "setMaxAmount",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_minAmount",
				"type": "uint256"
			}
		],
		"name": "setMinAmount",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_pledged",
				"type": "uint256"
			}
		],
		"name": "setPledged",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_softCap",
				"type": "uint256"
			}
		],
		"name": "setSoftCap",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint32",
				"name": "_startAt",
				"type": "uint32"
			}
		],
		"name": "setStartAt",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_tokenContract",
				"type": "address"
			}
		],
		"name": "setTokenContract",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "softCap",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "startAt",
		"outputs": [
			{
				"internalType": "uint32",
				"name": "",
				"type": "uint32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "token",
		"outputs": [
			{
				"internalType": "contract IERC20",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "tokenWithdrawal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdrawAnyLeftbalance",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"stateMutability": "payable",
		"type": "receive"
	}
];
let address = "0xfFe1d6AB2733027aaB7fEb7f49218fb5Da888f20"

const MyAccount = () => {

	const [_navbarOpen, set_navbarOpen] = useState(0)
	const [_percentage, setPercentage] = useState('0%')
	const navigate = useNavigate();

	const { open } = useWeb3Modal()
	const { chain } = useNetwork()
	const { switchNetwork } = useSwitchNetwork()
	const clientID = "2f8f716d-b303-481b-80ef-febaf341524b";
	const httpURL = "https://polygon-mainnet.infura.io/v3/9aad89c8e515457ab8b7805f5da593ea"
	const [showErrorDiv, setshowErrorDiv] = useState(false);
	const [_nameEdit, set_nameEdit] = useState(0);
	const [_ImageSubmitButton, set_ImageSubmitButton] = useState(0);
	const [_emailEdit, set_emailEdit] = useState(0);
	const [_passwrdEdit, set_passwrdEdit] = useState(0);
	const [file, setFile] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [_email, set_email] = useState('');
	const [_password, set_password] = useState('');
	const [_totalSupply, settotalSupply] = useState(0);
	const [statusError, setstatusError] = useState(false);
	const [statusLoading, setstatusLoading] = useState(false);
	const [_owner, set_owner] = useState("");
	const [myNFTWallet, setmyNFTWallet] = useState(0);
	const [_public_mint_status, set_public_mint_status] = useState("");
	const [_connected, setConnected] = useState(false);
	const [_registerDiv, set_registerDiv] = useState(0);
	const [_dashboardDiv, set_dashboardDiv] = useState(0);
	const [_name, set_name] = useState("");
	const { disconnect } = useDisconnect();
	const [_success1, setSuccessMsg1] = useState("");
	const [_loading1, setLoadingMsg1] = useState("");
	const [_ErrorMsg1, setErrorMsg1] = useState("");
	const [success, setsuccess] = useState(false);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [txnHash, setTxnHash] = useState('');
	const [_Password2, setPassword2] = useState('');
	const [_Email, setEmail] = useState('');
	const [_FirstName, setFirstName] = useState('');
	const [_LastName, setLastName] = useState('');
	const [email, setDisplayEmail] = useState('');
	const [displayName, setDisplayName] = useState('');
	const [responseMessage, setResponseMessage] = useState('');
	const [isResponseMessage, setIsResponseSuccess] = useState(null);
	const [_Password, setPassword] = useState(''); // State for password
	const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility
	const [_ImgData, setImgData] = useState('');

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword); // Toggle visibility
	};

	const [selectedLanguage, setSelectedLanguage] = useState('Español'); // Initial language

	// Handle language change
	const handleLanguageChange = (language) => {
		setSelectedLanguage(language);

		// Redirect to the appropriate routes based on language
		if (language === 'English') {
			navigate('/en'); // Navigate to English home page
		} else {
			navigate('/'); // Navigate to Spanish home page
		}
	};

	const { address: walletAddress } = useAccount({
		async onConnect() {
			handleConnect()
		}
	})

	//const transport = webSocket(websocketUrl);
	const transport = http(httpURL);

	const publicClient = createPublicClient({
		chain: polygon,
		transport
	})

	var contract = {
		address: address,
		abi: ABI
	}

	var contract2 = {
		address: addressToken,
		abi: ABIToken
	}

	async function handleConnect() {
		if (chain.id !== 137) {
			switchNetwork(137);
		}

		//await fetchNFTs();
		setConnected(true);
	}

	function formatNumber(number) {
		// Convert number to string and split into integer and decimal parts
		let [integerPart, decimalPart] = number.toString().split('.');

		// Add commas every three digits from the right for the integer part
		integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

		// Combine the integer part with the decimal part if it exists
		return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
	}

	useEffect(() => {

		const storedEmail = sessionStorage.getItem('email');
		const storedDisplayName = sessionStorage.getItem('displayName');
		const storedUserID = sessionStorage.getItem('userId');

		const imgData = sessionStorage.getItem('imgUrl');

		console.log("storedEmail" + storedEmail);
		console.log("DisplayName" + storedDisplayName);
		console.log("userId" + storedUserID);
		console.log("imgData************" + imgData);
		setImgData(imgData);

		if (storedEmail && storedDisplayName) {
			setEmail(storedEmail);
			setDisplayName(storedDisplayName);
		}

		// Get the current page's URL
		const currentURL = window.location.href;

		// Check if the URL contains "localhost"
		if (currentURL.includes("localhost")) {
			console.log("The URL contains 'localhost'.");
			// You can add additional actions here if needed
		} else {
			console.log("The URL does not contain 'localhost'.");
		}

		$(document).ready(() => {
			$('.photo').change(function () {
				const file = this.files[0];
				console.log(file);
				//setSelectedFile(file);
				if (file) {
					let reader = new FileReader();
					reader.onload = function (event) {
						console.log(event.target.result);
						$('#imgPreview').attr('src', event.target.result);
					}
					reader.readAsDataURL(file);
				}
			});
		});

		if (_connected) {
		}

	}, [_connected, walletAddress, _ImgData]);

	function shortenAddress(walletAddress) {
		try {
			return _connected
				? walletAddress.slice(0, 3) + "..." + walletAddress.slice(-4)
				: "Connect";
		} catch (error) {
			console.log(error);
		}
	}

	async function disconnectWallet() {
		sessionStorage.clear();
		setConnected(false);
		disconnect();
		window.location.reload(true);
		window.location.href = '/'
	}

	const { writeAsync } = useContractWrite({
		onError(error) {
			if (error.message.includes("balance")) {
				setstatusError(true);
				setstatusLoading(false);
			}
		},
	});


	async function imageGet() {
		// Get userId from sessionStorage
		const userId = sessionStorage.getItem('userId');
		if (!userId) {
			console.error('User ID not found in sessionStorage.');
			return;
		}
		try {
			if (file) {
				const fileName = file.name;

				// Convert the file to base64 encoding
				const fileReader = new FileReader();
				fileReader.onload = async () => {

					console.log("Image Loading....");
					const base64String = fileReader.result.split(',')[1];

					try {
						const payload = {
							id: sessionStorage.getItem("userId"),
							imgUrl: base64String,
						};


						const response = await axios.post('https://api.guardhawk.io/api/User/updateUserImage', payload, {
							headers: {
								'Authorization': `Bearer ${sessionStorage.getItem("userToken")}`
							}
						});

						console.log('File sent successfully.');
						sessionStorage.setItem('imgUrl', base64String);
						console.log(response.data);

						if (response.data.isSuccess) {
							try {

								console.log("I'm getting the img");
								const response = await axios.get(`https://api.guardhawk.io/api/User/${userId}`, {
									headers: {
										'Authorization': `Bearer ${sessionStorage.getItem("userToken")}`
									}
								});

								console.log(response.data);

								if (response.data.isSuccess) {
									var fullImg = 'data:image/png;base64,' + response.data.data.imgUrl;
									sessionStorage.setItem('imgUrl', fullImg);
									setImgData(fullImg);

									console.log("GET IMG URL : " + sessionStorage.getItem('imgUrl'));
									console.log("_ImgData3232323 : " + _ImgData);
									set_ImageSubmitButton(0);
								}

								// You can now use response.data to update the user image section
							} catch (error) {
								console.error('Error fetching user image:', error.response?.data || error.message);
							}
						}

					} catch (error) {
						console.log('Error sending file.', error);
						setResponseMessage('Error sending file.');
					} finally {
						setIsLoading(false); // Set loading to false
					}

				};

				fileReader.onerror = () => {
					console.log('Error reading file.');
					setResponseMessage('Error reading file.');
					setIsLoading(false); // Set loading to false
				};

				fileReader.readAsDataURL(file);
			} else {
				setResponseMessage('Please select a file.');
				setIsLoading(false); // Set loading to false
			}
		} catch (err) {
			console.log(err);
			setResponseMessage('Error occurred.');
			setIsLoading(false); // Set loading to false
		}

	}

	const handleFileChange = (event) => {
		//setSelectedFile(event.target.files[0]);
		setFile(event.target.files[0]);
		console.log("File Changed ---- :" + event.target.files[0].name);
		showImageSubmitButton();


	};

	const updateUser = async (event) => {
		event.preventDefault(); // Prevent default form submission behavior
		setResponseMessage(''); // Clear any previous response message
		setIsLoading(true);

		try {

			try {
				const payload = {
					id: sessionStorage.getItem("userId"),
					firstName: _FirstName,
					lastName: _LastName,
				};

				const response = await axios.post('https://api.guardhawk.io/api/User/updateUserName', payload, {
					headers: {
						'Authorization': `Bearer ${sessionStorage.getItem("userToken")}`
					}
				});

				console.log(response.data);

				if (response.data.isSuccess) {
					setIsResponseSuccess(true);

					sessionStorage.setItem('displayName', (response.data.data.firstName + " " + response.data.data.lastName));

					setResponseMessage('Password update successful! Redirecting to login...');
				} else {
					console.log('Password update was not successful:', response.data.message);
					setIsResponseSuccess(false);
					setResponseMessage(response.data.message || 'Registration failed.');
				}

			} catch (error) {
				console.log('Error during registration:', error);
				//If there's a server error, show the error message if available
				setResponseMessage(error.response?.data?.message || 'Error occurred during registration.');
				setIsResponseSuccess(false);
			} finally {
				setIsLoading(false); // Set loading to false
			}

		} catch (err) {
			console.log(err);
			setIsLoading(false); // Set loading to false
		}
	};

	const updateUser2 = async (event) => {
		event.preventDefault(); // Prevent default form submission behavior
		setResponseMessage(''); // Clear any previous response message
		setIsLoading(true);

		try {

			try {

				const payload = {
					password: _Password,
				};

				const response = await axios.put('https://api.guardhawk.io/api/User/changePassword', payload, {
					headers: {
						'Authorization': `Bearer ${sessionStorage.getItem("userToken")}`
					}
				});

				console.log(response.data);

				if (response.data.isSuccess) {
					setIsResponseSuccess(true);

					sessionStorage.setItem('displayName', (response.data.data.firstName + " " + response.data.data.lastName));

					setResponseMessage('Password update successful! Redirecting to login...');
				} else {
					console.log('Password update was not successful:', response.data.message);
					setIsResponseSuccess(false);
					setResponseMessage(response.data.message || 'Registration failed.');
				}

			} catch (error) {
				console.log('Error during registration:', error);
				//If there's a server error, show the error message if available
				setResponseMessage(error.response?.data?.message || 'Error occurred during registration.');
				setIsResponseSuccess(false);
			} finally {
				setIsLoading(false); // Set loading to false
			}

		} catch (err) {
			console.log(err);
			setIsLoading(false); // Set loading to false
		}
	};

	const updateImage = async (event) => {
		event.preventDefault(); // Prevent default form submission behavior
		setResponseMessage(''); // Clear any previous response message
		setIsLoading(true); // Set loading to true

		try {
			if (file) {
				const fileName = file.name;

				// Convert the file to base64 encoding
				const fileReader = new FileReader();
				fileReader.onload = async () => {

					console.log("Image Loading....");
					const base64String = fileReader.result.split(',')[1];

					try {
						const payload = {
							id: sessionStorage.getItem("userId"),
							imgeUrl: base64String,
						};


						const response = await axios.post('https://api.guardhawk.io/api/User/updateUserImage', payload, {
							headers: {
								'Authorization': `Bearer ${sessionStorage.getItem("userToken")}`
							}
						});

						console.log('File sent successfully.');
						console.log(response.data);
						setResponseMessage(response.data || 'Success');
					} catch (error) {
						console.log('Error sending file.', error);
						setResponseMessage('Error sending file.');
					} finally {
						setIsLoading(false); // Set loading to false
					}

				};

				fileReader.onerror = () => {
					console.log('Error reading file.');
					setResponseMessage('Error reading file.');
					setIsLoading(false); // Set loading to false
				};

				fileReader.readAsDataURL(file);
			} else {
				setResponseMessage('Please select a file.');
				setIsLoading(false); // Set loading to false
			}
		} catch (err) {
			console.log(err);
			setResponseMessage('Error occurred.');
			setIsLoading(false); // Set loading to false
		}
	};

	async function nameEdit() {
		set_nameEdit(1);
	}

	async function showImageSubmitButton() {
		set_ImageSubmitButton(1);
	}

	async function emailEdit() {
		set_emailEdit(1);
	}

	async function passwrdEdit() {
		set_passwrdEdit(1);
	}

	async function closeWindow() {
		set_nameEdit(0);
		set_emailEdit(0);
		set_passwrdEdit(0);
		sessionStorage.setItem("password", '');
		console.log("GET ITEM PASS" + sessionStorage.getItem('password'))
		setPassword('');
		setIsResponseSuccess('');
		setIsLoading('');
		setResponseMessage('');
	}

	function Base64Image({ base64Data }) {
		return (
			<div>
				<img src={`data:image/png;base64,${base64Data}`} alt="Base64 Image" />
			</div>
		);
	}

	return (
		<div>
			{_connected ?
				<div>
					<div className='allWrap'>
						<div class="light">
							{_connected ? (
								<div className="connectT" onClick={() => disconnectWallet()}>
									{walletAddress === "" ? "Conectar Billetera Web3" : shortenAddress(walletAddress)}
								</div>
							) : (
								<div className="connectT" onClick={() => { open(); }}>Conectar Billetera Web3</div>
							)}
							<div className='onlyMob'>
								<HeaderMobile />
							</div>
							<div className='page1Main'>
								<Header />

								<div className='rightSection'>
									<div className='mainT'>MI CUENTA</div>

									<div class="uploadMain">
										<div className='listT'>DATOS DE PERFIL</div>
										<div className='line3'></div>

										<div class="profile">

											<div className='upload'>

												<div>
													<div class="upload">
														<div id="block_container">
															<div class="fileBtn">

																<input class="choosebtn" type="file" onChange={handleFileChange} name="file" accept="image/png"
																	className="photo" required="true" id="actual-btn" hidden />

																<label for="actual-btn">
																	<div><img id="imgPreview" src={_ImgData} alt="Preview Here" /></div>
																	<div>Cambiar foto de perfil</div>

																</label>

																<p></p>

																{_ImageSubmitButton > 0 ?
																	<button onClick={imageGet} className='saveEdits2'>Save Image</button>
																	: null}
															</div>

														</div>
													</div>
												</div>

											</div>

											<div className='upload2'>
												<div className='editSection'>
													<div className='editT'>NOMBRE COMPLETO</div>
													<div className='editInputArea'>
														<input placeholder={sessionStorage.getItem('displayName')}></input>
														<div className='editHere'>
															<img onClick={nameEdit} src={edit} />
															<div onClick={nameEdit}>Modificar</div>
														</div>
													</div>
												</div>

												<div className='editSection'>
													<div className='editT'>CORREO ELECTRÓNICO</div>
													<div className='editInputArea'>
														<input placeholder={sessionStorage.getItem('email')}></input>
													</div>
												</div>

												<div className='editSection'>
													<div className='editT'>CONTRASEÑA ACTUAL</div>
													<div className='editInputArea'>
														<input type="password" class="fake-password" placeholder="●●●●●●●●" />
														<div className='editHere'>
															<img onClick={passwrdEdit} src={edit} />
															<div onClick={passwrdEdit}>Modificar</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className='onlyMob'>
										<div className='section4'>
											<div className='pagesDiv-2'>

												<div className="language-selection">
													<img src={selectedLanguage === 'Español' ? spanish : english} alt={selectedLanguage} />
													<span className="grayTxt">{selectedLanguage}</span>
													<div className="dropdown2">
														{selectedLanguage === 'Español' ? (
															<span onClick={() => handleLanguageChange('English')}>
																<img src={english} alt="English" /> English
															</span>
														) : (
															<span onClick={() => handleLanguageChange('Español')}>
																<img src={spanish} alt="Español" /> Español
															</span>
														)}
													</div>
												</div>

												<div onClick={addPOL} ><img src={help} /><span className='grayTxt'>Cómo agregar POL</span></div>
												{_connected ?
													<div onClick={disconnectWallet}><img src={on} /><span style={{ color: '#AD5EFF' }} className='grayTxt'>Cerrar sesion</span></div> :
													<div onClick={disconnectWallet}><img src={off} /><span className='grayTxt'>Cerrar sesion</span></div>
												}
											</div>
										</div>
									</div>
								</div>
							</div>

							{_nameEdit > 0 ?

								<div class="popup-containerMain">

									<div class="popup-container3">
										<div className="close" onClick={closeWindow}>✖</div>
										<div className='editTPopUp'>CAMBIAR NOMBRE
										</div>
										<form onSubmit={updateUser} method="post" encType="multipart/form-data">
											<div className='validateInputDiv2'>
												<input name='_FirstName' value={_FirstName}
													onChange={event => setFirstName(event.target.value)} type="text" required placeholder='Nombre de pila' />
											</div>

											<div className='validateInputDiv2'>
												<input name='_LastName' value={_LastName}
													onChange={event => setLastName(event.target.value)} type="text" required placeholder='Apellido' />
											</div>

											<button className='saveEdits'>Guardar cambios</button>
										</form>
										<p></p>
										{isResponseMessage === false && (
											<p className='review2'>{responseMessage}</p>
										)}

										{isLoading && (
											<p class="responseSuccess">Actualizando...</p>
										)}

										{isResponseMessage === true && (
											<p className='review'>Actualizado!</p>
										)}


									</div>
								</div> : null}

							{/*_emailEdit > 0 ?
						<div class="popup-containerMain">

							<div class="popup-container3">
								<div className="close" onClick={closeWindow}>✖</div>
								<div className='editTPopUp'>CHANGE EMAIL
								</div>
								<form onSubmit={updateUser2} method="post" encType="multipart/form-data">
									<div className='validateInputDiv2'>
										<input name='_Email' value={_Email}
											onChange={event => setEmail(event.target.value)} type="text" required placeholder='edusm@gmail.com' />
									</div>
									<button className='saveEdits'>Guardar cambios</button>
								</form>

							</div>
						</div> : null*/}

							{_passwrdEdit > 0 ?

								<div className="popup-containerMain">
									<div className="popup-container3">
										<div className="close" onClick={closeWindow}>✖</div>
										<div className='editTPopUp'>NUEVA CONTRASEÑA</div>
										<form onSubmit={updateUser2} method="post" encType="multipart/form-data">
											<div className='validateInputDiv2'>
												<input
													name='_Password'
													value={_Password}
													onChange={event => setPassword(event.target.value)}
													type={showPassword ? "text" : "password"} // Toggle input type
													className="fake-password"
												/>
												<div onClick={togglePasswordVisibility}>
													{showPassword ? <img className='eye' src={eyeOpen} /> : <img className='eye' src={eyeClose} />} {/* Toggle eye icon */}
												</div>
											</div>
											<button className='saveEdits'>Guardar cambios</button>
										</form>
										<p></p>
										{isResponseMessage === false && (
											<p className='review2'>{responseMessage}</p>
										)}
										{isLoading && (
											<p className="responseSuccess">Actualizando...</p>
										)}
										{isResponseMessage === true && (
											<p className='review'>Actualizado!</p>
										)}
									</div>
								</div>
								: null}

						</div>
						<img className='grid' src={grid} />
					</div>

					<RegisterLogin />

					<div className='onlyMob'>
						<HeaderNavBar />
					</div>
				</div>
				: null}
		</div >
	)

}
export default MyAccount;
