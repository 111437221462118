/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Web3Button, Web3Modal, useWeb3Modal } from '@web3modal/react';
import { mainnet, useDisconnect, useAccount, useContractRead, useContractReads, useContractWrite, useNetwork, usePublicClient, useSwitchNetwork } from 'wagmi';
import { createPublicClient, formatEther, http, parseEther } from 'viem';
import { polygon } from 'wagmi/chains';
import '../App.css';
import doc from '../assets/file.png';
import download from '../assets/downloads.png';
import { base } from 'wagmi/chains';
import Header from '../components/headers';
import grid from '../assets/grid.png';
import HeaderMobile from '../components/headersMobile';
import HeaderNavBar from '../components/headersNavBar';
import axios from 'axios';
import html2pdf from "html2pdf.js";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

//import { jsPDF } from "jspdf";
import RegisterLogin from '../components/regsiterAndLogin';

var Scroll = require('react-scroll');

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

const tweet = () => {
	window.open("#");
}

const tg = () => {
	window.open("#");
}

let ABIToken = [
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "Paused",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "Unpaused",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			}
		],
		"name": "allowance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "burn",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "burnFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimals",
		"outputs": [
			{
				"internalType": "uint8",
				"name": "",
				"type": "uint8"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "subtractedValue",
				"type": "uint256"
			}
		],
		"name": "decreaseAllowance",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "addedValue",
				"type": "uint256"
			}
		],
		"name": "increaseAllowance",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "pause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "transfer",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "unpause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	}
];
let addressToken = "0xDcbF2647B9ccb7F85a3Da06446B670f2FD8ca643"

let ABI = [
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_token",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [],
		"name": "Cancel",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [],
		"name": "Claim",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "softCap",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "hardCap",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint32",
				"name": "startAt",
				"type": "uint32"
			},
			{
				"indexed": false,
				"internalType": "uint32",
				"name": "endAt",
				"type": "uint32"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "minAmount",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "maxAmount",
				"type": "uint256"
			}
		],
		"name": "Launch",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "caller",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "PledgeCanceled",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "caller",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "PledgeConfirmed",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "caller",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "PledgeIntention",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "caller",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Refund",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "EURtoPOL",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "POLToEUR",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "POLToGHUT",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "approvedList",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "cancel",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_wallet",
				"type": "address"
			}
		],
		"name": "cancelPledgeTo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "canceled",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "claim",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "claimed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_wallet",
				"type": "address"
			}
		],
		"name": "confirmPledge",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimalCorrecter",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimalCorrecter2",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "deposit",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "receiver",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "distribution",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "eligibleGHUTTokens",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "endAt",
		"outputs": [
			{
				"internalType": "uint32",
				"name": "",
				"type": "uint32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "exchangeRate",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getApprovedList",
		"outputs": [
			{
				"internalType": "address[]",
				"name": "",
				"type": "address[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getBalance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getChainlinkDataFeedLatestAnswer_USD_EUR",
		"outputs": [
			{
				"internalType": "int256",
				"name": "",
				"type": "int256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getChainlinkDataFeedLatestAnswer_USD_POL",
		"outputs": [
			{
				"internalType": "int256",
				"name": "",
				"type": "int256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getHardCapInPol",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getMaxAmountInPol",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getMinAmountInPol",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getPledgeAddressList",
		"outputs": [
			{
				"internalType": "address[]",
				"name": "",
				"type": "address[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getSoftCapInPol",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "hardCap",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "holded",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "holdedAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "holdedAmountInEuros",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_softCap",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_hardCap",
				"type": "uint256"
			},
			{
				"internalType": "uint32",
				"name": "_startAt",
				"type": "uint32"
			},
			{
				"internalType": "uint32",
				"name": "_endAt",
				"type": "uint32"
			},
			{
				"internalType": "uint256",
				"name": "_minAmount",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_maxAmount",
				"type": "uint256"
			}
		],
		"name": "launch",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "maxAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "minAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "pledge",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "pledgeList",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "pledged",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "pledgedAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "refund",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "wallet",
				"type": "address"
			}
		],
		"name": "refundTo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_df",
				"type": "address"
			}
		],
		"name": "setDataFeed_USD_EUR",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_df",
				"type": "address"
			}
		],
		"name": "setDataFeed_USD_POL",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_decimalCorrecter",
				"type": "uint256"
			}
		],
		"name": "setDecimalCorrecter",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_decimalCorrecter2",
				"type": "uint256"
			}
		],
		"name": "setDecimalCorrecter2",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint32",
				"name": "_endAt",
				"type": "uint32"
			}
		],
		"name": "setEndAt",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_exchangeRate",
				"type": "uint256"
			}
		],
		"name": "setExchangeRate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_hardCap",
				"type": "uint256"
			}
		],
		"name": "setHardCap",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_maxAmount",
				"type": "uint256"
			}
		],
		"name": "setMaxAmount",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_minAmount",
				"type": "uint256"
			}
		],
		"name": "setMinAmount",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_pledged",
				"type": "uint256"
			}
		],
		"name": "setPledged",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_softCap",
				"type": "uint256"
			}
		],
		"name": "setSoftCap",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint32",
				"name": "_startAt",
				"type": "uint32"
			}
		],
		"name": "setStartAt",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_tokenContract",
				"type": "address"
			}
		],
		"name": "setTokenContract",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "softCap",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "startAt",
		"outputs": [
			{
				"internalType": "uint32",
				"name": "",
				"type": "uint32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "token",
		"outputs": [
			{
				"internalType": "contract IERC20",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "tokenWithdrawal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdrawAnyLeftbalance",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"stateMutability": "payable",
		"type": "receive"
	}
];
let address = "0xfFe1d6AB2733027aaB7fEb7f49218fb5Da888f20"

let ABIWETH = [
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "src",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "guy",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "wad",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "dst",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "wad",
				"type": "uint256"
			}
		],
		"name": "Deposit",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "src",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "dst",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "wad",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "src",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "wad",
				"type": "uint256"
			}
		],
		"name": "Withdrawal",
		"type": "event"
	},
	{
		"payable": true,
		"stateMutability": "payable",
		"type": "fallback"
	},
	{
		"constant": true,
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "allowance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"internalType": "address",
				"name": "guy",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "wad",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [],
		"name": "decimals",
		"outputs": [
			{
				"internalType": "uint8",
				"name": "",
				"type": "uint8"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [],
		"name": "deposit",
		"outputs": [],
		"payable": true,
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"internalType": "address",
				"name": "dst",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "wad",
				"type": "uint256"
			}
		],
		"name": "transfer",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"internalType": "address",
				"name": "src",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "dst",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "wad",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"internalType": "uint256",
				"name": "wad",
				"type": "uint256"
			}
		],
		"name": "withdraw",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	}
];
let addressWETH = "0x4200000000000000000000000000000000000006"

const MyInvestments = () => {

	const [_navbarOpen, set_navbarOpen] = useState(0)
	const [_percentage, setPercentage] = useState('0%')
	const contentRefs = useRef([]); // Array to store refs for each response

	const { open } = useWeb3Modal()
	const { chain } = useNetwork()
	const { switchNetwork } = useSwitchNetwork()
	const clientID = "2f8f716d-b303-481b-80ef-febaf341524b";
	const [_amount, set_amount] = useState('');
	const httpURL = "https://polygon-mainnet.infura.io/v3/9aad89c8e515457ab8b7805f5da593ea"
	const [_connected, setConnected] = useState(false);
	const [_downloadPDF, setDownloadPDF] = useState(0);
	const [_responseData, setResponseData] = useState([]);
	const [_softCap, setSoftCap] = useState(0);
	const [_HardCapInPOL, setHardCapInPOL] = useState(0);
	const [_MaxAmount, setMaxAmount] = useState(0);
	const [_MinAmount, setMinAmount] = useState(0);
	const [_GhutToPol, setGhutToPol] = useState(0);
	const [_rate, setRate] = useState(0);
	const [_ghutAmount, setGhutAmount] = useState(0);
	const { disconnect } = useDisconnect();
	const [popupIndex, setPopupIndex] = useState(null); // Track which popup is open

	const { address: walletAddress } = useAccount({
		async onConnect() {
			handleConnect()
		}
	})

	const transport = http(httpURL);

	const publicClient = createPublicClient({
		chain: polygon,
		transport
	})

	var contract = {
		address: address,
		abi: ABI
	}

	var contract2 = {
		address: addressToken,
		abi: ABIToken
	}

	async function handleConnect() {
		if (chain.id !== 137) {
			switchNetwork(137);
		}

		//await fetchNFTs();
		setConnected(true);
	}


	useEffect(() => {

		const getAllUserTxns = async () => {
			console.log("Im in My Investment")
			try {

				try {

					const response = await axios.get('https://api.guardhawk.io/api/Investment/getAllInvestmentByLoggedInUser', {
						headers: {
							'Authorization': `Bearer ${sessionStorage.getItem("userToken")}`
						}
					});

					console.log('File sent successfully.');
					console.log(response.data);

					if (response.data.isSuccess) {

						console.log(response.data.data);
						setResponseData(response.data.data);

					}


				} catch (error) {
					console.log('Error sending file.', error);
				} finally {
				}

			} catch (err) {
				console.log(err);
			}
		};

		async function fetchHardCapInPOL() {
			try {
				var data = await getHardCapInPol();
				let hardCapPOL = Number(data.data);
				console.log("Hard Cap in POL: " + hardCapPOL);

				// Set the hard cap in POL (if you have a state or variable to store it)
				setHardCapInPOL(hardCapPOL);

			} catch (error) {
				console.error("Error fetching data: ", error);
			}
		}

		async function fetchData5() {

			var data = await getSoftCap();
			var softCap = Number(data.data);
			setSoftCap(softCap);
			console.log("softCap : " + softCap);
		}

		async function fetchMinAmount() {
			var data = await getMinAmount();
			let minAmount = Number(data.data);
			setMinAmount(minAmount);
			console.log("minAmount : " + minAmount);
		}


		async function fetchMaxAmount() {
			var data = await getMaxAmount();
			let maxAmount = Number(data.data);
			setMaxAmount(maxAmount);
			console.log("maxAmount : " + maxAmount);
		}

		async function ghutToPol() {
			var ghutToPol = Number(1 * (10 ** 18 / Number(_rate)))
			setGhutToPol(ghutToPol);
			console.log("ghutToPol : " + ghutToPol);
		}

		async function fetchData2() {
			var data = await getRate();
			let rate = Number(data.data);
			setRate(Number(data.data));
			console.log("GHUT AMOUNT : " + (Number(_amount) * Number(data.data)) / 10 ** 18);
			setGhutAmount((Number(_amount) * Number(data.data)) / 10 ** 18);
			console.log("rate : " + rate);
		}

		getAllUserTxns();
		fetchData5();
		fetchHardCapInPOL();
		fetchMinAmount();
		fetchMaxAmount();
		ghutToPol();
		fetchData2();

	}, [_connected, walletAddress, _GhutToPol]);

	const { refetch: getRate } = useContractRead({
		...contract,
		functionName: 'POLToGHUT', args: [],
	})

	const { refetch: getHardCapInPol } = useContractRead({
		...contract,
		functionName: 'getHardCapInPol', args: [],
	})

	const { refetch: getSoftCap } = useContractRead({
		...contract,
		functionName: 'getSoftCapInPol', args: [],
	})

	const { refetch: getMinAmount } = useContractRead({
		...contract,
		functionName: 'minAmount', args: [],
	})


	const { refetch: getMaxAmount } = useContractRead({
		...contract,
		functionName: 'maxAmount', args: [],
	})

	/*const downloadPDF = (index) => {
		const element = contentRefs.current[index];  // Get the specific content for the selected index
		html2pdf()
			.from(element)
			.save(`GuardHawk_SAFT_${index}.pdf`);  // Save as a unique PDF for each index
	};*/

	const downloadPDF = (index) => {
		const element = contentRefs.current[index];

		setTimeout(() => {
			const doc = new jsPDF('p', 'mm', 'a4');
			const pageHeight = 280; // A4 page height in mm
			const pageWidth = 210; // A4 page width in mm
			let y = 20;

			// Select all the text elements
			const textElements = element.querySelectorAll('h, h2, h4, p, li, span');

			textElements.forEach((element) => {
				let text = element.textContent;

				if (element.tagName === 'H2') {
					doc.setFont('Helvetica', 'bold'); // Set font style to bold for h2 elements
					doc.setFontSize(20); // Set font size to 24px for h2 elements
					doc.setTextColor(0, 0, 0); // Set text color to red for h2 elements
					doc.setLineHeightFactor(1.5); // Set line height factor to 1.5
				} else if (element.tagName === 'P') {
					doc.setFont('Helvetica', 'normal'); // Set font style to regular for p elements
					doc.setFontSize(12); // Set font size to 8px for p elements
					doc.setTextColor((0, 0, 0, 0.700)); // Set text color to black for p elements
					doc.setLineHeightFactor(1.2); // Set line height factor to 1.2
				} else if (element.tagName === 'H4') {
					doc.setFont('Helvetica', 'bold'); // Set font style to bold for h4 elements
					doc.setFontSize(15); // Set font size to 8px for p elements
					doc.setTextColor(0, 0, 0); // Set text color to black for p elements
					doc.setLineHeightFactor(1.0); // Set line height factor to 1.2
				} else if (element.tagName === 'H') {
					doc.setFont('Helvetica', 'bold'); // Set font style to bold for h4 elements
					doc.setFontSize(22); // Set font size to 8px for p elements
					doc.setTextColor(0, 0, 0); // Set text color to black for p elements
					doc.setLineHeightFactor(1.0); // Set line height factor to 1.2
				} else if (element.tagName === 'LI') {
					doc.setFont('Helvetica', 'normal'); // Set font style to regular for li elements
					doc.setFontSize(11); // Set font size to 12px for li elements
					doc.setTextColor(0, 0, 0); // Set text color to black for li elements
					doc.setLineHeightFactor(1.2); // Set line height factor to 1.2
					text = '\u2022 ' + text; // Add a bullet point to the text
				} else if (element.tagName === 'SPAN' && element.parentElement.classList.contains('txtPDF')) {
					doc.setFont('Helvetica', 'bold'); // Set font style to bold for span elements
					doc.setFontSize(12); // Set font size to 12px for span elements
					doc.setTextColor(0, 0, 0); // Set text color to black for span elements
					doc.setLineHeightFactor(1.2); // Set line height factor to 1.2
				} else {
					doc.setFont('Helvetica', 'normal'); // Set font style to regular for other elements
					doc.setFontSize(12); // Set font size to 12px for other elements
					doc.setTextColor(0, 0, 0); // Set text color to black for other elements
					doc.setLineHeightFactor(1.2); // Set line height factor to 1.2
				}

				// Split the text into lines based on the available width
				let lines = doc.getTextDimensions(text).w / (pageWidth - 30);
				let maxLines = Math.ceil(lines);

				// Wrap the text to the specified number of lines
				doc.text(text, 15, y, { maxWidth: pageWidth - 30, maxLines: maxLines });

				// Update the y position based on the number of lines
				y += (maxLines * 5);

				// Add a small gap between headings
				if (element.tagName === 'H2' || element.tagName === 'H4') {
					y += 5; // Add a 5mm gap
				}

				// Add a small gap between list items
				if (element.tagName === 'LI') {
					y += 2; // Add a 2mm gap
				}

				// Add a small gap between paragraphs
				if (element.tagName === 'P') {
					y += 5; // Add a 2mm gap
				}

				// Check if the content exceeds the page height
				if (y > pageHeight - 20) {
					doc.addPage();
					y = 20;
				}
			});

			// Save the PDF
			doc.save(`saft-${index}.pdf`);
		}, 1000);
	};

	const openPopup = (index) => {
		setPopupIndex(index);  // Open the corresponding popup
	};

	const closePopup = () => {
		setPopupIndex(null);  // Close popup
	};

	function shortenAddress(walletAddress) {
		try {
			return _connected
				? walletAddress.slice(0, 3) + "..." + walletAddress.slice(-4)
				: "Connect";
		} catch (error) {
			console.log(error);
		}
	}

	async function disconnectWallet() {
		setConnected(false);
		disconnect();
		window.location.reload(true);
		window.location.href = '/'
	}

	const { writeAsync } = useContractWrite({
		onError(error) {
			if (error.message.includes("balance")) {
				setstatusError(true);
				setstatusLoading(false);
			}
		},
	});

	async function downloadPDFWindow() {
		setDownloadPDF(1);
	}

	async function closeWindow() {
		setDownloadPDF(0);
	}

	const getRelativeTime = (date) => {
		const now = new Date();
		const past = new Date(date);
		const diffInSeconds = Math.floor((now - past) / 1000);

		const minutes = Math.floor(diffInSeconds / 60);
		const hours = Math.floor(minutes / 60);
		const days = Math.floor(hours / 24);

		if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`;
		if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
		if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
		return `${diffInSeconds} second${diffInSeconds > 1 ? 's' : ''} ago`;
	};

	return (
		<div>
			<div className='allWrap'>
				<div class="light">
					{_connected ? (
						<div className="connectT" onClick={() => disconnectWallet()}>
							{walletAddress === "" ? "Connect Web3 Wallet" : shortenAddress(walletAddress)}
						</div>
					) : (
						<div className="connectT" onClick={() => { open(); }}>Connect Web3 Wallet</div>
					)}

					<div className='onlyMob'>
						<HeaderMobile />
					</div>

					<div className='page1Main'>
						<Header />

						<div className='rightSection'>
							<div className='mainT'>MIS INVERSIONES</div>
							{_connected ?
								<div className='flexBox3T'>HOLA, {sessionStorage.getItem('displayName')}!</div> :
								<div className='flexBox3T'>HOLA</div>
							}
							<div className='flexBox4T'>BIENVENIDO A TU ESPACIO DE INVERSIÓN</div>

							<div class="transaction-list-main">
								<div className='listT'>ÚLTIMAS TRANSACCIONES</div>
								<div className='line3'></div>

								{_responseData.slice().reverse().map((responseData, index) => (
									<div key={index}>

										<div class="transaction-list">

											<div className='list1'>
												<img src={doc} />
												<div className='listConMain'>
													<div className='list1Con'>Detalles_del_contrato_de_transacción {_responseData.length - index}</div>
													<div className='list1Con2'>{getRelativeTime(responseData.createdDate)}</div>
												</div>
											</div>

											{/*<div className='listConMain'>
												<div className='list1Con3'>Desde:</div>
												<div className='list1Con3'>Hacia:</div>
											</div>*/}

											<div className='listConMain'><button className='listBtn'>$$$$</button></div>

											<div className="listConMain2">
												<div className="listConMain2-2">Descargar</div>
												<div className="listConMain3">
													<div>SAFT</div>
													<img src={download} onClick={() => openPopup(index)} />
												</div>
											</div>

										</div>

										{popupIndex === index && (
											<div id="popupContainerPDF" class="popupPDF">
												<div class="close" onClick={closePopup}>✖</div>
												<div class="popup-contentPDF" >
													<div ref={el => contentRefs.current[index] = el}>

														<p className='centerAligned'><h2 id="centerAligned" className='headerPDF'>Simple Agreement For Future Token Guard Hawk</h2>
															<h2 className='headerPDF' id="centerAligned">(SAFT)</h2>
															<h2 className='headerPDF' id="centerAligned">Ronda Inversores Ángeles</h2></p>
														<p>&nbsp;</p>


														<h2 className='headerPDF'>INTRODUCCIÓN</h2>

														<p className='txtPDF'>El presente acuerdo contiene información sobre la ronda de inversión para Capitales Ángeles del token de utilidad Guard Hawk (GHUT), en particular sobre las condiciones y restricciones aplicables a cualquier persona que decida invertir en la primera ronda de Guard Hawk.</p>
														<p className='txtPDF'>Es del caso indicar que se ha concedido un plazo de seis meses a partir del lanzamiento para recaudar el capital suficiente para desarrollar el proyecto.</p>

														<p>&nbsp;</p>
														<h2 className='headerPDF'>DEFINICIONES</h2>
														<p className='txtPDF'>&ldquo;<strong><bold><span>SAFT</span></bold></strong>&rdquo; el acuerdo simple para tokens futuros es un instrumento que otorga derechos futuros a un Inversor para recibir una cantidad de Tokens en relación con su inversión.</p>
														<p className='txtPDF'>&ldquo;<strong><bold><span>Empresa</span></bold></strong>&rdquo; es la entidad jurídica “Guard Hawk”.</p>
														<p className='txtPDF'>&ldquo;<strong><span>Inversor</span></strong>&rdquo; es la entidad jurídica, inversionista, que puede ser una empresa o un individuo, que proporciona fondos a cambio de futuros Tokens.</p>
														<p className='txtPDF'>&ldquo;<strong><span>Token de utilidad (GHUT)</span>&rdquo;</strong> tipo de activo que proporciona a un usuario un producto o un servicio.</p>
														<p className='txtPDF'>&ldquo;<strong><span>Token de Gobernanza (GHGO)</span>&rdquo;</strong> tipo de activo digital que otorga a sus titulares el derecho a participar en el proceso de toma de una organización o protocolo descentralizado.</p>
														<p className='txtPDF'>&ldquo;<strong><span>NFT (Token No Fungible)</span>&rdquo;</strong> un activo digital único que representa objetos o conceptos en el mundo digital, como arte, música, juegos y otros coleccionables. En el contexto de Guard Hawk, un NFT coleccionable es un arte digital que representa el logo de Guard Hawk, otorgando a su propietario una pieza única de coleccionismo digital vinculada a la marca.</p>
														<p className='txtPDF'>&ldquo;<strong><span>Evento de disolución</span></strong>&rdquo; significa (a) una terminación voluntaria de las operaciones por parte de la Compañía a su entera discreción; (b) una cesión general en beneficio de los acreedores de la Sociedad; o (c) cualquier otro evento de liquidación, disolución o liquidación de la Compañía, excluyendo el cambio de propiedad y control de la Compañía, así como la oferta pública inicial.</p>
														<p className='txtPDF'>&ldquo;<strong><span>Acuerdo posterior</span></strong>&rdquo; significa cualquier SAFT liberado por la Compañía después de este SAFT para las futuras rondas de venta del Token de acuerdo con los principios y con el fin de recaudar capital. Esta definición excluye: (a) las emisiones de tokens en el ámbito del plan de distribución interno de la Compañía, como los incentivos del equipo de desarrollo y otros eventos relevantes (b) los tokens emitidos a proveedores de servicios externos u otras personas conectadas y asociadas con el lanzamiento de la Red o, de manera similar, la provisión de bienes y servicios a la Compañía; (c) Tokens emitidos de acuerdo con la investigación, la colaboración, la concesión de licencias, el desarrollo, las asociaciones y otros acuerdos aplicables patrocinados; (d) cualquier valor convertible emitido por la Compañía; y (e) Tokens emitidos en conexión con actividades mineras de la Red y obsequios iniciados por la Compañía.</p>
														<p>&nbsp;</p>
														<h2 className='headerPDF'>ESTRUCTURA DEL NEGOCIO</h2>
														<ul>
															<li>Se emitirán un total de 20.000.000.000 GHUT. </li>
															<li>Se emitirán 13.000.000 de GHUT para la ronda de capitales ángeles. De un total e 100.000.000 de GHUT que fueron asignados en los Tokenomics.</li>
															<li>El monto total a recaudar en la ronda de capitales ángeles fluctúa entre 600.000 € y 650.000 €, equivalentes en POL.</li>
															<li>El valor del GHUT para inversionistas de capitales ángeles es de 0,05 €, equivalente a POL según el valor al día y hora de la inversión.</li>
															<li>La inversión de esta ronda de capitales ángeles podrá ser de un mínimo de 100 € y un máximo de 50.000 €, por cada billetera digital, equivalentes en POL al día y hora de la inversión. Sin importar el número de transacciones, cumpliendo con los rangos  indicados.</li>
														</ul>
														<p>&nbsp;</p>
														<h2 className='headerPDF'>INVERSIÓN</h2>
														<ul>
															<li>La Billetera Nº {(responseData.ethAddress)} compra {responseData.ghutAmount} GHUT.</li>
															<li>El valor total de la compra es de {responseData.ethAmount} POL.</li>

														</ul>
														<p className='txtPDF'>
															<div className='txtPDF'>DURACION: </div>
															<div className='txtPDF'>La ronda de inversión para Capitales Ángeles se inicia el día 1 de octubre de  de 2024 y finaliza el 1 de abril de 2025 a las 00:00 horas GMT 0.</div></p>
														<p className='txtPDF'>
															El 15% del total de los tokens GHUT está destinado a los inversionistas. Este porcentaje se distribuirá en tres rondas de financiamiento: un 0.5% para los inversores ángeles, un 6% en la Primera Ronda de Financiamiento, un 3.5% en la Segunda Ronda y un 5% a los inversores de los Launchpads.
														</p>
														<p className='txtPDF'>El valor inicial del GHUT será de 0,1 €, equivalente al valor en POL según el valor al día y hora de emisión. Este valor refleja nuestros costos operativos y la demanda prevista, proporcionando un punto de partida claro y justificado. A partir de esa fecha, el precio del GHUT será determinado por el mercado, ajustándose según la oferta y la demanda a lo largo del tiempo.</p>
														<p className='txtPDF'>Estos términos certifican que, a cambio del pago por parte del inversor, la empresa emite al inversor el derecho al importe de la compra de GHUT tokens sujeto a los términos establecidos en este documento.</p>
														<p>&nbsp;</p>
														<h2 className='headerPDF'>BENEFICIOS</h2>
														<p className='txtPDF'>Los inversores de la primera ronda de financiamiento del proyecto Guard Hawk recibirán una serie de beneficios exclusivos, que incluyen:</p>
														<ul>
															<li>GHUT (token de utilidad Guard Hawk) según cantidad invertida.</li>
															<li>Tokens GHGO (Guard Hawk Governance): La distribución de tokens GHGO será proporcional a la inversión. El 30% de los tokens GHGO estará destinado a los inversores, quienes recibirán una cantidad de tokens acorde a su participación en cada ronda de financiación. Esto les permitirá influir en las decisiones del proyecto de acuerdo con su contribución financiera.</li>
															<li>1 NFT (token no fungible) exclusivo coleccionable para inversionistas ángeles.</li>
														</ul>
														<p>&nbsp;</p>
														<p>&nbsp;</p>

														<h2 className='headerPDF'>CONDICIONES DE VENTA</h2>
														<h4 className='txtPDF'>Reserva de derechos</h4>
														<p className='txtPDF'>La Compañía se reserva el derecho de ofrecer y vender el Token en diferentes términos y en múltiples rondas. Los términos aplicables a cada ronda posterior a esta ronda pueden modificarse siempre que no se exceda el suministro total de tokens.</p>
														<h4 className='txtPDF'>Entrega de la Inversión</h4>
														<p className='txtPDF'>Al celebrar el acuerdo con la Compañía de conformidad con las disposiciones del presente documento, el Inversor acepta y se compromete a transferir, el Monto de Compra correspondiente al monto del valor del Token y de conformidad con estos términos. Al aceptar el presente documento, el Inversor acepta voluntariamente estar obligado contractualmente por sus términos.</p>
														<p></p>
														<h4 className='txtPDF'>Condiciones de entrega de tokens GHUT, GHGO y NFT</h4>
														<p className='txtPDF'>
															La cantidad correspondiente de token GHUT, token GHGO y NFT se entregará al Inversor en su billetera digital de acuerdo con los detalles
															proporcionados por el Inversor en el presente documento. El Token se entregará a la Billetera bajo el control directo o indirecto del Inversor que no podrá delegarse a ningún tercero autorizado o no autorizado, incluidos los representantes del Inversor. Los Tokens y NFT se entregarán a la dirección de la Billetera indicada por el Inversor en un plazo máximo de 7 días corridos, desde transcurrido el plazo de 6 meses establecido para la captación de capital del proyecto, siempre y cuando se cumpla la meta establecida de captación de capital, ya que de lo contrario el monto transferido por parte del inversionista será devuelto a su billetera, de acuerdo al Smart Contract {responseData.ethContract}, link <a href={`https://polygonscan.com/address/${responseData.ethContract}`} target="_blank" rel="noopener noreferrer">
															 https://polygonscan.com/address/{responseData.ethContract}.
															</a> 

															</p>
														<h4 className='txtPDF'>Rescisión</h4>
														<p className='txtPDF'>Este SAFT y sus disposiciones se considerarán terminados siempre y cuando:</p>
														<ul>
															<li>La cantidad aplicable de Token será entregada al Inversor en el plazo de 7 días corridos, desde que se ha finalizado el periodo de 6 meses de captación de capital, teniendo un resultado positivo, es decir, se haya captado el capital necesario; o</li>
															<li>La devolución de la Inversión realizada al inversionista, de acuerdo establece el contrato inteligente, en caso que una vez transcurrido el plazo de los 6 meses para la captación de capital, no se haya conseguido el monto necesario.</li>
														</ul>
														<p className='txtPDF'>*Los costos asociados a las transacciones de la red serán de cargo exclusivo del inversionista.</p>
														<p>&nbsp;</p>
														<h2 className='headerPDF'>CONDICIONES DE BLOQUEO</h2>
														<p className='txtPDF'>La estructura de bloqueo (vesting) y liberación de GHUT para la primera ronda de financiación está diseñada para alinear sus intereses con el crecimiento y la estabilidad a largo plazo de Guard Hawk. La siguiente estructura refleja un enfoque justo y equilibrado:</p>
														<h4 className='txtPDF'>Periodo de Bloqueo (Cliff)</h4>
														<p className='txtPDF'>Bloqueo de Inversiones para Capitales Ángeles: El 100% de los tokens GHUT emitidos en esta ronda de financiación, que representa el 0,5% del total de los tokens emitidos, estarán bloqueados durante 1 año a partir del día del lanzamiento del token. Después de este período, se liberará el 10% de los tokens en el primer mes, y el 90% restante se liberará de manera proporcional durante los siguientes 18 meses.</p>
														<p>&nbsp;</p>
														<h2 className='headerPDF'>CLÁUSULAS DE PROTECCIÓN</h2>

														<ul>
															<li><strong><bold><span>Cláusula de reactivación del bloqueo: </span></bold></strong>
																Cuando un incidente importante, una anomalía o una fuerza mayor afecte directa o indirectamente a la viabilidad futura del proyecto o a los intereses de los inversores, el comité podrá, en caso de informe de riesgo desfavorable, reactivar el bloqueo del token durante un máximo de 7 días consecutivos. Si el comité decide que el citado plazo es tiempo insuficiente para subsanar el incidente o fuerza mayor, deberá someter a Gobernabilidad la decisión sobre el mantenimiento del bloqueo durante un periodo más largo, basándose en todo momento en los informes de riesgos aplicables. Este mecanismo garantiza que cualquier acción tomada se alinee con los intereses de la comunidad y los titulares de tokens, proporcionando una capa adicional de seguridad y transparencia. La gobernanza desempeñará un papel crucial en la evaluación de la situación y en la toma de decisiones informadas, teniendo en cuenta en todo momento los mejores intereses del proyecto.
															</li>
															<p></p>
															<li><strong><bold><span>Cláusula de congelación de billeteras criptográficas (lista negra): </span></bold></strong>
																A solicitud de los reguladores y/o tribunales competentes, las billeteras que registren actividades fraudulentas, o que no cumplan con los requisitos regulatorios y los estándares mínimos de seguridad, pueden ser congeladas. Esto es importante para la tranquilidad de los inversores, ya que les impide invertir en el mismo proyecto que inversores poco fiables. Estas listas están diseñadas para prevenir fraudes y estafas.</li>
														</ul>
														<p>&nbsp;</p>
														<h2 className='headerPDF'>TRANSPARENCIA Y COMUNICACIÓN</h2>
														<ul>
															<li><strong><bold><span>Anuncios claros: </span></bold></strong>Todos los términos de bloqueo y liberación se anunciarán claramente antes de que los inversores comprometan su capital.</li>
															<li><strong><bold><span>Comunicación continua: </span></bold></strong>
																Mantendremos abierto los canales de comunicación que sean indicados en nuestra página web, tales como Discord, Telegram, etc. De esa manera los inversores, recojan sus opiniones y presenten propuestas a Gobernanza para que las políticas puedan ajustarse según sea necesario en beneficio del proyecto y de la comunidad.</li>
														</ul>
														<p>&nbsp;</p>
														<h2 className='headerPDF'>GARANTÍA</h2>
														<div className='txtPDF'>
															La propuesta de financiación de Guard Hawk destaca por la seguridad que ofrece a los inversores. Guard Hawk garantiza el reembolso automático de las inversiones si no se cumplen los objetivos financieros en el plazo establecido, mediante la aplicación del Smart Contract código {responseData.ethContract},</div> <div className='txtPDF'>disponible en el link <a href={`https://polygonscan.com/address/${responseData.ethContract}`} target="_blank" rel="noopener noreferrer">
															https://polygonscan.com/address/{responseData.ethContract}.</a> En caso de proceder la devolución, ésta se efectuará en POL, siendo los costos de transacción de cargo del inversor.
														</div>
														<p className='txtPDF'>Esta medida reduce significativamente el riesgo para los inversores, aumentando su confianza en la gestión responsable y viabilidad del proyecto.</p>
														<p className='txtPDF'>El proyecto será auditado por una firma internacional durante la etapa de desarrollo para garantizar que se cumplan los hitos de desarrollo y los gastos se mantengan transparentes, con los resultados publicados en nuestro sitio web. Esta transparencia adicional es crucial para mantener la confianza de los inversores.</p>
														<ul>
															<p className='txtPDF'>-	Es importante destacar que cerraremos la captación de inversores iniciales al alcanzar nuestro objetivo de financiación, dentro de un plazo de 6 meses. El cierre se realizara el día 1 abril de 2025 00:00 horas GMT 0, con un límite máximo fijado entre 600.000 € y 650.000 €, equivalentes en POL a la fecha de cierre.
															</p>
														</ul>
														<p className='txtPDF'>Además, dentro de las proyecciones tokenómicas futuras se estipula una distribución continua para liquidez del mercado a través de diferentes exchanges -centralizados y descentralizados- de tal forma de permitir movimientos libres de entrada y salida al ecosistema.
														</p>
														<p>&nbsp;</p>
														<h2 className='headerPDF'>DECLARACIONES DE LA EMPRESA</h2>
														<p className='txtPDF'>La Compañía es una empresa debidamente constituida, válidamente existente y en buen estado bajo las leyes del estado de su incorporación.</p>
														<p className='txtPDF'>
															La Compañía afirma y reconoce que, no ha estado ni está en ninguna violación de sus artículos de incorporación o estatutos actuales, ni ha estado o está actualmente sujeta a ningún litigio, investigación o procedimiento legal en curso o pendiente; tampoco ha sido o está actualmente en violación de ninguna ley o reglamento que pueda resultar potencial y razonablemente en un daño material a la Compañía o sus activos; Tampoco ha estado o está actualmente involucrada en ningún acto relacionado con el blanqueo de capitales, la financiación del terrorismo, el fraude u otros delitos financieros.
														</p>
														<p>&nbsp;</p>
														<h2 className='headerPDF'>DECLARACIONES INVERSIONISTA</h2>
														<p className='txtPDF'>Como inversionista, usted declara que:</p>
														<ul>
															<li>Ha leído y aceptado el presente acuerdo, que es parte integral y vinculante entre las partes, antes de invertir en el proyecto Guard Hawk.</li>
															<li>Ha leído y aceptar los términos y condiciones de la plataforma de Guard Hawk, Aviso legal web y su política de privacidad.</li>
															<li>Los fondos que proporcionas provienen de actividades legales, de acuerdo con la normativa de la Unión Europea.</li>
															<li>No permitirá que terceros realicen depósitos en sus cuentas con fondos procedentes de actividades ilegales, de acuerdo con la normativa de la Unión Europea; Tampoco participará en transacciones destinadas a dichas actividades o a favor de personas relacionadas con las mismas. </li>
															<li>Se compromete a rescindir cualquier acuerdo, negocio o contrato celebrado con Guard Hawk si incumple cualquiera de las disposiciones de este documento, eximiendo a Guard Hawk de cualquier responsabilidad resultante de la información errónea, falsa o inexacta que haya proporcionado, o de un incumplimiento de los términos y condiciones del presente documento.</li>
															<li>Usted autoriza a Guard Hawk a comprobar si ha sido sancionado por alguna autoridad europea o estadounidense, con el fin de verificar las declaraciones anteriores.</li>
															<li>Usted autoriza a Guard Hawk a contratar con terceros los servicios de verificación de identificación de identidad que serán utilizados al momento de registrarse como inversor.</li>
															<li>El Inversor ha alcanzado la mayoría de edad legal en su país de residencia y, por lo tanto, tiene capacidad legal para celebrar este SAFT y estar sujeto a sus términos</li>
															<li>La celebración de un acuerdo vinculante con la Sociedad y la obligación de cumplir los términos y disposiciones de este SAFT no contradice ni incumple ningún otro término contractual por el que el Inversor esté legalmente obligado al entrar en vigor este SAFT</li>
															<li>El Inversor no se encuentra y/o reside en ninguno de los lugares y jurisdicciones restringidas, incluidas las sujetas a regulaciones prohibitivas, sanciones financieras internacionales y medidas de restricción económica.</li>
														</ul>
														<p className='txtPDF'>En un caso en el que el Inversor sea la persona jurídica, el Inversor reconoce y garantiza que:</p>
														<ul>
															<li>La organización se constituye con toda la debida diligencia de la formación e incorporación de la empresa en el país de su ubicación legal, a organización lleva a cabo sus actividades de acuerdo con las leyes y reglamentos aplicables.</li>
															<li>El representante de la empresa que actúa como signatario de este SAFT ha sido legalmente autorizado para representar a la organización y celebrar acuerdos legalmente vinculantes en su nombre.</li>
															<li>La organización no representa ni lleva a cabo sus actividades comerciales en campos relacionados con formas ilegales de actividad comercial, infracción de derechos de propiedad intelectual, bienes y servicios regulados y con licencia, servicios y esquemas financieros fraudulentos y restringidos, así como otros bienes y servicios injustos, engañosos, agresivos y no regulados de acuerdo con las leyes pertinentes.</li>
														</ul>
														<p>&nbsp;</p>
														<h2 className='headerPDF'>CONFIDENCIALIDAD</h2>
														<p className='txtPDF'>Cualquier información confidencial que haya sido o vaya a ser revelada al inversor durante el periodo de captación de capital, y todos los derechos sobre dicha información, seguirán siendo propiedad de Guard Hawk.</p>
														<p className='txtPDF'>Los inversores no obtendrán ningún derecho de ningún tipo sobre la información, ni ningún derecho de uso de la información, excepto para los fines de este acuerdo. La divulgación de información confidencial no implica la concesión de licencias de derechos de patente, derechos de autor o cualquier otro derecho por parte de Guard Hawk, distintos de los establecidos en este documento.</p>
														<p className='txtPDF'>Los inversores se comprometen a mantener la información confidencial (es decir, cualquier información relacionada con la estrategia comercial, financiera y de marketing) en estricta confidencialidad y a no revelar ninguna información a ninguna otra parte, relacionada o de otro tipo, sin el consentimiento previo por escrito de Guard Hawk. </p>
														<p className='txtPDF'>Si el inversionista es una persona jurídica, su representante deberá informar a cualquier persona responsable de recibir información confidencial -y deberá firmar el acuerdo de confidencialidad si fuera necesario- de su obligación de recibir, procesar y utilizar cualquier información confidencial que reciba como confidencial y destinada únicamente a los fines del acuerdo, en los términos aquí establecidos. </p>
														<p className='txtPDF'>Los inversores deben tratar toda la información recibida directa o indirectamente de Guard Hawk de forma confidencial y no utilizar dicha información de ninguna otra manera que no sea para los fines de este acuerdo. Los inversores no deben manejar, usar, explotar o divulgar información confidencial a ninguna persona o entidad por ningún motivo en contravención de este instrumento, a menos que Guard Hawk lo autorice expresamente por escrito.</p>
														<p className='txtPDF'>La divulgación o el uso de la información por parte de un inversor en incumplimiento de este acuerdo se considerará motivo para pagar una indemnización por daños y perjuicios.</p>

														<p className='txtPDF'>Las obligaciones previstas en el presente documento no se aplicarán en los siguientes casos:</p>

														<ol>
															<p className='txtPDF'>i. A la información que es de dominio público o que en lo sucesivo pasa a ser de dominio público.</p>
															<p className='txtPDF'>ii. A la información que no haya sido obtenida de Guard Hawk o que deba ser divulgada por ley o por orden de una autoridad o tribunal. </p>
															<p className='txtPDF'>iii.	Si la información o cualquier parte de la misma es obtenida legalmente por el inversor de un tercero o terceros sin incumplir este acuerdo, cuando se pueda demostrar que el tercero es una fuente legal de información.</p>

															<p className='txtPDF'>
																iv.	Si la información o cualquier parte de la misma era conocida por el inversor antes de su divulgación por parte de Guard Hawk, siempre que el inversor pueda demostrar dicho conocimiento. Los inversores reconocen que las excepciones antes mencionadas no incluirán combinaciones de tipos de información simplemente porque cada uno de ellos sea de dominio público o esté en posesión del inversor. Los inversores serán responsables de demostrar sus derechos con respecto a cualquier excepción prevista en esta cláusula.
															</p>
														</ol>
														<p className='txtPDF'>Este acuerdo no constituye una garantía para el inversor por parte de Guard Hawk con respecto a cualquier violación relacionada con la información de patentes u otros derechos de terceros. Guard Hawk no será responsable de la duración de la divulgación de la información, de los errores u omisiones en la información, ni del uso y los resultados del uso de la información. Guard Hawk no será responsable de ninguna manera por cualquier pérdida de ningún tipo, incluidos, entre otros, daños y pérdidas, costos de intereses, pérdida de ganancias y cualquier otra pérdida o daño similar que resulte de cualquier error, inexactitud, omisión o cualquier otro defecto en la información.</p>
														<p>&nbsp;</p>
														<h2 className='headerPDF'>RESPONSABILIDAD</h2>
														<p className='txtPDF'>Bajo ninguna circunstancia Guard Hawk garantiza el éxito del proyecto. Sin embargo, el equipo de trabajo hará todo lo posible para desarrollar y llevar a cabo el proyecto tal y como se describe en el whitepaper. Tenga en cuenta que siempre existe la posibilidad de casos de fuerza mayor o contingencias que nos obliguen a cambiar o dar por terminado el proyecto. La inversión tiene riesgos inherentes, incluida la posibilidad de que Guard Hawk no pueda seguir la hoja de ruta establecida en su documento técnico. Antes de invertir en Guard Hawk, los inversionistas deben evaluar los riesgos asociados y, en su caso, buscar el asesoramiento de un experto externo. Los inversores desinformados que no acepten los riesgos financieros o de cumplimiento de este proyecto no deberían invertir en Guard Hawk. </p>
														<p>&nbsp;</p>
														<h2 className='headerPDF'>RECEPCIÓN DE NOTIFICACIONES</h2>
														<p className='txtPDF'>La recepción de cualquier notificación relacionada con el objeto de este acuerdo se iniciará únicamente por escrito desde la dirección de correo electrónico correspondiente de la respectiva Parte indicada en este acuerdo al correo electrónico de la otra Parte, en las mismas condiciones.</p>
														<p>&nbsp;</p>
														<h2 className='headerPDF'>LIMITACIÓN DE LA REPRESENTACIÓN</h2>
														<p className='txtPDF'>El Inversor no tendrá derecho a ningún derecho de representación de la Sociedad, ni a ningún derecho de voto y gestión en la Sociedad. Del mismo modo, el Inversor no tendrá derecho a ningún puesto en el consejo de administración de la Empresa ni a la adquisición o transferencia de ningún título de propiedad o participación en los activos de la Empresa sobre la base de su tenencia del Token. Además, el Inversor no tendrá derecho a votar para la elección o destitución de ningún miembro del consejo de administración de la Sociedad, ni a recibir notificación de reuniones ni a recibir los derechos correspondientes de las mismas.</p>
														<p>&nbsp;</p>
														<h2 className='headerPDF'>DIVISIBILIDAD</h2>
														<p className='txtPDF'>En el caso de que una o más de las disposiciones de este instrumento se consideren inválidas, ilegales o inaplicables, en su totalidad o en parte o en cualquier aspecto, o en el caso de que una o más de las disposiciones de este instrumento operen o puedan operar prospectivamente para invalidar este instrumento,  En ese caso, y en cualquier caso, solo dicha(s) disposición(es) se considerará(n) nula(s) y sin efecto y no afectará a ninguna otra disposición de este instrumento y las disposiciones restantes de este instrumento permanecerán operativas y en pleno vigor y efecto y no se verán afectadas, perjudicadas o perturbadas por ello.</p>
														<p>&nbsp;</p>
														<h2 className='headerPDF'>NO ASESORAMIENTO FINANCIERO</h2>
														<p className='txtPDF'>El Inversor garantiza que el presente acuerdo, y cualquier información entregada por Guard Hawk no se percibirán como asesoramiento de inversión, financiero, regulatorio, fiscal o legal y que el Inversor ha buscado dicho asesoramiento a su propia discreción e independientemente de los profesionales con cualificaciones y/o licencias pertinentes. Cualquier decisión con respecto a la conclusión del Inversor respecto a la compra de GHUT que sea tomada con o sin la ayuda del asesor pertinente será de su propia responsabilidad.</p>
														<p>&nbsp;</p>
														<h2 className='headerPDF'>CONOCIMIENTO DEL INVERSOR</h2>
														<p className='txtPDF'>El Inversor reconoce y garantiza que ha obtenido y posee suficiente conocimiento en asuntos financieros y comerciales y que es capaz de evaluar los riesgos de beneficios asociados con la compra, venta y negociación de monedas virtuales, incluidos los tokens. El Inversor reconoce además el riesgo de la inversión en monedas virtuales y que ha evaluado su situación financiera y circunstancial y ha considerado si la compra del Token es adecuada para él. El Inversor acepta que es consciente de las posibilidades de pérdida total e irreversible de sus activos financieros y que recuperarse de dicha pérdida puede ser difícil o imposible.</p>
														<p>&nbsp;</p>
														<h2 className='headerPDF'>TERCEROS</h2>
														<p className='txtPDF'>El Inversor reafirma que está comprando el Token por su propia cuenta para inversión, no como nominado o agente, y no con miras a, o para la reventa en relación con, la distribución del mismo, y el Inversor no tiene intención actual de vender, otorgar ninguna participación o distribuir el mismo.</p>
														<p>&nbsp;</p>
														<p>&nbsp;</p>
														<p className='txtPDF'>_______________________</p>
														<p className='txtPDF'>GERMAN MUÑOZ MENESES</p>
														<p className='txtPDF'>CEO</p>
														<p className='txtPDF'>GUARD HAWK LDA</p>
														<p>&nbsp;</p>
														<p className='txtPDF'>_______________________</p>
														<p className='txtPDF'>La Billetera Nº {(responseData.ethAddress)}</p>

													</div>
													<button onClick={() => downloadPDF(index)} className="downloadSaft">DOWNLOAD SAFT PDF</button>

												</div>
											</div>
										)}
									</div>
								))}

								{_responseData.length === 0 && <p className='notxns'>No transactions yet</p>}

								<div className='listNote'>VER TODAS LAS TRANSACCIONES</div>
							</div>
							<div className='onlyMob'>
								<div class="transaction-list-main-mob">
									<div className='listT'>ÚLTIMAS TRANSACCIONES</div>
									<div className='line3'></div>
									{_responseData.slice().reverse().map((responseData, index) => (
										<div key={index}>
											<div class="transaction-list">
												<div className='flexDoc1'>
													<div className='list1'>
														<img src={doc} />
														<div className='listConMain'>
															<div className='list1Con'>nombre del documento {_responseData.length - index}</div>
															<div className='list1Con2'>{getRelativeTime(responseData.createdDate)}</div>
														</div>
													</div>

													{/*<div className='listConMain'>
														<div className='list1Con3'>Desde:</div>
														<div className='list1Con3'>Hacia:</div>
													</div>*/}
												</div>

												<div className='flexDoc2'>
													<button className='listBtn'>$$$$</button>

													<div className="listConMain2">
														<div className="listConMain2-2">Descargar</div>
														<div className="listConMain3">
															<div>SAFT</div>
															<img src={download} onClick={() => openPopup(index)} />
														</div>
													</div>
												</div>
											</div>

											{popupIndex === index && (
												<div id="popupContainerPDF" class="popupPDF">
													<div class="close" onClick={closePopup}>✖</div>
													<div class="popup-contentPDF" >
														<div ref={el => contentRefs.current[index] = el}>
															<h className='headerPDF'>Simple Agreement For Future Token Guard Hawk - (SAFT)</h>
															<p></p>
															<h className='headerPDF'>Ronda Inversores Ángeles</h>
															<p>&nbsp;</p>

															<p>&nbsp;</p>


															<h2 className='headerPDF'>INTRODUCCIÓN</h2>

															<p className='txtPDF'>El presente acuerdo contiene información sobre la ronda de inversión para Capitales Ángeles del token de utilidad Guard Hawk (GHUT), en particular sobre las condiciones y restricciones aplicables a cualquier persona que decida invertir en la primera ronda de Guard Hawk.</p>
															<p className='txtPDF'>Es del caso indicar que se ha concedido un plazo de seis meses a partir del lanzamiento para recaudar el capital suficiente para desarrollar el proyecto.</p>

															<p>&nbsp;</p>
															<h2 className='headerPDF'>DEFINICIONES</h2>
															<p className='txtPDF'>&ldquo;<strong>SAFT</strong>&rdquo; el acuerdo simple para tokens futuros es un instrumento que otorga derechos futuros a un Inversor para recibir una cantidad de Tokens en relación con su inversión.</p>
															<p className='txtPDF'>&ldquo;<strong>Empresa</strong>&rdquo; es la entidad jurídica “Guard Hawk”.</p>
															<p className='txtPDF'>&ldquo;<strong>Inversor</strong>&rdquo; es la entidad jurídica, inversionista, que puede ser una empresa o un individuo, que proporciona fondos a cambio de futuros Tokens.</p>
															<p className='txtPDF'>&ldquo;<strong>Token de utilidad (GHUT)&rdquo;</strong> tipo de activo que proporciona a un usuario un producto o un servicio.</p>
															<p className='txtPDF'>&ldquo;<strong>Token de Gobernanza (GHGO)&rdquo;</strong> tipo de activo digital que otorga a sus titulares el derecho a participar en el proceso de toma de una organización o protocolo descentralizado.</p>
															<p className='txtPDF'>&ldquo;<strong>NFT (Token No Fungible)&rdquo;</strong> un activo digital único que representa objetos o conceptos en el mundo digital, como arte, música, juegos y otros coleccionables. En el contexto de Guard Hawk, un NFT coleccionable es un arte digital que representa el logo de Guard Hawk, otorgando a su propietario una pieza única de coleccionismo digital vinculada a la marca.</p>
															<p className='txtPDF'>&ldquo;<strong>Evento de disolución</strong>&rdquo; significa (a) una terminación voluntaria de las operaciones por parte de la Compañía a su entera discreción; (b) una cesión general en beneficio de los acreedores de la Sociedad; o (c) cualquier otro evento de liquidación, disolución o liquidación de la Compañía, excluyendo el cambio de propiedad y control de la Compañía, así como la oferta pública inicial.</p>
															<p className='txtPDF'>&ldquo;<strong>Acuerdo posterior</strong>&rdquo; significa cualquier SAFT liberado por la Compañía después de este SAFT para las futuras rondas de venta del Token de acuerdo con los principios y con el fin de recaudar capital. Esta definición excluye: (a) las emisiones de tokens en el ámbito del plan de distribución interno de la Compañía, como los incentivos del equipo de desarrollo y otros eventos relevantes (b) los tokens emitidos a proveedores de servicios externos u otras personas conectadas y asociadas con el lanzamiento de la Red o, de manera similar, la provisión de bienes y servicios a la Compañía; (c) Tokens emitidos de acuerdo con la investigación, la colaboración, la concesión de licencias, el desarrollo, las asociaciones y otros acuerdos aplicables patrocinados; (d) cualquier valor convertible emitido por la Compañía; y (e) Tokens emitidos en conexión con actividades mineras de la Red y obsequios iniciados por la Compañía.</p>
															<p>&nbsp;</p>

															<p>&nbsp;</p>
															<h2 className='headerPDF'>ESTRUCTURA DEL NEGOCIO</h2>
															<ul>
																<li>Se emitirán un total de 20.000.000.000 GHUT. </li>
																<li>Se emitirán 13.000.000 de GHUT para la ronda de capitales ángeles. De un total e 100.000.000 de GHUT que fueron asignados en los Tokenomics.</li>
																<li>El monto total a recaudar en la ronda de capitales ángeles fluctúa entre 600.000 € y 650.000 €, equivalentes en POL.</li>
																<li>El valor del GHUT para inversionistas de capitales ángeles es de 0,05 €, equivalente a POL según el valor al día y hora de la inversión.</li>
																<li>La inversión de esta ronda de capitales ángeles podrá ser de un mínimo de 100 € y un máximo de 50.000 €, por cada billetera digital, equivalentes en POL al día y hora de la inversión. Sin importar el número de transacciones, cumpliendo con los rangos  indicados.</li>
															</ul>
															<p>&nbsp;</p>
															<h2 className='headerPDF'>INVERSIÓN</h2>
															<ul>
																<li>La Billetera Nº {(responseData.ethAddress)} compra {responseData.ghutAmount} GHUT.</li>
																<li>El valor total de la compra es de {responseData.ethAmount} POL.</li>

															</ul>
															<p className='txtPDF'>
																<div className='txtPDF'>DURACION:</div>
																<div className='txtPDF'>La ronda de inversión para Capitales Ángeles se inicia el día 1 de octubre de  de 2024 y finaliza el 1 de abril de 2025 a las 00:00 horas GMT 0.</div></p>
															<p className='txtPDF'>
																El 15% del total de los tokens GHUT está destinado a los inversionistas. Este porcentaje se distribuirá en tres rondas de financiamiento: un 0.5% para los inversores ángeles, un 6% en la Primera Ronda de Financiamiento, un 3.5% en la Segunda Ronda y un 5% a los inversores de los Launchpads.
															</p>
															<p className='txtPDF'>El valor inicial del GHUT será de 0,1 €, equivalente al valor en POL según el valor al día y hora de emisión. Este valor refleja nuestros costos operativos y la demanda prevista, proporcionando un punto de partida claro y justificado. A partir de esa fecha, el precio del GHUT será determinado por el mercado, ajustándose según la oferta y la demanda a lo largo del tiempo.</p>
															<p className='txtPDF'>Estos términos certifican que, a cambio del pago por parte del inversor, la empresa emite al inversor el derecho al importe de la compra de GHUT tokens sujeto a los términos establecidos en este documento.</p>
															<p>&nbsp;</p>
															<h2 className='headerPDF'>BENEFICIOS</h2>
															<p className='txtPDF'>Los inversores de la primera ronda de financiamiento del proyecto Guard Hawk recibirán una serie de beneficios exclusivos, que incluyen:</p>
															<ul>
																<li>GHUT (token de utilidad Guard Hawk) según cantidad invertida.</li>
																<li>Tokens GHGO (Guard Hawk Governance): La distribución de tokens GHGO será proporcional a la inversión. El 30% de los tokens GHGO estará destinado a los inversores, quienes recibirán una cantidad de tokens acorde a su participación en cada ronda de financiación. Esto les permitirá influir en las decisiones del proyecto de acuerdo con su contribución financiera.</li>
																<li>1 NFT (token no fungible) exclusivo coleccionable para inversionistas ángeles.</li>
															</ul>
															<p>&nbsp;</p>
															<p>&nbsp;</p>

															<h2 className='headerPDF'>CONDICIONES DE VENTA</h2>
															<h4 className='txtPDF'>Reserva de derechos</h4>
															<p className='txtPDF'>La Compañía se reserva el derecho de ofrecer y vender el Token en diferentes términos y en múltiples rondas. Los términos aplicables a cada ronda posterior a esta ronda pueden modificarse siempre que no se exceda el suministro total de tokens.</p>
															<h4 className='txtPDF'>Entrega de la Inversión</h4>
															<p className='txtPDF'>Al celebrar el acuerdo con la Compañía de conformidad con las disposiciones del presente documento, el Inversor acepta y se compromete a transferir, el Monto de Compra correspondiente al monto del valor del Token y de conformidad con estos términos. Al aceptar el presente documento, el Inversor acepta voluntariamente estar obligado contractualmente por sus términos.</p>
															<p></p>
															<h4 className='txtPDF'>Condiciones de entrega de tokens GHUT, GHGO y NFT</h4>
															<p className='txtPDF'>
																La cantidad correspondiente de token GHUT, token GHGO y NFT se entregará al Inversor en su billetera digital de acuerdo con los detalles
																proporcionados por el Inversor en el presente documento. El Token se entregará a la Billetera bajo el control directo o indirecto del Inversor que no podrá delegarse a ningún tercero autorizado o no autorizado, incluidos los representantes del Inversor. Los Tokens y NFT se entregarán a la dirección de la Billetera indicada por el Inversor en un plazo máximo de 7 días corridos, desde transcurrido el plazo de 6 meses establecido para la captación de capital del proyecto, siempre y cuando se cumpla la meta establecida de captación de capital, ya que de lo contrario el monto transferido por parte del inversionista será devuelto a su billetera, de acuerdo al Smart Contract {responseData.ethContract}, link https://polygonscan.com/address/{responseData.ethContract}.</p>
															<p></p>
															<h4 className='txtPDF'>Rescisión</h4>
															<p className='txtPDF'>Este SAFT y sus disposiciones se considerarán terminados siempre y cuando:</p>
															<ul>
																<li>La cantidad aplicable de Token será entregada al Inversor en el plazo de 7 días corridos, desde que se ha finalizado el periodo de 6 meses de captación de capital, teniendo un resultado positivo, es decir, se haya captado el capital necesario; o</li>
																<li>La devolución de la Inversión realizada al inversionista, de acuerdo establece el contrato inteligente, en caso que una vez transcurrido el plazo de los 6 meses para la captación de capital, no se haya conseguido el monto necesario.</li>
															</ul>
															<p className='txtPDF'>*Los costos asociados a las transacciones de la red serán de cargo exclusivo del inversionista.</p>
															<p>&nbsp;</p>
															<h2 className='headerPDF'>CONDICIONES DE BLOQUEO</h2>
															<p className='txtPDF'>La estructura de bloqueo (vesting) y liberación de GHUT para la primera ronda de financiación está diseñada para alinear sus intereses con el crecimiento y la estabilidad a largo plazo de Guard Hawk. La siguiente estructura refleja un enfoque justo y equilibrado:</p>
															<h4 className='txtPDF'>Periodo de Bloqueo (Cliff)</h4>
															<p className='txtPDF'>Bloqueo de Inversiones para Capitales Ángeles: El 100% de los tokens GHUT emitidos en esta ronda de financiación, que representa el 0,5% del total de los tokens emitidos, estarán bloqueados durante 1 año a partir del día del lanzamiento del token. Después de este período, se liberará el 10% de los tokens en el primer mes, y el 90% restante se liberará de manera proporcional durante los siguientes 18 meses.</p>
															<p>&nbsp;</p>
															<h2 className='headerPDF'>CLÁUSULAS DE PROTECCIÓN</h2>

															<ul>
																<li><strong>Cláusula de reactivación del bloqueo: </strong>
																	Cuando un incidente importante, una anomalía o una fuerza mayor afecte directa o indirectamente a la viabilidad futura del proyecto o a los intereses de los inversores, el comité podrá, en caso de informe de riesgo desfavorable, reactivar el bloqueo del token durante un máximo de 7 días consecutivos. Si el comité decide que el citado plazo es tiempo insuficiente para subsanar el incidente o fuerza mayor, deberá someter a Gobernabilidad la decisión sobre el mantenimiento del bloqueo durante un periodo más largo, basándose en todo momento en los informes de riesgos aplicables. Este mecanismo garantiza que cualquier acción tomada se alinee con los intereses de la comunidad y los titulares de tokens, proporcionando una capa adicional de seguridad y transparencia. La gobernanza desempeñará un papel crucial en la evaluación de la situación y en la toma de decisiones informadas, teniendo en cuenta en todo momento los mejores intereses del proyecto.
																</li>
																<p></p>
																<li><strong>Cláusula de congelación de billeteras criptográficas (lista negra): </strong>
																	A solicitud de los reguladores y/o tribunales competentes, las billeteras que registren actividades fraudulentas, o que no cumplan con los requisitos regulatorios y los estándares mínimos de seguridad, pueden ser congeladas. Esto es importante para la tranquilidad de los inversores, ya que les impide invertir en el mismo proyecto que inversores poco fiables. Estas listas están diseñadas para prevenir fraudes y estafas.</li>
															</ul>
															<p>&nbsp;</p>
															<h2 className='headerPDF'>TRANSPARENCIA Y COMUNICACIÓN</h2>
															<ul>
																<li><strong>Anuncios claros: </strong>Todos los términos de bloqueo y liberación se anunciarán claramente antes de que los inversores comprometan su capital.</li>
																<li><strong>Comunicación continua: </strong>
																	Mantendremos abierto los canales de comunicación que sean indicados en nuestra página web, tales como Discord, Telegram, etc. De esa manera los inversores, recojan sus opiniones y presenten propuestas a Gobernanza para que las políticas puedan ajustarse según sea necesario en beneficio del proyecto y de la comunidad.</li>
															</ul>
															<p>&nbsp;</p>
															<h2 className='headerPDF'>GARANTÍA</h2>
															<p className='txtPDF'>
																La propuesta de financiación de Guard Hawk destaca por la seguridad que ofrece a los inversores. Guard Hawk garantiza el reembolso automático de las inversiones si no se cumplen los objetivos financieros en el plazo establecido, mediante la aplicación del Smart Contract código {responseData.ethContract},</p> <p className='txtPDF'>disponible en el link https://polygonscan.com/address/{responseData.ethContract}.
																	En caso de proceder la devolución, ésta se efectuará en POL, siendo los costos de transacción de cargo del inversor.
															</p>
															<p className='txtPDF'>Esta medida reduce significativamente el riesgo para los inversores, aumentando su confianza en la gestión responsable y viabilidad del proyecto.</p>
															<p className='txtPDF'>El proyecto será auditado por una firma internacional durante la etapa de desarrollo para garantizar que se cumplan los hitos de desarrollo y los gastos se mantengan transparentes, con los resultados publicados en nuestro sitio web. Esta transparencia adicional es crucial para mantener la confianza de los inversores.</p>
															<ul>
																<p className='txtPDF'>-	Es importante destacar que cerraremos la captación de inversores iniciales al alcanzar nuestro objetivo de financiación, dentro de un plazo de 6 meses. El cierre se realizara el día 1 abril de 2025 00:00 horas GMT 0, con un límite máximo fijado entre 600.000 € y 650.000 €, equivalentes en POL a la fecha de cierre.
																</p>
															</ul>
															<p className='txtPDF'>Además, dentro de las proyecciones tokenómicas futuras se estipula una distribución continua para liquidez del mercado a través de diferentes exchanges -centralizados y descentralizados- de tal forma de permitir movimientos libres de entrada y salida al ecosistema.
															</p>
															<p>&nbsp;</p>
															<h2 className='headerPDF'>DECLARACIONES DE LA EMPRESA</h2>
															<p className='txtPDF'>La Compañía es una empresa debidamente constituida, válidamente existente y en buen estado bajo las leyes del estado de su incorporación.</p>
															<p className='txtPDF'>
																La Compañía afirma y reconoce que, no ha estado ni está en ninguna violación de sus artículos de incorporación o estatutos actuales, ni ha estado o está actualmente sujeta a ningún litigio, investigación o procedimiento legal en curso o pendiente; tampoco ha sido o está actualmente en violación de ninguna ley o reglamento que pueda resultar potencial y razonablemente en un daño material a la Compañía o sus activos; Tampoco ha estado o está actualmente involucrada en ningún acto relacionado con el blanqueo de capitales, la financiación del terrorismo, el fraude u otros delitos financieros.
															</p>
															<p>&nbsp;</p>
															<h2 className='headerPDF'>DECLARACIONES INVERSIONISTA</h2>
															<p className='txtPDF'>Como inversionista, usted declara que:</p>
															<ul>
																<li>Ha leído y aceptado el presente acuerdo, que es parte integral y vinculante entre las partes, antes de invertir en el proyecto Guard Hawk.</li>
																<li>Ha leído y aceptar los términos y condiciones de la plataforma de Guard Hawk, Aviso legal web y su política de privacidad.</li>
																<li>Los fondos que proporcionas provienen de actividades legales, de acuerdo con la normativa de la Unión Europea.</li>
																<li>No permitirá que terceros realicen depósitos en sus cuentas con fondos procedentes de actividades ilegales, de acuerdo con la normativa de la Unión Europea; Tampoco participará en transacciones destinadas a dichas actividades o a favor de personas relacionadas con las mismas. </li>
																<li>Se compromete a rescindir cualquier acuerdo, negocio o contrato celebrado con Guard Hawk si incumple cualquiera de las disposiciones de este documento, eximiendo a Guard Hawk de cualquier responsabilidad resultante de la información errónea, falsa o inexacta que haya proporcionado, o de un incumplimiento de los términos y condiciones del presente documento.</li>
																<li>Usted autoriza a Guard Hawk a comprobar si ha sido sancionado por alguna autoridad europea o estadounidense, con el fin de verificar las declaraciones anteriores.</li>
																<li>Usted autoriza a Guard Hawk a contratar con terceros los servicios de verificación de identificación de identidad que serán utilizados al momento de registrarse como inversor.</li>
																<li>El Inversor ha alcanzado la mayoría de edad legal en su país de residencia y, por lo tanto, tiene capacidad legal para celebrar este SAFT y estar sujeto a sus términos</li>
																<li>La celebración de un acuerdo vinculante con la Sociedad y la obligación de cumplir los términos y disposiciones de este SAFT no contradice ni incumple ningún otro término contractual por el que el Inversor esté legalmente obligado al entrar en vigor este SAFT</li>
																<li>El Inversor no se encuentra y/o reside en ninguno de los lugares y jurisdicciones restringidas, incluidas las sujetas a regulaciones prohibitivas, sanciones financieras internacionales y medidas de restricción económica.</li>
															</ul>
															<p className='txtPDF'>En un caso en el que el Inversor sea la persona jurídica, el Inversor reconoce y garantiza que:</p>
															<ul>
																<li>La organización se constituye con toda la debida diligencia de la formación e incorporación de la empresa en el país de su ubicación legal, a organización lleva a cabo sus actividades de acuerdo con las leyes y reglamentos aplicables.</li>
																<li>El representante de la empresa que actúa como signatario de este SAFT ha sido legalmente autorizado para representar a la organización y celebrar acuerdos legalmente vinculantes en su nombre.</li>
																<li>La organización no representa ni lleva a cabo sus actividades comerciales en campos relacionados con formas ilegales de actividad comercial, infracción de derechos de propiedad intelectual, bienes y servicios regulados y con licencia, servicios y esquemas financieros fraudulentos y restringidos, así como otros bienes y servicios injustos, engañosos, agresivos y no regulados de acuerdo con las leyes pertinentes.</li>
															</ul>
															<p>&nbsp;</p>
															<h2 className='headerPDF'>CONFIDENCIALIDAD</h2>
															<p className='txtPDF'>Cualquier información confidencial que haya sido o vaya a ser revelada al inversor durante el periodo de captación de capital, y todos los derechos sobre dicha información, seguirán siendo propiedad de Guard Hawk.</p>
															<p className='txtPDF'>Los inversores no obtendrán ningún derecho de ningún tipo sobre la información, ni ningún derecho de uso de la información, excepto para los fines de este acuerdo. La divulgación de información confidencial no implica la concesión de licencias de derechos de patente, derechos de autor o cualquier otro derecho por parte de Guard Hawk, distintos de los establecidos en este documento.</p>
															<p className='txtPDF'>Los inversores se comprometen a mantener la información confidencial (es decir, cualquier información relacionada con la estrategia comercial, financiera y de marketing) en estricta confidencialidad y a no revelar ninguna información a ninguna otra parte, relacionada o de otro tipo, sin el consentimiento previo por escrito de Guard Hawk. </p>
															<p className='txtPDF'>Si el inversionista es una persona jurídica, su representante deberá informar a cualquier persona responsable de recibir información confidencial -y deberá firmar el acuerdo de confidencialidad si fuera necesario- de su obligación de recibir, procesar y utilizar cualquier información confidencial que reciba como confidencial y destinada únicamente a los fines del acuerdo, en los términos aquí establecidos. </p>
															<p className='txtPDF'>Los inversores deben tratar toda la información recibida directa o indirectamente de Guard Hawk de forma confidencial y no utilizar dicha información de ninguna otra manera que no sea para los fines de este acuerdo. Los inversores no deben manejar, usar, explotar o divulgar información confidencial a ninguna persona o entidad por ningún motivo en contravención de este instrumento, a menos que Guard Hawk lo autorice expresamente por escrito.</p>
															<p className='txtPDF'>La divulgación o el uso de la información por parte de un inversor en incumplimiento de este acuerdo se considerará motivo para pagar una indemnización por daños y perjuicios.</p>

															<p className='txtPDF'>Las obligaciones previstas en el presente documento no se aplicarán en los siguientes casos:</p>

															<ol>
																<p className='txtPDF'>i. A la información que es de dominio público o que en lo sucesivo pasa a ser de dominio público.</p>
																<p className='txtPDF'>ii. A la información que no haya sido obtenida de Guard Hawk o que deba ser divulgada por ley o por orden de una autoridad o tribunal. </p>
																<p className='txtPDF'>iii.	Si la información o cualquier parte de la misma es obtenida legalmente por el inversor de un tercero o terceros sin incumplir este acuerdo, cuando se pueda demostrar que el tercero es una fuente legal de información.</p>

																<p className='txtPDF'>
																	iv.	Si la información o cualquier parte de la misma era conocida por el inversor antes de su divulgación por parte de Guard Hawk, siempre que el inversor pueda demostrar dicho conocimiento. Los inversores reconocen que las excepciones antes mencionadas no incluirán combinaciones de tipos de información simplemente porque cada uno de ellos sea de dominio público o esté en posesión del inversor. Los inversores serán responsables de demostrar sus derechos con respecto a cualquier excepción prevista en esta cláusula.
																</p>
															</ol>
															<p className='txtPDF'>Este acuerdo no constituye una garantía para el inversor por parte de Guard Hawk con respecto a cualquier violación relacionada con la información de patentes u otros derechos de terceros. Guard Hawk no será responsable de la duración de la divulgación de la información, de los errores u omisiones en la información, ni del uso y los resultados del uso de la información. Guard Hawk no será responsable de ninguna manera por cualquier pérdida de ningún tipo, incluidos, entre otros, daños y pérdidas, costos de intereses, pérdida de ganancias y cualquier otra pérdida o daño similar que resulte de cualquier error, inexactitud, omisión o cualquier otro defecto en la información.</p>
															<p>&nbsp;</p>
															<h2 className='headerPDF'>RESPONSABILIDAD</h2>
															<p className='txtPDF'>Bajo ninguna circunstancia Guard Hawk garantiza el éxito del proyecto. Sin embargo, el equipo de trabajo hará todo lo posible para desarrollar y llevar a cabo el proyecto tal y como se describe en el whitepaper. Tenga en cuenta que siempre existe la posibilidad de casos de fuerza mayor o contingencias que nos obliguen a cambiar o dar por terminado el proyecto. La inversión tiene riesgos inherentes, incluida la posibilidad de que Guard Hawk no pueda seguir la hoja de ruta establecida en su documento técnico. Antes de invertir en Guard Hawk, los inversionistas deben evaluar los riesgos asociados y, en su caso, buscar el asesoramiento de un experto externo. Los inversores desinformados que no acepten los riesgos financieros o de cumplimiento de este proyecto no deberían invertir en Guard Hawk. </p>
															<p>&nbsp;</p>
															<h2 className='headerPDF'>RECEPCIÓN DE NOTIFICACIONES</h2>
															<p className='txtPDF'>La recepción de cualquier notificación relacionada con el objeto de este acuerdo se iniciará únicamente por escrito desde la dirección de correo electrónico correspondiente de la respectiva Parte indicada en este acuerdo al correo electrónico de la otra Parte, en las mismas condiciones.</p>
															<p>&nbsp;</p>
															<h2 className='headerPDF'>LIMITACIÓN DE LA REPRESENTACIÓN</h2>
															<p className='txtPDF'>El Inversor no tendrá derecho a ningún derecho de representación de la Sociedad, ni a ningún derecho de voto y gestión en la Sociedad. Del mismo modo, el Inversor no tendrá derecho a ningún puesto en el consejo de administración de la Empresa ni a la adquisición o transferencia de ningún título de propiedad o participación en los activos de la Empresa sobre la base de su tenencia del Token. Además, el Inversor no tendrá derecho a votar para la elección o destitución de ningún miembro del consejo de administración de la Sociedad, ni a recibir notificación de reuniones ni a recibir los derechos correspondientes de las mismas.</p>
															<p>&nbsp;</p>
															<h2 className='headerPDF'>DIVISIBILIDAD</h2>
															<p className='txtPDF'>En el caso de que una o más de las disposiciones de este instrumento se consideren inválidas, ilegales o inaplicables, en su totalidad o en parte o en cualquier aspecto, o en el caso de que una o más de las disposiciones de este instrumento operen o puedan operar prospectivamente para invalidar este instrumento,  En ese caso, y en cualquier caso, solo dicha(s) disposición(es) se considerará(n) nula(s) y sin efecto y no afectará a ninguna otra disposición de este instrumento y las disposiciones restantes de este instrumento permanecerán operativas y en pleno vigor y efecto y no se verán afectadas, perjudicadas o perturbadas por ello.</p>
															<p>&nbsp;</p>
															<h2 className='headerPDF'>NO ASESORAMIENTO FINANCIERO</h2>
															<p className='txtPDF'>El Inversor garantiza que el presente acuerdo, y cualquier información entregada por Guard Hawk no se percibirán como asesoramiento de inversión, financiero, regulatorio, fiscal o legal y que el Inversor ha buscado dicho asesoramiento a su propia discreción e independientemente de los profesionales con cualificaciones y/o licencias pertinentes. Cualquier decisión con respecto a la conclusión del Inversor respecto a la compra de GHUT que sea tomada con o sin la ayuda del asesor pertinente será de su propia responsabilidad.</p>
															<p>&nbsp;</p>
															<h2 className='headerPDF'>CONOCIMIENTO DEL INVERSOR</h2>
															<p className='txtPDF'>El Inversor reconoce y garantiza que ha obtenido y posee suficiente conocimiento en asuntos financieros y comerciales y que es capaz de evaluar los riesgos de beneficios asociados con la compra, venta y negociación de monedas virtuales, incluidos los tokens. El Inversor reconoce además el riesgo de la inversión en monedas virtuales y que ha evaluado su situación financiera y circunstancial y ha considerado si la compra del Token es adecuada para él. El Inversor acepta que es consciente de las posibilidades de pérdida total e irreversible de sus activos financieros y que recuperarse de dicha pérdida puede ser difícil o imposible.</p>
															<p>&nbsp;</p>
															<h2 className='headerPDF'>TERCEROS</h2>
															<p className='txtPDF'>El Inversor reafirma que está comprando el Token por su propia cuenta para inversión, no como nominado o agente, y no con miras a, o para la reventa en relación con, la distribución del mismo, y el Inversor no tiene intención actual de vender, otorgar ninguna participación o distribuir el mismo.</p>
															<p>&nbsp;</p>
															<p>&nbsp;</p>
															<p className='txtPDF'>_______________________</p>
															<p className='txtPDF'>GERMAN MUÑOZ MENESES</p>
															<p className='txtPDF'>CEO</p>
															<p className='txtPDF'>GUARD HAWK LDA</p>
															<p>&nbsp;</p>
															<p className='txtPDF'>_______________________</p>
															<p className='txtPDF'>La Billetera Nº {(responseData.ethAddress)}</p>

														</div>
														<button onClick={() => downloadPDF(index)} className="downloadSaft">DOWNLOAD SAFT PDF</button>

													</div>
												</div>
											)}

										</div>

									))}

									{_responseData.length === 0 && <p className='notxns'>No hay ninguna transacción todavía</p>}

									<div className='listNote'>VER TODAS LAS TRANSACCIONES</div>
								</div>

							</div>
						</div>
					</div>

				</div>
				<img className='grid' src={grid} />

			</div>

			<RegisterLogin />

			<div className='onlyMob'>
				<HeaderNavBar />
			</div>

		</div>
	)

}
export default MyInvestments;
