import React, { Component } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from "./Pages/Home";
import Invest from './Pages/invest';
import InvestGHUT from './Pages/investGhut';
import MyInvestments from './Pages/myInvestments';
import Notifications from './Pages/notifications';
import MyAccount from './Pages/myaccount';
import Admin from './Pages/admin';
import HomeGhut from './Pages/Homeghut';
 
import HomeEn from "./Pages/HomeEn";
import InvestEn from './Pages/investEn';
import InvestGHUTEn from './Pages/investGhutEn';
import MyInvestmentsEn from './Pages/myInvestmentsEn';
import NotificationsEn from './Pages/notificationsEn';
import MyAccountEn from './Pages/myaccountEn';
import HomeGhutEn from './Pages/HomeghutEn';
 
function App() {

	return (
		<div>
			<BrowserRouter>
				<Routes>

					<Route path='/' element={<Home />} />
					<Route path='invest' element={<Invest />} />
					<Route path='myInvestments' element={<MyInvestments />} />
					<Route path='notifications' element={<Notifications />} />
					<Route path='myAccount' element={<MyAccount />} />
					<Route path='admin' element={<Admin />} />
					<Route path='ghut' element={<HomeGhut />} />
					<Route path='InvestGHUT' element={<InvestGHUT />} />

					<Route path='en' element={<HomeEn />} />
					<Route path='investEn' element={<InvestEn />} />
					<Route path='myInvestmentsEn' element={<MyInvestmentsEn />} />
					<Route path='notificationsEn' element={<NotificationsEn />} />
					<Route path='myAccountEn' element={<MyAccountEn />} />
					<Route path='ghutEn' element={<HomeGhutEn />} />
					<Route path='InvestGHUTEn' element={<InvestGHUTEn />} />
  
				</Routes>
			</BrowserRouter>
			<div>
		

			</div>
		</div>




	)
}


export default App;
