/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Web3Button, Web3Modal, useWeb3Modal } from '@web3modal/react';
import { mainnet, useDisconnect, useAccount, useContractRead, useContractReads, useContractWrite, useNetwork, usePublicClient, useSwitchNetwork } from 'wagmi';
import { createPublicClient, formatEther, http, parseEther } from 'viem';
import { polygon } from 'wagmi/chains';
import '../App.css';
import doc from '../assets/file.png';
import download from '../assets/downloads.png';
import { base } from 'wagmi/chains';
import Header from '../components/headers';
import grid from '../assets/grid.png';
import HeaderMobile from '../components/headersMobile';
import HeaderNavBar from '../components/headersNavBar';
import axios from 'axios';
import html2pdf from "html2pdf.js";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

//import { jsPDF } from "jspdf";
import RegisterLogin from '../components/regsiterAndLogin';
import HeaderNavBarEn from '../components/headersNavBarEn';
import RegisterLoginEn from '../components/regsiterAndLoginEn';
import HeaderEn from '../components/headersEn';
import HeaderMobileEn from '../components/headersMobileEn';

var Scroll = require('react-scroll');

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

const tweet = () => {
	window.open("#");
}

const tg = () => {
	window.open("#");
}

let ABIToken = [
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "Paused",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "Unpaused",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			}
		],
		"name": "allowance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "burn",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "burnFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimals",
		"outputs": [
			{
				"internalType": "uint8",
				"name": "",
				"type": "uint8"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "subtractedValue",
				"type": "uint256"
			}
		],
		"name": "decreaseAllowance",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "addedValue",
				"type": "uint256"
			}
		],
		"name": "increaseAllowance",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "pause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "transfer",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "unpause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	}
];
let addressToken = "0xDcbF2647B9ccb7F85a3Da06446B670f2FD8ca643"

let ABI = [
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_token",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [],
		"name": "Cancel",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [],
		"name": "Claim",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "softCap",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "hardCap",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint32",
				"name": "startAt",
				"type": "uint32"
			},
			{
				"indexed": false,
				"internalType": "uint32",
				"name": "endAt",
				"type": "uint32"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "minAmount",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "maxAmount",
				"type": "uint256"
			}
		],
		"name": "Launch",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "caller",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "PledgeCanceled",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "caller",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "PledgeConfirmed",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "caller",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "PledgeIntention",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "caller",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Refund",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "EURtoPOL",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "POLToEUR",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "POLToGHUT",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "approvedList",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "cancel",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_wallet",
				"type": "address"
			}
		],
		"name": "cancelPledgeTo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "canceled",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "claim",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "claimed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_wallet",
				"type": "address"
			}
		],
		"name": "confirmPledge",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimalCorrecter",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimalCorrecter2",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "deposit",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "receiver",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "distribution",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "eligibleGHUTTokens",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "endAt",
		"outputs": [
			{
				"internalType": "uint32",
				"name": "",
				"type": "uint32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "exchangeRate",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getApprovedList",
		"outputs": [
			{
				"internalType": "address[]",
				"name": "",
				"type": "address[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getBalance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getChainlinkDataFeedLatestAnswer_USD_EUR",
		"outputs": [
			{
				"internalType": "int256",
				"name": "",
				"type": "int256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getChainlinkDataFeedLatestAnswer_USD_POL",
		"outputs": [
			{
				"internalType": "int256",
				"name": "",
				"type": "int256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getHardCapInPol",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getMaxAmountInPol",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getMinAmountInPol",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getPledgeAddressList",
		"outputs": [
			{
				"internalType": "address[]",
				"name": "",
				"type": "address[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getSoftCapInPol",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "hardCap",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "holded",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "holdedAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "holdedAmountInEuros",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_softCap",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_hardCap",
				"type": "uint256"
			},
			{
				"internalType": "uint32",
				"name": "_startAt",
				"type": "uint32"
			},
			{
				"internalType": "uint32",
				"name": "_endAt",
				"type": "uint32"
			},
			{
				"internalType": "uint256",
				"name": "_minAmount",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_maxAmount",
				"type": "uint256"
			}
		],
		"name": "launch",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "maxAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "minAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "pledge",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "pledgeList",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "pledged",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "pledgedAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "refund",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "wallet",
				"type": "address"
			}
		],
		"name": "refundTo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_df",
				"type": "address"
			}
		],
		"name": "setDataFeed_USD_EUR",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_df",
				"type": "address"
			}
		],
		"name": "setDataFeed_USD_POL",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_decimalCorrecter",
				"type": "uint256"
			}
		],
		"name": "setDecimalCorrecter",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_decimalCorrecter2",
				"type": "uint256"
			}
		],
		"name": "setDecimalCorrecter2",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint32",
				"name": "_endAt",
				"type": "uint32"
			}
		],
		"name": "setEndAt",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_exchangeRate",
				"type": "uint256"
			}
		],
		"name": "setExchangeRate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_hardCap",
				"type": "uint256"
			}
		],
		"name": "setHardCap",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_maxAmount",
				"type": "uint256"
			}
		],
		"name": "setMaxAmount",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_minAmount",
				"type": "uint256"
			}
		],
		"name": "setMinAmount",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_pledged",
				"type": "uint256"
			}
		],
		"name": "setPledged",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_softCap",
				"type": "uint256"
			}
		],
		"name": "setSoftCap",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint32",
				"name": "_startAt",
				"type": "uint32"
			}
		],
		"name": "setStartAt",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_tokenContract",
				"type": "address"
			}
		],
		"name": "setTokenContract",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "softCap",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "startAt",
		"outputs": [
			{
				"internalType": "uint32",
				"name": "",
				"type": "uint32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "token",
		"outputs": [
			{
				"internalType": "contract IERC20",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "tokenWithdrawal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdrawAnyLeftbalance",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"stateMutability": "payable",
		"type": "receive"
	}
];
let address = "0xfFe1d6AB2733027aaB7fEb7f49218fb5Da888f20"

let ABIWETH = [
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "src",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "guy",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "wad",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "dst",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "wad",
				"type": "uint256"
			}
		],
		"name": "Deposit",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "src",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "dst",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "wad",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "src",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "wad",
				"type": "uint256"
			}
		],
		"name": "Withdrawal",
		"type": "event"
	},
	{
		"payable": true,
		"stateMutability": "payable",
		"type": "fallback"
	},
	{
		"constant": true,
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "allowance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"internalType": "address",
				"name": "guy",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "wad",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [],
		"name": "decimals",
		"outputs": [
			{
				"internalType": "uint8",
				"name": "",
				"type": "uint8"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [],
		"name": "deposit",
		"outputs": [],
		"payable": true,
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"internalType": "address",
				"name": "dst",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "wad",
				"type": "uint256"
			}
		],
		"name": "transfer",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"internalType": "address",
				"name": "src",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "dst",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "wad",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"internalType": "uint256",
				"name": "wad",
				"type": "uint256"
			}
		],
		"name": "withdraw",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	}
];
let addressWETH = "0x4200000000000000000000000000000000000006"

const MyInvestments = () => {

	const [_navbarOpen, set_navbarOpen] = useState(0)
	const [_percentage, setPercentage] = useState('0%')
	const contentRefs = useRef([]); // Array to store refs for each response

	const { open } = useWeb3Modal()
	const { chain } = useNetwork()
	const { switchNetwork } = useSwitchNetwork()
	const clientID = "2f8f716d-b303-481b-80ef-febaf341524b";
	const [_amount, set_amount] = useState('');
	const httpURL = "https://polygon-mainnet.infura.io/v3/9aad89c8e515457ab8b7805f5da593ea"
	const [_connected, setConnected] = useState(false);
	const [_downloadPDF, setDownloadPDF] = useState(0);
	const [_responseData, setResponseData] = useState([]);
	const [_softCap, setSoftCap] = useState(0);
	const [_HardCapInPOL, setHardCapInPOL] = useState(0);
	const [_MaxAmount, setMaxAmount] = useState(0);
	const [_MinAmount, setMinAmount] = useState(0);
	const [_GhutToPol, setGhutToPol] = useState(0);
	const [_rate, setRate] = useState(0);
	const [_ghutAmount, setGhutAmount] = useState(0);
	const { disconnect } = useDisconnect();
	const [popupIndex, setPopupIndex] = useState(null); // Track which popup is open

	const { address: walletAddress } = useAccount({
		async onConnect() {
			handleConnect()
		}
	})

	const transport = http(httpURL);

	const publicClient = createPublicClient({
		chain: polygon,
		transport
	})

	var contract = {
		address: address,
		abi: ABI
	}

	var contract2 = {
		address: addressToken,
		abi: ABIToken
	}

	async function handleConnect() {
		if (chain.id !== 137) {
			switchNetwork(137);
		}

		//await fetchNFTs();
		setConnected(true);
	}


	useEffect(() => {

		const getAllUserTxns = async () => {
			console.log("Im in My Investment")
			try {

				try {

					const response = await axios.get('https://api.guardhawk.io/api/Investment/getAllInvestmentByLoggedInUser', {
						headers: {
							'Authorization': `Bearer ${sessionStorage.getItem("userToken")}`
						}
					});

					console.log('File sent successfully.');
					console.log(response.data);

					if (response.data.isSuccess) {

						console.log(response.data.data);
						setResponseData(response.data.data);

					}


				} catch (error) {
					console.log('Error sending file.', error);
				} finally {
				}

			} catch (err) {
				console.log(err);
			}
		};

		async function fetchHardCapInPOL() {
			try {
				var data = await getHardCapInPol();
				let hardCapPOL = Number(data.data);
				console.log("Hard Cap in POL: " + hardCapPOL);

				// Set the hard cap in POL (if you have a state or variable to store it)
				setHardCapInPOL(hardCapPOL);

			} catch (error) {
				console.error("Error fetching data: ", error);
			}
		}

		async function fetchData5() {

			var data = await getSoftCap();
			var softCap = Number(data.data);
			setSoftCap(softCap);
			console.log("softCap : " + softCap);
		}

		async function fetchMinAmount() {
			var data = await getMinAmount();
			let minAmount = Number(data.data);
			setMinAmount(minAmount);
			console.log("minAmount : " + minAmount);
		}


		async function fetchMaxAmount() {
			var data = await getMaxAmount();
			let maxAmount = Number(data.data);
			setMaxAmount(maxAmount);
			console.log("maxAmount : " + maxAmount);
		}

		async function ghutToPol() {
			var ghutToPol = Number(1 * (10 ** 18 / Number(_rate)))
			setGhutToPol(ghutToPol);
			console.log("ghutToPol : " + ghutToPol);
		}

		async function fetchData2() {
			var data = await getRate();
			let rate = Number(data.data);
			setRate(Number(data.data));
			console.log("GHUT AMOUNT : " + (Number(_amount) * Number(data.data)) / 10 ** 18);
			setGhutAmount((Number(_amount) * Number(data.data)) / 10 ** 18);
			console.log("rate : " + rate);
		}

		getAllUserTxns();
		fetchData5();
		fetchHardCapInPOL();
		fetchMinAmount();
		fetchMaxAmount();
		ghutToPol();
		fetchData2();

	}, [_connected, walletAddress, _GhutToPol]);

	const { refetch: getRate } = useContractRead({
		...contract,
		functionName: 'POLToGHUT', args: [],
	})

	const { refetch: getHardCapInPol } = useContractRead({
		...contract,
		functionName: 'getHardCapInPol', args: [],
	})

	const { refetch: getSoftCap } = useContractRead({
		...contract,
		functionName: 'getSoftCapInPol', args: [],
	})

	const { refetch: getMinAmount } = useContractRead({
		...contract,
		functionName: 'minAmount', args: [],
	})


	const { refetch: getMaxAmount } = useContractRead({
		...contract,
		functionName: 'maxAmount', args: [],
	})

	/*const downloadPDF = (index) => {
		const element = contentRefs.current[index];  // Get the specific content for the selected index
		html2pdf()
			.from(element)
			.save(`GuardHawk_SAFT_${index}.pdf`);  // Save as a unique PDF for each index
	};*/

	const downloadPDF = (index) => {
		const element = contentRefs.current[index];

		setTimeout(() => {
			const doc = new jsPDF('p', 'mm', 'a4');
			const pageHeight = 280; // A4 page height in mm
			const pageWidth = 210; // A4 page width in mm
			let y = 20;

			// Select all the text elements
			const textElements = element.querySelectorAll('h, h2, h4, p, li, span');

			textElements.forEach((element) => {
				let text = element.textContent;

				if (element.tagName === 'H2') {
					doc.setFont('Helvetica', 'bold'); // Set font style to bold for h2 elements
					doc.setFontSize(20); // Set font size to 24px for h2 elements
					doc.setTextColor(0, 0, 0); // Set text color to red for h2 elements
					doc.setLineHeightFactor(1.5); // Set line height factor to 1.5
				} else if (element.tagName === 'P') {
					doc.setFont('Helvetica', 'normal'); // Set font style to regular for p elements
					doc.setFontSize(12); // Set font size to 8px for p elements
					doc.setTextColor((0, 0, 0, 0.700)); // Set text color to black for p elements
					doc.setLineHeightFactor(1.2); // Set line height factor to 1.2
				} else if (element.tagName === 'H4') {
					doc.setFont('Helvetica', 'bold'); // Set font style to bold for h4 elements
					doc.setFontSize(15); // Set font size to 8px for p elements
					doc.setTextColor(0, 0, 0); // Set text color to black for p elements
					doc.setLineHeightFactor(1.0); // Set line height factor to 1.2
				} else if (element.tagName === 'H') {
					doc.setFont('Helvetica', 'bold'); // Set font style to bold for h4 elements
					doc.setFontSize(22); // Set font size to 8px for p elements
					doc.setTextColor(0, 0, 0); // Set text color to black for p elements
					doc.setLineHeightFactor(1.0); // Set line height factor to 1.2
				} else if (element.tagName === 'LI') {
					doc.setFont('Helvetica', 'normal'); // Set font style to regular for li elements
					doc.setFontSize(11); // Set font size to 12px for li elements
					doc.setTextColor(0, 0, 0); // Set text color to black for li elements
					doc.setLineHeightFactor(1.2); // Set line height factor to 1.2
					text = '\u2022 ' + text; // Add a bullet point to the text
				} else if (element.tagName === 'SPAN' && element.parentElement.classList.contains('txtPDF')) {
					doc.setFont('Helvetica', 'bold'); // Set font style to bold for span elements
					doc.setFontSize(12); // Set font size to 12px for span elements
					doc.setTextColor(0, 0, 0); // Set text color to black for span elements
					doc.setLineHeightFactor(1.2); // Set line height factor to 1.2
				} else {
					doc.setFont('Helvetica', 'normal'); // Set font style to regular for other elements
					doc.setFontSize(12); // Set font size to 12px for other elements
					doc.setTextColor(0, 0, 0); // Set text color to black for other elements
					doc.setLineHeightFactor(1.2); // Set line height factor to 1.2
				}

				// Split the text into lines based on the available width
				let lines = doc.getTextDimensions(text).w / (pageWidth - 30);
				let maxLines = Math.ceil(lines);

				// Wrap the text to the specified number of lines
				doc.text(text, 15, y, { maxWidth: pageWidth - 30, maxLines: maxLines });

				// Update the y position based on the number of lines
				y += (maxLines * 5);

				// Add a small gap between headings
				if (element.tagName === 'H2' || element.tagName === 'H4') {
					y += 5; // Add a 5mm gap
				}

				// Add a small gap between list items
				if (element.tagName === 'LI') {
					y += 2; // Add a 2mm gap
				}

				// Add a small gap between paragraphs
				if (element.tagName === 'P') {
					y += 5; // Add a 2mm gap
				}

				// Check if the content exceeds the page height
				if (y > pageHeight - 20) {
					doc.addPage();
					y = 20;
				}
			});

			// Save the PDF
			doc.save(`saft-${index}.pdf`);
		}, 1000);
	};

	const openPopup = (index) => {
		setPopupIndex(index);  // Open the corresponding popup
	};

	const closePopup = () => {
		setPopupIndex(null);  // Close popup
	};

	function shortenAddress(walletAddress) {
		try {
			return _connected
				? walletAddress.slice(0, 3) + "..." + walletAddress.slice(-4)
				: "Connect";
		} catch (error) {
			console.log(error);
		}
	}

	async function disconnectWallet() {
		setConnected(false);
		disconnect();
		window.location.reload(true);
		window.location.href = '/'
	}

	const { writeAsync } = useContractWrite({
		onError(error) {
			if (error.message.includes("balance")) {
				setstatusError(true);
				setstatusLoading(false);
			}
		},
	});

	async function downloadPDFWindow() {
		setDownloadPDF(1);
	}

	async function closeWindow() {
		setDownloadPDF(0);
	}

	const getRelativeTime = (date) => {
		const now = new Date();
		const past = new Date(date);
		const diffInSeconds = Math.floor((now - past) / 1000);

		const minutes = Math.floor(diffInSeconds / 60);
		const hours = Math.floor(minutes / 60);
		const days = Math.floor(hours / 24);

		if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`;
		if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
		if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
		return `${diffInSeconds} second${diffInSeconds > 1 ? 's' : ''} ago`;
	};

	return (
		<div>
			<div className='allWrap'>
				<div class="light">
					{_connected ? (
						<div className="connectT" onClick={() => disconnectWallet()}>
							{walletAddress === "" ? "Connect Web3 Wallet" : shortenAddress(walletAddress)}
						</div>
					) : (
						<div className="connectT" onClick={() => { open(); }}>Connect Web3 Wallet</div>
					)}

					<div className='onlyMob'>
						<HeaderMobileEn />
					</div>

					<div className='page1Main'>
						<HeaderEn />

						<div className='rightSection'>
							<div className='mainT'>MY INVESTMENTS</div>
							{_connected ?
								<div className='flexBox3T'>HELLO, {sessionStorage.getItem('displayName')}!</div> :
								<div className='flexBox3T'>HELLO</div>
							}
							<div className='flexBox4T'>WELCOME TO YOUR INVESTMENT SPACE</div>

							<div class="transaction-list-main">
								<div className='listT'>LATEST TRANSACTIONS</div>
								<div className='line3'></div>

								{_responseData.slice().reverse().map((responseData, index) => (
									<div key={index}>

										<div class="transaction-list">

											<div className='list1'>
												<img src={doc} />
												<div className='listConMain'>
													<div className='list1Con'>Transaction_contract_details {_responseData.length - index}</div>
													<div className='list1Con2'>{getRelativeTime(responseData.createdDate)}</div>
												</div>
											</div>

											{/*<div className='listConMain'>
												<div className='list1Con3'>Desde:</div>
												<div className='list1Con3'>Hacia:</div>
											</div>*/}

											<div className='listConMain'><button className='listBtn'>$$$$</button></div>

											<div className="listConMain2">
												<div className="listConMain2-2">Discharge</div>
												<div className="listConMain3">
													<div>SAFT</div>
													<img src={download} onClick={() => openPopup(index)} />
												</div>
											</div>

										</div>

										{popupIndex === index && (
											<div id="popupContainerPDF" class="popupPDF">
												<div class="close" onClick={closePopup}>✖</div>
												<div class="popup-contentPDF" >
													<div ref={el => contentRefs.current[index] = el}>

														<p className='centerAligned'><h2 id="centerAligned" className='headerPDF'>Simple Agreement For Future Token - Guard Hawk (SAFT)</h2>
															<h2 className='headerPDF' id="centerAligned">Angel Investor Round</h2></p>
														<h2 className='headerPDF'>INTRODUCTION</h2>

														<p className='txtPDF'>This agreement contains information regarding the Angel Capital investment round for the Guard Hawk utility token (GHUT), particularly on the conditions and restrictions applicable to anyone who decides to invest in the first round of Guard Hawk.</p>
														<p className='txtPDF'>
															It should be noted that a six-month period from the launch date has been granted to raise sufficient capital to develop the project.
														</p>

														<p>&nbsp;</p>
														<h2 className='headerPDF'>DEFINITIONS</h2>
														<p className='txtPDF'>&ldquo;<strong><bold><span>SAFT</span></bold></strong>&rdquo; -
															The Simple Agreement for Future Tokens is an instrument that grants an Investor future rights to receive a certain amount of Tokens in connection with their investment.
														</p>
														<p className='txtPDF'>&ldquo;<strong><bold><span>Company</span></bold></strong>&rdquo; - Refers to the legal entity “Guard Hawk.”</p>
														<p className='txtPDF'>&ldquo;<strong><span>Investor</span></strong>&rdquo; - Is a legal entity or individual investor that provides funds in exchange for future Tokens.
														</p>
														<p className='txtPDF'>&ldquo;<strong><span>Utility Token (GHUT)</span>&rdquo;</strong> - A type of asset that provides a user with a product or service.</p>
														<p className='txtPDF'>&ldquo;<strong><span>Governance Token (GHGO)</span>&rdquo;</strong> -
															A type of digital asset that grants its holders the right to participate in the decision-making process of a decentralized organization or protocol.
														</p>
														<p className='txtPDF'>&ldquo;<strong><span>NFT (Non-Fungible Token)</span>&rdquo;</strong> - A unique digital asset representing objects or concepts in the digital world, such as art, music, games, and other collectibles. In the context of Guard Hawk, a collectible NFT is digital art representing the Guard Hawk logo, granting its owner a unique piece of digital memorabilia tied to the brand.
														</p>
														<p className='txtPDF'>&ldquo;<strong><span>Dissolution Event</span></strong>&rdquo; - Means (a) a voluntary termination of operations by the Company at its sole discretion; (b) a general assignment for the benefit of the Company’s creditors; or (c) any other event of liquidation, dissolution, or winding up of the Company, excluding any change in ownership and control of the Company or an initial public offering.</p>
														<p className='txtPDF'>&ldquo;<strong><span>Subsequent Agreement</span></strong>&rdquo; - Means any SAFT issued by the Company after this SAFT for future Token sales rounds, in line with principles to raise capital. This definition excludes: (a) token issuances under the Company’s internal distribution plan, such as team incentives and other relevant events; (b) tokens issued to external service providers or others connected and associated with the Network launch, or similarly, for goods and services provided to the Company; (c) Tokens issued under research, collaboration, licensing, development, partnership, and other applicable sponsored agreements; (d) any convertible security issued by the Company; and (e) Tokens issued in connection with the Network’s mining activities and giveaways initiated by the Company.</p>

														<p>&nbsp;</p>
														<h2 className='headerPDF'>BUSINESS STRUCTURE</h2>

														<ul>
															<li>A total of 20,000,000,000 GHUT will be issued</li>
															<li>13,000,000 GHUT will be issued for the angel capital round from a total of 100,000,000 GHUT allocated in the Tokenomics.</li>
															<li>The total amount to be raised in the angel capital round fluctuates between €600,000 and €650,000, equivalent in POL.</li>
															<li>The GHUT value for angel capital investors is €0.05, equivalent in POL at the time and date of investment.</li>
															<li>Investment in this angel capital round may range from a minimum of €100 to a maximum of €50,000 per digital wallet, equivalent in POL at the time and date of investment. This applies regardless of the number of transactions, as long as they are within the specified ranges.</li>
														</ul>

														<p>&nbsp;</p>
														<h2 className='headerPDF'>INVESTMENT</h2>
														<ul>
															<li>Wallet No. {(responseData.ethAddress)} purchase {responseData.ghutAmount} GHUT.</li>
															<li>The total purchase value is {responseData.ethAmount} POL.</li>
														</ul>
														<p>&nbsp;</p>
														<h2 className='headerPDF'>DURATION</h2>
														<p className='txtPDF'>The Angel Capital investment round begins on October 1, 2024, and ends on April 1, 2025, at 00:00 GMT 0.</p>
														<p className='txtPDF'>15% of the total GHUT tokens are allocated to investors. This percentage will be distributed across three funding rounds: 0.5% for angel investors, 6% in the First Funding Round, 3.5% in the Second Round, and 5% to Launchpad investors.
															The initial GHUT value will be €0.1, equivalent to POL value at the time and date of issuance. This price reflects our operating costs and projected demand, providing a clear and justified starting point. From that date, GHUT’s price will be determined by the market, adjusting according to supply and demand over time.</p>
														<p className='txtPDF'>These terms certify that, in exchange for payment from the investor, the company grants the investor the right to the GHUT token purchase amount subject to the terms outlined in this document.</p>
														<p>&nbsp;</p>
														<h2 className='headerPDF'>BENEFITS</h2>
														<p className='txtPDF'>
															Angel investors in the Guard Hawk project’s first funding round will receive exclusive benefits, including:</p>
														<ul>
															<li>GHUT (Guard Hawk utility token) based on the invested amount.</li>
															<li>GHGO Tokens (Guard Hawk Governance): GHGO token distribution will be proportional to the investment. 30% of GHGO tokens will be allocated to investors, who will receive a number of tokens commensurate with their participation in each funding round, enabling them to influence project decisions according to their financial contribution.</li>
															<li>1 exclusive collectible NFT (Non-Fungible Token) for angel investors.</li>
														</ul>
														<p>&nbsp;</p>
														<p>&nbsp;</p>

														<h2 className='headerPDF'>SALE CONDITIONS</h2>
														<h4 className='txtPDF'>Right Reservation</h4>
														<p className='txtPDF'>The Company reserves the right to offer and sell the Token on different terms and in multiple rounds. Terms for each round subsequent to this one may change as long as the total token supply is not exceeded.</p>
														<h4 className='txtPDF'>Delivery of Investment</h4>
														<p className='txtPDF'>By entering into the agreement with the Company as per the provisions of this document, the Investor agrees and commits to transfer the Purchase Amount corresponding to the Token’s value amount and in accordance with these terms. By accepting this document, the Investor voluntarily agrees to be contractually bound by its terms.</p>
														<h4 className='txtPDF'>GHUT, GHGO, and NFT Delivery Conditions</h4>
														<p className='txtPDF'>
															The corresponding quantity of GHUT, GHGO, and NFT tokens will be delivered to the Investor in their digital wallet based on the details
															provided by the Investor in this document. The Token will be delivered to the Wallet under the Investor’s direct or indirect control, which cannot be delegated to any third party, authorized or unauthorized, including Investor representatives. Tokens and NFTs will be delivered to the Wallet address provided by the Investor within a maximum of 7 calendar days after the six-month capital-raising period for the project has concluded, provided the capital-raising target is met; otherwise, the amount transferred by the investor will be refunded to their wallet according to the Smart Contract {responseData.ethContract}, link <a href={`https://polygonscan.com/address/${responseData.ethContract}`} target="_blank" rel="noopener noreferrer">
																https://polygonscan.com/address/{responseData.ethContract}
															</a>.</p>
														<h4 className='txtPDF'>Termination:</h4>
														<p className='txtPDF'>This SAFT and its provisions shall be considered terminated if:</p>
														<ul>
															<li>The applicable Token amount is delivered to the Investor within 7 calendar days after the end of the 6-month capital-raising period, with a successful outcome, meaning the necessary capital has been raised; or</li>
															<li>The Investment is refunded to the investor, per the smart contract, if the capital-raising target is not met within the 6-month period.

															</li>
														</ul>
														<p className='txtPDF'>*Network transaction costs will be borne solely by the investor.</p>
														<p>&nbsp;</p>
														<h2 className='headerPDF'>LOCK-UP CONDITIONS</h2>
														<p className='txtPDF'>The lock-up (vesting) structure and release of GHUT for the first funding round is designed to align interests with Guard Hawk’s long-term growth and stability. The following structure reflects a fair and balanced approach:</p>
														<h4 className='txtPDF'>Cliff Period (Lock-Up)</h4>
														<p className='txtPDF'>Angel Investment Lock-Up: 100% of the GHUT tokens issued in this funding round, representing 0.5% of the total issued tokens, will be locked for one year from the token launch date. After this period, 10% of the tokens will be released in the first month, and the remaining 90% will be proportionally released over the next 18 months.</p>
														<p>&nbsp;</p>
														<h2 className='headerPDF'>PROTECTIVE CLAUSES</h2>
														<ul>

															<li>
																<strong>Lock Reactivation Clause: </strong>
																When a major incident, anomaly, or force majeure directly or indirectly affects the project’s future viability or investors’ interests, the committee may, upon a negative risk report, reactivate the token lock-up for a maximum of 7 consecutive days. If the committee decides the cited period is insufficient to resolve the incident or force majeure, it shall submit to Governance the decision to extend the lock-up, always based on applicable risk reports. This mechanism ensures that any action taken aligns with the community’s and token holders’ interests, providing an additional layer of security and transparency. Governance will play a crucial role in assessing the situation and making informed decisions, always considering the project’s best interests.
															</li>
														</ul>

														<ul>
															<li><strong><bold><span>Blacklist Clause:</span></bold></strong> At the request of regulators and/or competent courts, wallets with fraudulent activity or that do not meet regulatory requirements and minimum security standards may be frozen. This is important for investors’ peace of mind as it prevents unreliable investors from investing in the same project. These lists are designed to prevent fraud and scams.</li>
														</ul>
														<p>&nbsp;</p>
														<h2 className='headerPDF'>TRANSPARENCY AND COMMUNICATION</h2>
														<ul>
															<li>Clear Announcements: All lock-up and release terms will be clearly announced before investors commit capital.</li>
														</ul>

														<ul>
															<li>Ongoing Communication: We will keep open communication channels indicated on our website, such as Discord, Telegram, etc. This allows investors to share their opinions and submit proposals to Governance so that policies can be adjusted as needed for the benefit of the project and the community.</li>
														</ul>
														<p>&nbsp;</p>
														<h2 className='headerPDF'>GUARANTEE</h2>
														<p className='txtPDF'>
														Guard Hawk’s funding proposal stands out for the security it offers investors. Guard Hawk guarantees the automatic refund of investments if financial targets are not met within the specified timeframe, through the application of the Smart Contract code {responseData.ethContract}, available at link <a href={`https://polygonscan.com/address/${responseData.ethContract}`} target="_blank" rel="noopener noreferrer">
																https://polygonscan.com/address/{responseData.ethContract}
															</a>. 
															</p>
															<p className='txtPDF'>
															If a refund is due, it will be made in POL, with transaction costs borne by the investor.
															This measure significantly reduces risk for investors, increasing their confidence in the project’s responsible management and viability.
															The project will be audited by an international firm during the development phase to ensure that development milestones are met and expenditures remain transparent, with results published on our website. This additional transparency is crucial to maintaining investor confidence.
														</p>
														
														<ul>
															<li>Note that we will close the initial investor round upon reaching our funding goal within 6 months. Closing will take place on April 1, 2025, at 00:00 GMT 0, with a maximum limit set between €600,000 and €650,000, equivalent in POL as of the closing date.
																Future tokenomics projections include continuous distribution for market liquidity through various centralized and decentralized exchanges to allow free entry and exit within the ecosystem.
															</li>
														</ul>
														<p>&nbsp;</p>
														<h2 className='headerPDF'>COMPANY REPRESENTATIONS</h2>
														<p className='txtPDF'>The Company is duly incorporated, validly existing, and in good standing under the laws of its jurisdiction.
															The Company affirms and acknowledges that it is not and has not been in violation of its articles of incorporation or current bylaws, nor is it currently subject to any ongoing or pending litigation, investigation, or legal proceedings; it has not been or is currently involved in any act related to money laundering, terrorist financing, fraud, or other financial crimes.</p>
														<p>&nbsp;</p>
														<h2 className='headerPDF'>INVESTOR REPRESENTATIONS</h2>
														<p className='txtPDF'>As an investor, you declare that:</p>
														<ul>

															<li>You have read and accepted this agreement, which is an integral and binding part between the parties, before investing in the Guard Hawk project.</li>
															<li>You have read and accepted the terms and conditions of the Guard Hawk platform, the website’s legal notice, and its privacy policy.</li>
															<li>The funds you provide come from legal activities in accordance with European Union regulations.</li>
															<li>You will not allow third parties to make deposits into your accounts with funds from illegal activities under EU regulations, nor will you engage in transactions intended for such activities or for the benefit of related individuals.</li>
															<li>You commit to terminate any agreement, business, or contract with Guard Hawk if you violate any provision of this document, holding Guard Hawk harmless from any liability resulting from inaccurate, false, or misleading information you have provided or a breach of the terms and conditions in this document.</li>
															<li>You authorize Guard Hawk to verify if you have been sanctioned by any European or U.S. authority to verify these representations.</li>
															<li>You authorize Guard Hawk to contract third-party identity verification services to be used when registering as an investor.</li>
															<li>You have reached the legal age of majority in your country of residence and therefore have the legal capacity to enter this SAFT and be bound by its terms.</li>
															<li>Entering into a binding agreement with the Company and complying with the terms and provisions of this SAFT does not contradict or breach any other contractual terms the Investor is legally bound to at the time of entering into this SAFT.</li>
															<li>The Investor is not located in or resides in any restricted jurisdictions, including those subject to prohibitive regulations, international financial sanctions, or economic restrictions.</li>
														</ul>
														<p></p>

														<p className='txtPDF'>If the Investor is a legal entity, the Investor acknowledges and guarantees that:</p>
														<ul>

															<li>The organization is duly formed and incorporated in its legal jurisdiction and conducts its activities in accordance with applicable laws and regulations.</li>
															<li>The organization’s representative acting as the signatory of this SAFT has been legally authorized to represent the organization and enter into legally binding agreements on its behalf.</li>
															<li>The organization does not represent or conduct its business activities in fields related to illegal business activities, intellectual property infringement, regulated and licensed goods and services, or fraudulent or restricted financial services and schemes, as well as other unfair, deceptive, aggressive, and unregulated goods and services under applicable laws.</li>

														</ul>

														<p>&nbsp;</p>
														<h2 className='headerPDF'>CONFIDENTIALITY</h2>
														<p className='txtPDF'>Any confidential information that has been or will be disclosed to the investor during the capital-raising period, along with all rights to such information, will remain the property of Guard Hawk.
															Any confidential information that has been or will be disclosed to the investor during the capital-raising period, along with all rights to such information, will remain the property of Guard Hawk.</p>
														<p className='txtPDF'>Investors will not gain any rights of any kind to the information, nor any right to use it, except for the purposes of this agreement. The disclosure of confidential information does not imply granting licenses for patent rights, copyrights, or any other rights by Guard Hawk, apart from those established in this document.</p>
														<p className='txtPDF'>Investors agree to keep confidential information (including any business, financial, and marketing strategy information) strictly confidential and not disclose it to any other party without prior written consent from Guard Hawk.</p>
														<p className='txtPDF'>If the investor is a legal entity, its representative must inform any person responsible for receiving confidential information – who must also sign the confidentiality agreement if necessary – of their obligation to receive, process, and use any confidential information as intended only for this agreement’s purposes.</p>
														<p className='txtPDF'>Investors must treat all information received directly or indirectly from Guard Hawk as confidential and use it solely for the purposes of this agreement. Investors shall not handle, use, exploit, or disclose confidential information to any person or entity for any reason contrary to this instrument unless Guard Hawk expressly authorizes it in writing.</p>
														<p className='txtPDF'>Disclosure or use of information by an investor in breach of this agreement shall be grounds for compensation for damages.
															The obligations outlined in this document do not apply in the following cases:
														</p>
														<p className='txtPDF'>i. Information that is public or subsequently becomes public.</p>
														<p className='txtPDF'>ii. Information not obtained from Guard Hawk or that must be disclosed by law or by order of an authority or court.</p>
														<p className='txtPDF'>iii. If information or any part of it is legally obtained by the investor from a third party without violating this agreement, provided that the third party is a lawful source.</p>
														<p className='txtPDF'>iv. If information or any part of it was known to the investor before Guard Hawk’s disclosure, provided that the investor can demonstrate such knowledge.</p>
														<p className='txtPDF'>Investors acknowledge that the exceptions mentioned above do not include information types solely because each is publicly known or in the investor’s possession. Investors will be responsible for demonstrating their rights regarding any exception provided in this clause.</p>
														<p className='txtPDF'>This agreement does not guarantee the investor from Guard Hawk any assurance against any infringement related to patent information or other third-party rights. Guard Hawk shall not be responsible for the duration of the information disclosure, errors, or omissions in the information, nor the use and results of using the information. Guard Hawk shall not be responsible in any way for any loss, including but not limited to damages, interest costs, lost profits, or any other similar loss or damage resulting from any error, inaccuracy, omission, or any other defect in the information.</p>

														<p>&nbsp;</p>
														<h2 className='headerPDF'>LIABILITY</h2>
														<p className='txtPDF'>Under no circumstances does Guard Hawk guarantee the project’s success. However, the team will make every effort to develop and execute the project as described in the whitepaper. Be aware that force majeure or contingencies could require us to modify or terminate the project. Investment carries inherent risks, including the possibility that Guard Hawk cannot follow the roadmap established in its technical document. Before investing in Guard Hawk, investors should assess the associated risks and, if needed, seek advice from an external expert. Uninformed investors who do not accept the financial or compliance risks of this project should not invest in Guard Hawk.</p>
														<p>&nbsp;</p>
														<h2 className='headerPDF'>NOTICE RECEPTION</h2>
														<p className='txtPDF'>Any notification related to this agreement shall be initiated solely in writing from the email address of the respective Party indicated in this agreement to the other Party’s email under the same conditions.</p>
														<p>&nbsp;</p>
														<h2 className='headerPDF'>LIMITATION OF REPRESENTATION</h2>
														<p className='txtPDF'>The Investor shall not have any right of representation of the Company, nor any voting or management rights in the Company. Similarly, the Investor shall not have any position on the Company’s board nor the acquisition or transfer of ownership or shares in the Company’s assets based on their Token holding. The Investor shall also have no right to vote for the election or removal of any board member of the Company, nor receive notice of meetings or the rights associated with them.</p>
														<p>&nbsp;</p>
														<h2 className='headerPDF'>SEVERABILITY</h2>
														<p className='txtPDF'>In the event that one or more provisions of this instrument are deemed invalid, illegal, or unenforceable, in whole or in part, or in any respect, or if one or more provisions of this instrument may prospectively operate to invalidate this instrument, then, in any case, such provision(s) shall be considered void and of no effect and shall not affect any other provisions of this instrument, which shall remain operative and in full force and effect.</p>
														<p>&nbsp;</p>
														<h2 className='headerPDF'>NO FINANCIAL ADVICE</h2>
														<p className='txtPDF'>The Investor warrants that this agreement, and any information provided by Guard Hawk, shall not be construed as investment, financial, regulatory, tax, or legal advice and that the Investor has sought such advice independently, if needed, from qualified professionals. Any decision regarding the Investor’s purchase of GHUT, made with or without professional assistance, will be their sole responsibility.</p>
														<p>&nbsp;</p>
														<h2 className='headerPDF'>INVESTOR KNOWLEDGE</h2>
														<p className='txtPDF'>The Investor acknowledges and warrants that they have acquired sufficient knowledge in financial and business matters and are capable of evaluating the benefits and risks associated with the purchase, sale, and trading of virtual currencies, including tokens. The Investor further acknowledges the risks associated with virtual currency investment and has assessed their financial and circumstantial situation to determine if purchasing the Token is suitable for them. The Investor accepts the potential for total and irreversible financial loss and understands that recovering from such loss may be challenging or impossible.</p>
														<p>&nbsp;</p>
														<h2 className='headerPDF'>THIRD-PARTY INTEREST</h2>
														<p className='txtPDF'>The Investor confirms they are purchasing the Token for their own account, not as a nominee or agent, and not with an intent for resale related to its distribution. The Investor has no current intent to sell, transfer any stake, or distribute it.</p>
														<p>&nbsp;</p>

														<p className='txtPDF'>GERMAN MUÑOZ MENESES</p>
														<p className='txtPDF'>CEO</p>
														<p className='txtPDF'>GUARD HAWK LDA</p>
														<p className='txtPDF'> Wallet No. {(responseData.ethAddress)}</p>

													</div>
													<button onClick={() => downloadPDF(index)} className="downloadSaft">DOWNLOAD SAFT PDF</button>

												</div>
											</div>
										)}
									</div>
								))}

								{_responseData.length === 0 && <p className='notxns'>No transactions yet</p>}

								<div className='listNote'>SEE ALL TRANSACTIONS</div>
							</div>
							<div className='onlyMob'>
								<div class="transaction-list-main-mob">
									<div className='listT'>LATEST TRANSACTIONS</div>
									<div className='line3'></div>
									{_responseData.slice().reverse().map((responseData, index) => (
										<div key={index}>
											<div class="transaction-list">
												<div className='flexDoc1'>
													<div className='list1'>
														<img src={doc} />
														<div className='listConMain'>
															<div className='list1Con'>Document name{_responseData.length - index}</div>
															<div className='list1Con2'>{getRelativeTime(responseData.createdDate)}</div>
														</div>
													</div>

													{/*<div className='listConMain'>
														<div className='list1Con3'>Desde:</div>
														<div className='list1Con3'>Hacia:</div>
													</div>*/}
												</div>

												<div className='flexDoc2'>
													<button className='listBtn'>$$$$</button>

													<div className="listConMain2">
														<div className="listConMain2-2">Discharge</div>
														<div className="listConMain3">
															<div>SAFT</div>
															<img src={download} onClick={() => openPopup(index)} />
														</div>
													</div>
												</div>
											</div>

											{popupIndex === index && (
												<div id="popupContainerPDF" class="popupPDF">
													<div class="close" onClick={closePopup}>✖</div>
													<div class="popup-contentPDF" >
														<div ref={el => contentRefs.current[index] = el}>
															<h className='headerPDF'>Simple Agreement For Future Token - Guard Hawk (SAFT)</h>
															<p></p>
															<h className='headerPDF'>Angel Investor Round</h>

															<p>&nbsp;</p>

															<p>&nbsp;</p>
															<h2 className='headerPDF'>INTRODUCTION</h2>
															<p className='txtPDF'>This agreement contains information regarding the Angel Capital investment round for the Guard Hawk utility token (GHUT), particularly on the conditions and restrictions applicable to anyone who decides to invest in the first round of Guard Hawk.</p>
															<p className='txtPDF'>
																It should be noted that a six-month period from the launch date has been granted to raise sufficient capital to develop the project.
															</p>

															<p>&nbsp;</p>
															<h2 className='headerPDF'>DEFINITIONS</h2>
															<p className='txtPDF'>&ldquo;<strong>SAFT</strong>&rdquo; - The Simple Agreement for Future Tokens is an instrument that grants an Investor future rights to receive a certain amount of Tokens in connection with their investment.</p>
															<p className='txtPDF'>&ldquo;<strong>Company</strong>&rdquo; - Refers to the legal entity “Guard Hawk.”</p>
															<p className='txtPDF'>&ldquo;<strong>Investor</strong>&rdquo; - Is a legal entity or individual investor that provides funds in exchange for future Tokens.</p>
															<p className='txtPDF'>&ldquo;<strong>Utility Token (GHUT)&rdquo;</strong> - A type of asset that provides a user with a product or service.</p>
															<p className='txtPDF'>&ldquo;<strong>Governance Token (GHGO)&rdquo;</strong> -
																A type of digital asset that grants its holders the right to participate in the decision-making process of a decentralized organization or protocol.</p>
															<p className='txtPDF'>&ldquo;<strong>NFT (Non-Fungible Token)&rdquo;</strong> - A unique digital asset representing objects or concepts in the digital world, such as art, music, games, and other collectibles. In the context of Guard Hawk, a collectible NFT is digital art representing the Guard Hawk logo, granting its owner a unique piece of digital memorabilia tied to the brand.</p>
															<p className='txtPDF'>&ldquo;<strong>Dissolution Event</strong>&rdquo; - Means (a) a voluntary termination of operations by the Company at its sole discretion; (b) a general assignment for the benefit of the Company’s creditors; or (c) any other event of liquidation, dissolution, or winding up of the Company, excluding any change in ownership and control of the Company or an initial public offering.
															</p>
															<p className='txtPDF'>&ldquo;<strong>Subsequent Agreement</strong>&rdquo; - Means any SAFT issued by the Company after this SAFT for future Token sales rounds, in line with principles to raise capital. This definition excludes: (a) token issuances under the Company’s internal distribution plan, such as team incentives and other relevant events; (b) tokens issued to external service providers or others connected and associated with the Network launch, or similarly, for goods and services provided to the Company; (c) Tokens issued under research, collaboration, licensing, development, partnership, and other applicable sponsored agreements; (d) any convertible security issued by the Company; and (e) Tokens issued in connection with the Network’s mining activities and giveaways initiated by the Company.</p>
															<p>&nbsp;</p>
															<h2 className='headerPDF'>BUSINESS STRUCTURE</h2>

															<ul>
																<li>A total of 20,000,000,000 GHUT will be issued</li>
																<li>13,000,000 GHUT will be issued for the angel capital round from a total of 100,000,000 GHUT allocated in the Tokenomics.</li>
																<li>The total amount to be raised in the angel capital round fluctuates between €600,000 and €650,000, equivalent in POL.</li>
																<li>The GHUT value for angel capital investors is €0.05, equivalent in POL at the time and date of investment.</li>
																<li>Investment in this angel capital round may range from a minimum of €100 to a maximum of €50,000 per digital wallet, equivalent in POL at the time and date of investment. This applies regardless of the number of transactions, as long as they are within the specified ranges.</li>
															</ul>

															<p>&nbsp;</p>
															<h2 className='headerPDF'>INVESTMENT</h2>
															<ul>
																<li>Wallet No. {(responseData.ethAddress)} purchase {responseData.ghutAmount} GHUT.</li>
																<li>The total purchase value is {responseData.ethAmount} POL.</li>
															</ul>
															<p>&nbsp;</p>
															<h2 className='headerPDF'>DURATION</h2>
															<p className='txtPDF'>The Angel Capital investment round begins on October 1, 2024, and ends on April 1, 2025, at 00:00 GMT 0.</p>
															<p className='txtPDF'>15% of the total GHUT tokens are allocated to investors. This percentage will be distributed across three funding rounds: 0.5% for angel investors, 6% in the First Funding Round, 3.5% in the Second Round, and 5% to Launchpad investors.
																The initial GHUT value will be €0.1, equivalent to POL value at the time and date of issuance. This price reflects our operating costs and projected demand, providing a clear and justified starting point. From that date, GHUT’s price will be determined by the market, adjusting according to supply and demand over time.</p>
															<p className='txtPDF'>These terms certify that, in exchange for payment from the investor, the company grants the investor the right to the GHUT token purchase amount subject to the terms outlined in this document.</p>
															<p>&nbsp;</p>
															<h2 className='headerPDF'>BENEFITS</h2>
															<p className='txtPDF'>
																Angel investors in the Guard Hawk project’s first funding round will receive exclusive benefits, including:</p>
															<ul>
																<li>GHUT (Guard Hawk utility token) based on the invested amount.</li>
																<li>GHGO Tokens (Guard Hawk Governance): GHGO token distribution will be proportional to the investment. 30% of GHGO tokens will be allocated to investors, who will receive a number of tokens commensurate with their participation in each funding round, enabling them to influence project decisions according to their financial contribution.</li>
																<li>1 exclusive collectible NFT (Non-Fungible Token) for angel investors.</li>
															</ul>
															<p>&nbsp;</p>
															<p>&nbsp;</p>

															<h2 className='headerPDF'>SALE CONDITIONS</h2>
															<h4 className='txtPDF'>Right Reservation</h4>
															<p className='txtPDF'>The Company reserves the right to offer and sell the Token on different terms and in multiple rounds. Terms for each round subsequent to this one may change as long as the total token supply is not exceeded.</p>
															<h4 className='txtPDF'>Delivery of Investment</h4>
															<p className='txtPDF'>By entering into the agreement with the Company as per the provisions of this document, the Investor agrees and commits to transfer the Purchase Amount corresponding to the Token’s value amount and in accordance with these terms. By accepting this document, the Investor voluntarily agrees to be contractually bound by its terms.</p>
															<h4 className='txtPDF'>GHUT, GHGO, and NFT Delivery Conditions</h4>
															<p className='txtPDF'>
																The corresponding quantity of GHUT, GHGO, and NFT tokens will be delivered to the Investor in their digital wallet based on the details
																provided by the Investor in this document. The Token will be delivered to the Wallet under the Investor’s direct or indirect control, which cannot be delegated to any third party, authorized or unauthorized, including Investor representatives. Tokens and NFTs will be delivered to the Wallet address provided by the Investor within a maximum of 7 calendar days after the six-month capital-raising period for the project has concluded, provided the capital-raising target is met; otherwise, the amount transferred by the investor will be refunded to their wallet according to the Smart Contract {responseData.ethContract}, link https://polygonscan.com/address/{responseData.ethContract}.</p>
															<p></p>
															<h4 className='txtPDF'>Termination:</h4>
															<p className='txtPDF'>This SAFT and its provisions shall be considered terminated if:</p>
															<ul>
																<li>The applicable Token amount is delivered to the Investor within 7 calendar days after the end of the 6-month capital-raising period, with a successful outcome, meaning the necessary capital has been raised; or</li>
																<p></p>
																<li>The Investment is refunded to the investor, per the smart contract, if the capital-raising target is not met within the 6-month period.

																</li>
															</ul>
															<p className='txtPDF'>*Network transaction costs will be borne solely by the investor.</p>
															<p>&nbsp;</p>
															<h2 className='headerPDF'>LOCK-UP CONDITIONS</h2>
															<p className='txtPDF'>The lock-up (vesting) structure and release of GHUT for the first funding round is designed to align interests with Guard Hawk’s long-term growth and stability. The following structure reflects a fair and balanced approach:</p>
															<h4 className='txtPDF'>Cliff Period (Lock-Up)</h4>
															<p className='txtPDF'>Angel Investment Lock-Up: 100% of the GHUT tokens issued in this funding round, representing 0.5% of the total issued tokens, will be locked for one year from the token launch date. After this period, 10% of the tokens will be released in the first month, and the remaining 90% will be proportionally released over the next 18 months.</p>
															<p>&nbsp;</p>
															<h2 className='headerPDF'>PROTECTIVE CLAUSES</h2>
															<ul>

																<li>
																	<strong>Lock Reactivation Clause: </strong>
																	When a major incident, anomaly, or force majeure directly or indirectly affects the project’s future viability or investors’ interests, the committee may, upon a negative risk report, reactivate the token lock-up for a maximum of 7 consecutive days. If the committee decides the cited period is insufficient to resolve the incident or force majeure, it shall submit to Governance the decision to extend the lock-up, always based on applicable risk reports. This mechanism ensures that any action taken aligns with the community’s and token holders’ interests, providing an additional layer of security and transparency. Governance will play a crucial role in assessing the situation and making informed decisions, always considering the project’s best interests.
																</li>
															</ul>

															<ul>
																<li><strong>Blacklist Clause:</strong> At the request of regulators and/or competent courts, wallets with fraudulent activity or that do not meet regulatory requirements and minimum security standards may be frozen. This is important for investors’ peace of mind as it prevents unreliable investors from investing in the same project. These lists are designed to prevent fraud and scams.</li>
															</ul>
															<p>&nbsp;</p>
															<h2 className='headerPDF'>TRANSPARENCY AND COMMUNICATION</h2>
															<ul>
																<li>Clear Announcements: All lock-up and release terms will be clearly announced before investors commit capital.</li>
															</ul>

															<ul>
																<li>Ongoing Communication: We will keep open communication channels indicated on our website, such as Discord, Telegram, etc. This allows investors to share their opinions and submit proposals to Governance so that policies can be adjusted as needed for the benefit of the project and the community.</li>
															</ul>
															<p>&nbsp;</p>
															<p>&nbsp;</p>
															<p>&nbsp;</p>
															<p>&nbsp;</p>

															<h2 className='headerPDF'>GUARANTEE</h2>
															<p className='txtPDF'>
																Guard Hawk’s funding proposal stands out for the security it offers investors. Guard Hawk guarantees the automatic refund of investments if financial targets are not met within the specified timeframe, through the application of the {responseData.ethContract}, available at link https://polygonscan.com/address/{responseData.ethContract}.
																If a refund is due, it will be made in POL, with transaction costs borne by the investor.
																This measure significantly reduces risk for investors, increasing their confidence in the project’s responsible management and viability.
																The project will be audited by an international firm during the development phase to ensure that development milestones are met and expenditures remain transparent, with results published on our website. This additional transparency is crucial to maintaining investor confidence.
															</p>
															<p></p>
															<ul>
																<li>Note that we will close the initial investor round upon reaching our funding goal within 6 months. Closing will take place on April 1, 2025, at 00:00 GMT 0, with a maximum limit set between €600,000 and €650,000, equivalent in POL as of the closing date.
																	Future tokenomics projections include continuous distribution for market liquidity through various centralized and decentralized exchanges to allow free entry and exit within the ecosystem.
																</li>
															</ul>
															<p>&nbsp;</p>
															<h2 className='headerPDF'>COMPANY REPRESENTATIONS</h2>
															<p className='txtPDF'>The Company is duly incorporated, validly existing, and in good standing under the laws of its jurisdiction.
																The Company affirms and acknowledges that it is not and has not been in violation of its articles of incorporation or current bylaws, nor is it currently subject to any ongoing or pending litigation, investigation, or legal proceedings; it has not been or is currently involved in any act related to money laundering, terrorist financing, fraud, or other financial crimes.</p>
															<p>&nbsp;</p>
															<h2 className='headerPDF'>INVESTOR REPRESENTATIONS</h2>
															<p className='txtPDF'>As an investor, you declare that:</p>
															<ul>

																<li>You have read and accepted this agreement, which is an integral and binding part between the parties, before investing in the Guard Hawk project.</li>
																<li>You have read and accepted the terms and conditions of the Guard Hawk platform, the website’s legal notice, and its privacy policy.</li>
																<li>The funds you provide come from legal activities in accordance with European Union regulations.</li>
																<li>You will not allow third parties to make deposits into your accounts with funds from illegal activities under EU regulations, nor will you engage in transactions intended for such activities or for the benefit of related individuals.</li>
																<li>You commit to terminate any agreement, business, or contract with Guard Hawk if you violate any provision of this document, holding Guard Hawk harmless from any liability resulting from inaccurate, false, or misleading information you have provided or a breach of the terms and conditions in this document.</li>
																<p></p>
																<li>You authorize Guard Hawk to verify if you have been sanctioned by any European or U.S. authority to verify these representations.</li>
																<li>You authorize Guard Hawk to contract third-party identity verification services to be used when registering as an investor.</li>
																<li>You have reached the legal age of majority in your country of residence and therefore have the legal capacity to enter this SAFT and be bound by its terms.</li>
																<li>Entering into a binding agreement with the Company and complying with the terms and provisions of this SAFT does not contradict or breach any other contractual terms the Investor is legally bound to at the time of entering into this SAFT.</li>
																<li>The Investor is not located in or resides in any restricted jurisdictions, including those subject to prohibitive regulations, international financial sanctions, or economic restrictions.</li>
															</ul>
															<p></p>

															<p className='txtPDF'>If the Investor is a legal entity, the Investor acknowledges and guarantees that:</p>
															<ul>

																<li>The organization is duly formed and incorporated in its legal jurisdiction and conducts its activities in accordance with applicable laws and regulations.</li>
																<li>The organization’s representative acting as the signatory of this SAFT has been legally authorized to represent the organization and enter into legally binding agreements on its behalf.</li>
																<li>The organization does not represent or conduct its business activities in fields related to illegal business activities, intellectual property infringement, regulated and licensed goods and services, or fraudulent or restricted financial services and schemes, as well as other unfair, deceptive, aggressive, and unregulated goods and services under applicable laws.</li>

															</ul>

															<p>&nbsp;</p>
															<h2 className='headerPDF'>CONFIDENTIALITY</h2>
															<p className='txtPDF'>Any confidential information that has been or will be disclosed to the investor during the capital-raising period, along with all rights to such information, will remain the property of Guard Hawk.
																Any confidential information that has been or will be disclosed to the investor during the capital-raising period, along with all rights to such information, will remain the property of Guard Hawk.</p>
															<p className='txtPDF'>Investors will not gain any rights of any kind to the information, nor any right to use it, except for the purposes of this agreement. The disclosure of confidential information does not imply granting licenses for patent rights, copyrights, or any other rights by Guard Hawk, apart from those established in this document.</p>
															<p className='txtPDF'>Investors agree to keep confidential information (including any business, financial, and marketing strategy information) strictly confidential and not disclose it to any other party without prior written consent from Guard Hawk.</p>
															<p className='txtPDF'>If the investor is a legal entity, its representative must inform any person responsible for receiving confidential information – who must also sign the confidentiality agreement if necessary – of their obligation to receive, process, and use any confidential information as intended only for this agreement’s purposes.</p>
															<p className='txtPDF'>Investors must treat all information received directly or indirectly from Guard Hawk as confidential and use it solely for the purposes of this agreement. Investors shall not handle, use, exploit, or disclose confidential information to any person or entity for any reason contrary to this instrument unless Guard Hawk expressly authorizes it in writing.</p>
															<p className='txtPDF'>Disclosure or use of information by an investor in breach of this agreement shall be grounds for compensation for damages.
																The obligations outlined in this document do not apply in the following cases:
															</p>
															<p className='txtPDF'>i. Information that is public or subsequently becomes public.</p>
															<p className='txtPDF'>ii. Information not obtained from Guard Hawk or that must be disclosed by law or by order of an authority or court.</p>
															<p className='txtPDF'>iii. If information or any part of it is legally obtained by the investor from a third party without violating this agreement, provided that the third party is a lawful source.</p>
															<p className='txtPDF'>iv. If information or any part of it was known to the investor before Guard Hawk’s disclosure, provided that the investor can demonstrate such knowledge.</p>
															<p className='txtPDF'>Investors acknowledge that the exceptions mentioned above do not include information types solely because each is publicly known or in the investor’s possession. Investors will be responsible for demonstrating their rights regarding any exception provided in this clause.</p>
															<p className='txtPDF'>This agreement does not guarantee the investor from Guard Hawk any assurance against any infringement related to patent information or other third-party rights. Guard Hawk shall not be responsible for the duration of the information disclosure, errors, or omissions in the information, nor the use and results of using the information. Guard Hawk shall not be responsible in any way for any loss, including but not limited to damages, interest costs, lost profits, or any other similar loss or damage resulting from any error, inaccuracy, omission, or any other defect in the information.</p>

															<p>&nbsp;</p>
															<h2 className='headerPDF'>LIABILITY</h2>
															<p className='txtPDF'>Under no circumstances does Guard Hawk guarantee the project’s success. However, the team will make every effort to develop and execute the project as described in the whitepaper. Be aware that force majeure or contingencies could require us to modify or terminate the project. Investment carries inherent risks, including the possibility that Guard Hawk cannot follow the roadmap established in its technical document. Before investing in Guard Hawk, investors should assess the associated risks and, if needed, seek advice from an external expert. Uninformed investors who do not accept the financial or compliance risks of this project should not invest in Guard Hawk.</p>
															<p>&nbsp;</p>
															<h2 className='headerPDF'>NOTICE RECEPTION</h2>
															<p className='txtPDF'>Any notification related to this agreement shall be initiated solely in writing from the email address of the respective Party indicated in this agreement to the other Party’s email under the same conditions.</p>
															<p>&nbsp;</p>
															<h2 className='headerPDF'>LIMITATION OF REPRESENTATION</h2>
															<p className='txtPDF'>The Investor shall not have any right of representation of the Company, nor any voting or management rights in the Company. Similarly, the Investor shall not have any position on the Company’s board nor the acquisition or transfer of ownership or shares in the Company’s assets based on their Token holding. The Investor shall also have no right to vote for the election or removal of any board member of the Company, nor receive notice of meetings or the rights associated with them.</p>
															<p>&nbsp;</p>
															<h2 className='headerPDF'>SEVERABILITY</h2>
															<p className='txtPDF'>In the event that one or more provisions of this instrument are deemed invalid, illegal, or unenforceable, in whole or in part, or in any respect, or if one or more provisions of this instrument may prospectively operate to invalidate this instrument, then, in any case, such provision(s) shall be considered void and of no effect and shall not affect any other provisions of this instrument, which shall remain operative and in full force and effect.</p>
															<p>&nbsp;</p>
															<h2 className='headerPDF'>NO FINANCIAL ADVICE</h2>
															<p className='txtPDF'>The Investor warrants that this agreement, and any information provided by Guard Hawk, shall not be construed as investment, financial, regulatory, tax, or legal advice and that the Investor has sought such advice independently, if needed, from qualified professionals. Any decision regarding the Investor’s purchase of GHUT, made with or without professional assistance, will be their sole responsibility.</p>
															<p>&nbsp;</p>
															<h2 className='headerPDF'>INVESTOR KNOWLEDGE</h2>
															<p className='txtPDF'>The Investor acknowledges and warrants that they have acquired sufficient knowledge in financial and business matters and are capable of evaluating the benefits and risks associated with the purchase, sale, and trading of virtual currencies, including tokens. The Investor further acknowledges the risks associated with virtual currency investment and has assessed their financial and circumstantial situation to determine if purchasing the Token is suitable for them. The Investor accepts the potential for total and irreversible financial loss and understands that recovering from such loss may be challenging or impossible.</p>
															<p>&nbsp;</p>
															<h2 className='headerPDF'>THIRD-PARTY INTEREST</h2>
															<p className='txtPDF'>The Investor confirms they are purchasing the Token for their own account, not as a nominee or agent, and not with an intent for resale related to its distribution. The Investor has no current intent to sell, transfer any stake, or distribute it.</p>
															<p>&nbsp;</p>

															<p className='txtPDF'>GERMAN MUÑOZ MENESES</p>
															<p className='txtPDF'>CEO</p>
															<p className='txtPDF'>GUARD HAWK LDA</p>
															<p className='txtPDF'> Wallet No. {(responseData.ethAddress)}</p>

														</div>
														<button onClick={() => downloadPDF(index)} className="downloadSaft">DOWNLOAD SAFT PDF</button>

													</div>
												</div>
											)}

										</div>

									))}

									{_responseData.length === 0 && <p className='notxns'>No transactions yet</p>}

									<div className='listNote'>SEE ALL TRANSACTIONS</div>
								</div>

							</div>
						</div>
					</div>

				</div>
				<img className='grid' src={grid} />

			</div>

			<RegisterLoginEn />

			<div className='onlyMob'>
				<HeaderNavBarEn />
			</div>

		</div>
	)

}
export default MyInvestments;
